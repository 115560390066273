import styles from "../../styles/form.module.css";
import React, {Component, useContext, useState} from "react";
import FormikConfig from "../../components/FormikConfig";
import {Field, Form, useFormikContext} from "formik";
import {Link} from "react-router-dom";
import BaseContext from "../../util/BaseContext";
import MessageDisplay from "../../components/MessageDisplay";

import ApplicationService from "../../Services/ApplicationService";
import FileService from "../../Services/FileService";


export default function Declaration02(props) {

    const stage = "Declaration02"

    const {
        readOnly,
        applicationId,
        globalMessage,
        setGlobalMessage,
        displayOptional,
        setDisplayOptional,
        setShortForm
    } = useContext(BaseContext);
    const {values, touched, errors, validateForm, submitForm } = useFormikContext();
   // const [message, setMessage] = useState();
    const [FILE_requiredFiles, setFILE_requiredFiles] = useState();





    const getRequiredList = (applicationId) => {
        FileService.getRequiredList(
            applicationId
        ).then(
            response => {
                if (response.data.requiredFiles) {
                           setFILE_requiredFiles(response.data.requiredFiles);


                } else {

                }
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                document.getElementById("messageBox").scrollIntoView();
            }
        );
    }



    const validateApplication = () => {
        //see if required files list > 0
        getRequiredList(applicationId);
        if (FILE_requiredFiles.length > 0) {
            setGlobalMessage("Before we can finalise your application, Please check that all your documents have been provided")
            document.getElementById("messageBox").scrollIntoView();
            return 0;
        }

        validateForm().then(
            (errors) => {
                const errorKeys = Object.keys(errors);


                if (errorKeys.length > 0) {

             //       console.log(errors);

                    setGlobalMessage("Some fields have missing or incomplete values, before we can finalise this application please check that all entries have been filled in. Missing entries will be highlighted in red on the relevant pages")
                    return 0;
                } else {
                    return 1;
                }

            }

        )

        return 1;

    }

    /*   submitApplication(applicationId) {


           ApplicationService.submit(applicationId)
               .then(
                   response => {
                       setReadOnly(true);
                       setMessage("Thankyou, your application has been received");
                   },
                   error => {
                       const resMessage =
                           (error.response &&
                               error.response.data &&
                               error.response.data.message) ||
                           error.message ||
                           error.toString();
                       setMessage(resMessage)

                       document.getElementById("messageBox").scrollIntoView();
                   }
               );


       }
   */







//getRequiredList(applicationId);
if (FILE_requiredFiles) {

}
else {
    getRequiredList(applicationId);

}




        return (

            <div>
                <div className={styles.pageTitleContainer}>
                    <h3 className={styles.pageTitle}>Declaration</h3>
                </div>


                <MessageDisplay message={globalMessage} errors={errors}/>

                <div className={styles.question}>
                    <p className={styles.instruction}>There is an obligation on all researchers (and supervisors) to
                        bring
                        to the attention of the School Ethics Panel any and all ethical implication for the research
                        covered
                        in this submission.</p>

                    <p className={styles.instruction}>Only after this form has been reviewed and approved by the School
                        Ethics Panel is approval of your study granted.</p>
                    <p className={styles.instruction}>Only then do you have approval to begin the data collection
                        process on
                        the basis of the information stated in this form.</p>

                </div>

                {/*

                <div className={styles.question}>
                    <span className={styles.question}>Declaration</span>
                    <Field className={styles.radioSelect} id="declaration" type="radio" name="declaration" value="false" />
                    I consider that this study has <b>No significant ethical implications</b> to be brought before the School Ethics Panel.


                    <br/>
                    <Field className={styles.radioSelect} id="declaration" type="radio" name="declaration" value="true" />
                    I consider that this study <b>May have ethical implications</b> that should be brought before the School Ethics Panel (i.e. you answered ‘YES’ to any of questions A-M or ‘NO’ to questions N-T in section C <b>OR</b> there are other ethical implications that you feel should be considered)<br/>
                </div>

                */}

                <div className={styles.question}>
                    {
                        values.researcherType === "staff" ? (
                            <p className={styles.instruction}>Selecting finalise will send your application for review
                                by
                                the ethics panel. You won;t be able to alter the application once sent</p>
                        ) : (

                            <p className={styles.instruction}>Selecting finalise will send your application for review
                                by
                                your supervisor, if they find no issues they may forward it on to the ethics panel, or
                                return it to you for amendments.</p>

                        )

                    }


                    <div className={styles.nextContainer}>
                        <button id = "finaliseSubmission" type="button"  onClick={() => {


                              //  setGlobalMessage("");
                                if (!readOnly && validateApplication() ) {
                                  submitForm().then(

                                  );
                              } else {
                                    document.getElementById("messageBox").scrollIntoView();
                             }

                            //submitApplication(applicationId);
                        }
                        }><span className = {styles.bigLink}>Finalise Application</span>
                        </button>
                    </div>
                </div>
            </div>

        )


}