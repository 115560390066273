
import styles from "../../styles/form.module.css";
import {ErrorMessage, Field, useFormikContext} from "formik";
import Autosave from "../../components/Autosave";
import React, {useContext} from "react";
import Gated from "../../components/Gated";
import {Link} from "react-router-dom";
import BaseContext from "../../util/BaseContext";
import MessageDisplay from "../../components/MessageDisplay";
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";
import Question from "../../components/Question";


export default function Optional05() {

    const stage = "Optional05"

    const {readOnly, applicationId, globalMessage} = useContext(BaseContext);
    const {values, touched, errors} = useFormikContext();



    return (


                    <div>

                        <div className={styles.pageTitleContainer}>
                            <h3 className={styles.pageTitle}>Study recruitment</h3>
                            <span className={styles.autoSaveMessageContainer}>{!readOnly && (<Autosave />)}</span>
                            <FeedbackToggle readOnly = {readOnly} applicationId = {applicationId} stage = {stage} />



                        </div>




                        <NoteDisplay stage={stage} applicationId={applicationId} />

                <Question className={styles.question} errors={errors.recruitmentDetails} touched={touched.recruitmentDetails}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01</span>How will you recruit your participants?</span>
                    <span className={styles.example}>Please provide samples of your recruitment materials in the documents section.</span>
                    <Field as="textarea" name="recruitmentDetails" className={styles.inputLong} disabled={readOnly}/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"recruitmentDetails"}/>
                </Question>

                <Question className={styles.question} errors={errors.recruitVulnerable} touched={touched.recruitVulnerable}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>02</span>Do you intend to recruit participants who may be deemed vulnerable in anyway?</span>
                    <Field className={styles.radioSelect} id="recruitVulnerable" type="radio" name="recruitVulnerable" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="recruitVulnerable" type="radio" name="recruitVulnerable" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"recruitVulnerable"}/>
                </Question>

                        <Gated display={values.recruitVulnerable=="true"}>
                <Question className={styles.question} errors={errors.recruitVulnerableDetails} touched={touched.recruitVulnerableDetails}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>02-A</span>Please give details why this is necessary and any steps you are taking to minimize the risk to both you and the participant.</span>
                    <Field as="textarea" name="recruitVulnerableDetails" className={styles.inputLong} disabled={readOnly}/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"recruitVulnerableDetails"}/>
                </Question>
                        </Gated>

                <Question className={styles.question} errors={errors.recruitChildren} touched={touched.recruitChildren}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>03</span>Do you intend to recruit children under the age of 16?</span>
                    <Field className={styles.radioSelect} id="recruitChildren" type="radio" name="recruitChildren" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="recruitChildren" type="radio" name="recruitChildren" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"recruitChildren"}/>
                </Question>
                        <Gated display={values.recruitChildren=="true"}>
                <Question className={styles.question}  errors={errors.recruitChildrenDetails} touched={touched.recruitChildrenDetails}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>03-A</span>Please give details on why this is necessary and any steps you are taking to minimise the risk to both you and the participants including how informed consent will be gained.</span>
                    <Field as="textarea" name="recruitChildrenDetails" className={styles.inputLong} disabled={readOnly}/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"recruitChildrenDetails"}/>
                </Question>
                        </Gated>
                <Question className={styles.question} errors={errors.recruitRelationship} touched={touched.recruitRelationship}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>04</span>Is it possible that a current or past relationship with potential participants could give rise to perceived pressure to participate because you are in a position of authority or influence over them (e.g. they are your students, colleagues, family etc)?</span>
                    <Field className={styles.radioSelect} id="recruitRelationship" type="radio" name="recruitRelationship" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="recruitRelationship" type="radio" name="recruitRelationship" value="true" disabled={readOnly} />Yes<br/>
                </Question>
                        <Gated display={values.recruitRelationship=="true"}>
                <Question className={styles.question} errors={errors.recruitRelationshipDetails} touched={touched.recruitRelationshipDetails}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>04-A</span>Please give details on the steps you are taking to minimise the risk and feelings of coercion.</span>
                    <Field as="textarea" name="recruitRelationshipDetails" className={styles.inputLong} disabled={readOnly} />
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"recruitRelationshipDetails"}/>
                </Question>
                        </Gated>
            <div className={styles.nextContainer}>


                    <Link to="/application/optional06" className={styles.next}>Next</Link>

            </div>

                    </div>

    )
}
