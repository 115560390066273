
import styles from "../../styles/form.module.css";
import {ErrorMessage, Field, useFormikContext} from "formik";
import Autosave from "../../components/Autosave";
import React, {useContext} from "react";
import Gated from "../../components/Gated";
import {Link} from "react-router-dom";
import BaseContext from "../../util/BaseContext";
import MessageDisplay from "../../components/MessageDisplay";
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";
import Question from "../../components/Question";

export default function Optional04() {

    const stage = "Optional04"

    const {readOnly, applicationId, globalMessage} = useContext(BaseContext);
    const {values, touched, errors} = useFormikContext();

    return (


                    <div>

                        <div className={styles.pageTitleContainer}>
                            <h3 className={styles.pageTitle}>Data collection</h3>
                            <span className={styles.autoSaveMessageContainer}>{!readOnly && (<Autosave />)}</span>
                            <FeedbackToggle readOnly = {readOnly} applicationId = {applicationId} stage = {stage} />



                        </div>




                        <NoteDisplay stage={stage} applicationId={applicationId} />

                <Question className={styles.question} errors={errors.interviewUse} touched={touched.interviewUse}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01</span>Will the study use focus groups/structured interviews?</span>
                    <Field className={styles.radioSelect} id="interviewUse" type="radio" name="interviewUse" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="interviewUse" type="radio" name="interviewUse" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"interviewUse"}/>
                </Question>
                        <Gated display={values.interviewUse==="true"}>

                <Question className={styles.question} errors={errors.interviewDescription} touched={touched.interviewDescription}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01-A</span>Please provide brief details of the questions and/or topics to be discussed. </span>
                    <span className={styles.example}>Please ensure any questionnaire or topic guide is supplied in the documents section</span>

                    <Field as="textarea" name="interviewDescription" className={styles.inputLong} disabled={readOnly} />
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"interviewDescription"}/>

                </Question>
                        </Gated>
                <Question className={styles.question} errors={errors.otherUse} touched={touched.otherUse}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>02</span>Will the study use <b>any other</b> methods/materials for data collection or experimental stimuli (e.g. audio-visual material, physiological equipment, vignettes, computer stimulations, mechanical devices)?</span>

                    <Field className={styles.radioSelect} id="otherUse" type="radio" name="otherUse" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="otherUse" type="radio" name="otherUse" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"otherUse"}/>
                </Question>

                        <Gated display={values.otherUse==="true"}>
                <Question className={styles.question} errors={errors.otherUseDescription} touched={touched.otherUseDescription}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>02-A</span>Please provide details of the methods/materials to be used</span>

                    <Field as="textarea" name="otherUseDescription" className={styles.inputLong} disabled={readOnly} />
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"otherUseDescription"}/>
                </Question>
                        </Gated>
            <div className={styles.nextContainer}>
                <Link to="/application/optional05" className={styles.next}>Next</Link>
            </div>


                    </div>


    )
}

