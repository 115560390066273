import React, {useContext} from 'react';
import {Redirect, Route} from "react-router-dom";
import BaseContext from "../util/BaseContext";

/**
 Wrapper component to ensure we're not trying to join a form without initialising the form
 submission system. We don't want people deep linking to stage5 without the form being
 hydrated in the initial steps.

 Initialised Forms will have the applicationId stored in the global state, If its not present
 boot people back to the home page

 <ApplicationIdCheck>
    {//accessible only if the global applicationId is stored in our global context}
 </ApplicationIdCheck>

 Failure to display will instead direct people to the /Home Page component.
 */
export default function ApplicationIdCheck({children}, ...rest) {

   const {applicationId} = useContext(BaseContext);

    return (
        <Route {...rest}
            render={() =>
                applicationId ? (
                    children
                ) : (
                    <Redirect to = {{
                        pathname: "/home",
                        }}
                    />
                )
            }
        />
    );
}

