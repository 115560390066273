import styles from "../../styles/form.module.css";
import {ErrorMessage, Field, useFormikContext} from 'formik';
import Autosave from "../../components/Autosave";
import {Link } from "react-router-dom";
import React, {useContext, useState} from "react";
import BaseContext from "../../util/BaseContext";
import MessageDisplay from "../../components/MessageDisplay";
import ApplicationService from "../../Services/ApplicationService";
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";
import Question from "../../components/Question";



export default function Step03() {

    const stage = "Step03"

    const {readOnly, applicationId, globalMessage} = useContext(BaseContext);
    const {values, touched, errors} = useFormikContext();
    const [message, setMessage] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [noteArray, setNoteArray] = useState();
    const [wordCount, setWordCount] = useState(0);





    return (

                    <div>

                        <div className={styles.pageTitleContainer}>
                            <h3 className={styles.pageTitle}>About your study</h3>
                            <span className={styles.autoSaveMessageContainer}>{!readOnly && (<Autosave />)}</span>
                            <FeedbackToggle readOnly = {readOnly} applicationId = {applicationId} stage = {stage} />



                        </div>


                        <MessageDisplay message={message}/>

                        <NoteDisplay stage={stage} applicationId={applicationId} />

                        <Question className={styles.question} errors={errors.studyTitle} touched={touched.studyTitle}>
                <span className={styles.question}><span className = {styles.questionIdentifier}>01</span>What's your study's provisional title?</span>
                <span className={styles.example}></span>
                <Field as="textarea" name="studyTitle" className={styles.inputShort} disabled={readOnly}/>
                            <ErrorMessage className={styles.validationWarning} component="div"
                                          name={"studyTitle"}/>
            </Question>

            <Question className={styles.question} errors={errors.studyDescription} touched={touched.studyDescription}>
                <span className={styles.question}><span className = {styles.questionIdentifier}>02</span>Describe the participants and procedure that will be used in your study.</span>
                <span className={styles.example}></span>
                <Field as="textarea" name="studyDescription" className={styles.inputLong}
                       disabled={readOnly}
                       onKeyUp={(event)=>{


                           setWordCount(event.target.value.trim().split(/[\s]+/).length);

                       }}





                />


                <span className={styles.wordCount}>Word Count: <span

                    id='wordCount'


                >{wordCount}</span>/500</span>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"studyDescription"}/>
            </Question>


            <div className={styles.nextContainer}>
                <Link to="/application/step04" className={styles.next}>Next</Link>
            </div>
                    </div>

    )
}



function calculateWordCount() {

    return true;

}