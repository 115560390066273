
import styles from "../../styles/form.module.css";
import {ErrorMessage, Field, useFormikContext} from "formik";
import Autosave from "../../components/Autosave";
import React, {useContext} from "react";
import ReactTooltip from "react-tooltip";
import Popup from "../../components/Popup";
import Gated from "../../components/Gated";
import {Link} from "react-router-dom";
import BaseContext from "../../util/BaseContext";
import MessageDisplay from "../../components/MessageDisplay";
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Question from "../../components/Question";
import {Tooltip}  from "@carbon/react";
import {TooltipHost} from "@fluentui/react";
import {Information} from "@carbon/icons-react";

export default function Optional11() {

    const stage = "Optional11"

    const {readOnly, applicationId, globalMessage} = useContext(BaseContext);
    const {values, touched, errors} = useFormikContext();

    return (

                    <>
                        <div className={styles.pageTitleContainer}>
                            <h3 className={styles.pageTitle}>Data handling</h3>
                            <span className={styles.autoSaveMessageContainer}>{!readOnly && (<Autosave />)}</span>
                            <FeedbackToggle readOnly = {readOnly} applicationId = {applicationId} stage = {stage} />



                        </div>




                        <NoteDisplay stage={stage} applicationId={applicationId} />


                        <Question className={styles.question} errors={errors.dataProtectionDetails} touched={touched.dataProtectionDetails}>
                            <span className={styles.question}><span className = {styles.questionIdentifier}>01</span>How will data be stored securely during and after the study?


               <TooltipHost styles={styles.inlineTooltip} align="end" direction="right"
                            content={
                                <Popup title="Data Retention">
                                    <p>Please consult relevant data protection documentation or speak to the Data Protection Officer / IT department for advice.</p>
                                </Popup>
                            }><span className={styles.tooltipIcon}><Information
                   size={18}/></span>
                    </TooltipHost>



                            </span>
                            <Field as="textarea" name="dataProtectionDetails" className={styles.inputLong} disabled={readOnly} />
                            <ErrorMessage className={styles.validationWarning} component="div"
                                          name={"dataProtectionDetails"}/>
                        </Question>

                <Question className={styles.question} errors={errors.dataProtectionPeriod} touched={touched.dataProtectionPeriod}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>02</span>How and when will personally identifiable data be destroyed?


                        <TooltipHost styles={styles.inlineTooltip} align="end" direction="right"
                                     content={
                                         <Popup title="Personally Identifiable Data">
                                             <p>Personal data includes information relating to natural persons who:
                                             can be identified or who are identifiable, directly from the information in question; or
                                                 who can be indirectly identified from that information in combination with other information.</p>

                                             <p>This includes and is not limited to items such as names, email addresses, phone numbers or items likely to narrow down potential individuals such as conditions or treatments.</p>
                                         </Popup>
                                     }><span className={styles.tooltipIcon}><Information
                            size={18}/></span>
                    </TooltipHost>



                    </span>
                    <Field as="textarea" name="dataProtectionPeriod" className={styles.inputLong} disabled={readOnly} />
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"dataProtectionPeriod"}/>
                </Question>

                <Question className={styles.question} errors={errors.collectTissue} touched={touched.collectTissue}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>03</span>Do you intend to collect tissue samples (blood, saliva, hair or any other body part)?</span>
                    <Field className={styles.radioSelect} id="collectTissue" type="radio" name="collectTissue" value="false" disabled={readOnly}/>No<br/>
                    <Field className={styles.radioSelect} id="collectTissue" type="radio" name="collectTissue" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"collectTissue"}/>
                </Question>

                    <Gated display={values.collectTissue=="true"}>
                <Question className={styles.question}  errors={errors.collectTissueDetails} touched={touched.collectTissueDetails}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>03-A</span>Please give details on the collection, storage and disposal of these samples.</span>
                    <Field as="textarea" name="collectTissueDetails" className={styles.inputLong} disabled={readOnly}/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"collectTissueDetails"}/>
                </Question>
                    </Gated>






            <div className={styles.nextContainer}>
                <Link to="/application/optional12" className={styles.next}>Next</Link>
            </div>




                    </>

    )
}
