
import styles from "../../styles/form.module.css";
import {ErrorMessage, Field, useFormikContext} from "formik";
import React, {useContext} from "react";
import Autosave from "../../components/Autosave";
import Popup from "../../components/Popup";

import {Link} from "react-router-dom";
import BaseContext from "../../util/BaseContext";
import MessageDisplay from "../../components/MessageDisplay";
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";

import Question from "../../components/Question";
import {Tooltip} from "@carbon/react";

import {TooltipHost} from "@fluentui/react";
import {Information} from "@carbon/icons-react";


export default function Optional02() {

    const stage = "Optional02"

    const {readOnly, applicationId, globalMessage} = useContext(BaseContext);
    const {values, touched, errors} = useFormikContext();

    return (


                    <div>

                        <div className={styles.pageTitleContainer}>
                            <h3 className={styles.pageTitle}>Basic instructions</h3>
                            <span className={styles.autoSaveMessageContainer}>{!readOnly && (<Autosave />)}</span>
                            <FeedbackToggle readOnly = {readOnly} applicationId = {applicationId} stage = {stage} />



                        </div>




                        <NoteDisplay stage={stage} applicationId={applicationId} />

                <Question className={styles.question} errors={errors.studyInstructions} touched={touched.studyInstructions}>
                    <span class={styles.question}><span className = {styles.questionIdentifier}>01</span>Describe what participants and researcher(s) will do in the study once informed consent has been given.



                                         <TooltipHost styles = {styles.inlineTooltip} align="end" direction="right" content={
                                             <Popup title="Description of Activities">
                                                 <p>Including but not limited to describing the study setting, sequence of events, way in which materials are presented to participants, what participants will actually do, how and when debrief will occur, etc.</p>
                                             </Popup>
                                         }><span className = {styles.tooltipIcon}><Information size = {18}/></span>
                    </TooltipHost>
                    </span>
                    <Field as="textarea" name="studyInstructions" className={styles.inputLong} disabled={readOnly}/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"studyInstructions"}/>
                </Question>

                        {/*}
                <div className={styles.question}>
                    <span className={styles.question}>Please append any standardised instruction that will be given to participants.</span>

                    <!--
                    <input type="file" className={styles.upload} title="upload" disabled={readOnly} />
                    -->
                </div>

*/}

            <div className={styles.nextContainer}>
                <Link to="/application/optional03" className={styles.next}>Next</Link>
            </div>

        </div>


)
}
