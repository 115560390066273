import axios from "axios";
import AuthService from "./AuthService";

const API_URL = process.env.REACT_APP_API_TARGET;

class ApplicationService {


    /**
     * Retrieve the data model for a given application
     *
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */

    getApplication(id) {
        const url = API_URL + "/application/" + id;

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );

    }


    newApplication() {
        const url = API_URL + "/application/new";

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }


    updateApplication(values) {
        const url = API_URL + "/application/" + values.id;

        return axios.put(url, values, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }


    /**
     * Delete the requested application, server will permit this providing the current status
     * is not indicating review by supervisor/admin/reviewer in progress
     *
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteApplication(id) {
        const url = API_URL + "/application/" + id;

        return axios.delete(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }

    /**
     * Request the list of applications for this user
     *
     * @param page
     * @param size
     * @returns {Promise<AxiosResponse<any>>}
     */
    getList(page, size) {
        const url = API_URL + "/application" + "?"
            + "pageNo=" + page
            + "&pageSize=" + size


        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }

    getListToReview(page, size) {
        const url = API_URL + "/review" + "?"
            + "pageNo=" + page
            + "&pageSize=" + size


        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }

    getActiveList(page, size) {
        const url = API_URL + "/admin/application" + "?"
            + "pageNo=" + page
            + "&pageSize=" + size


        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }


    getCompletedList(page, size) {
        const url = API_URL + "/admin/application2" + "?"
            + "pageNo=" + page
            + "&pageSize=" + size


        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }


    addNote(applicationId, stage, text) {
        const url = API_URL + "/application/" + applicationId + "/note"
        return axios.post(url, {text:text,stage:stage}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }

    getNotes(applicationId, step, text) {
        const url = API_URL + "/application/" + applicationId + "/note"
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }



    submit(applicationId) {
        const url = API_URL + "/application/" + applicationId + "/submit"

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );

    }




    approve(applicationId) {
        const url = API_URL + "/admin/application/" + applicationId + "/APPROVE"

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );

    }


    declineMinor(applicationId) {
        const url = API_URL + "/admin/application/" + applicationId + "/DRAFT2"

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );

    }

    declineMajor(applicationId) {
        const url = API_URL + "/admin/application/" + applicationId + "/DRAFT1"

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );

    }


    reject(applicationId) {
        const url = API_URL + "/admin/application/" + applicationId + "/REJECT"

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );

    }




}

export default new ApplicationService();
