/**
 * Utility functions for formatting the display of date and time information
 *
 */
class DateFormat {

    /**
     * Prefixes a numeral with a leading 0
     *
     */
    formatNumber(digit) {
        if (digit <= 9) {
            return "0" + digit;
        } else {
            return digit;
        }
    }


    /**
     * Takes a supplied date string, extracts the components and
     * prefixes numerals with a leading 0 where necessary
     *
     */
    formatDate(date) {
        const dateObj = new Date(date);

        const formattedDate = dateObj.getFullYear() + "." + this.formatNumber(dateObj.getMonth() + 1) + "." + this.formatNumber(dateObj.getDate()) + " " + this.formatNumber(dateObj.getHours()) + ":" + this.formatNumber(dateObj.getMinutes());

        return formattedDate;
    }


    formatDateOnly(date)  {
        const dateObj = new Date(date);
        const formattedDate = dateObj.getFullYear() + "." + this.formatNumber(dateObj.getMonth() + 1) + "." + this.formatNumber(dateObj.getDate())
        return formattedDate;
    }

}

export default new DateFormat();
