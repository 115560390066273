
import styles from "../../styles/form.module.css";
import {ErrorMessage, Field, useFormikContext} from "formik";
import Autosave from "../../components/Autosave";
import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import BaseContext from "../../util/BaseContext";
import MessageDisplay from "../../components/MessageDisplay";
import ApplicationService from "../../Services/ApplicationService";
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";
import Question from "../../components/Question";


export default function Step05() {

    const stage = "Step05"

    const {readOnly, applicationId, globalMessage, displayOptional, setDisplayOptional, setShortForm} = useContext(BaseContext);
    const {values, touched, errors} = useFormikContext();


    const fastTrackEligible = () => {
        if (values.screen02 === "true" ||
            values.screen03 === "true" ||
            values.screen04 === "true" ||
            values.screen05 === "true" ||
            values.screen06 === "true" ||
            values.screen07 === "true" ||
            values.screen08 === "true" ||
            values.screen09 === "true" ||
            values.screen10 === "true" ||
            values.screen11 === "true" ||
            values.screen12 === "true" ||
            values.screen13 === "true" ||
            values.screen13b === "true" ||
            values.screen14 === "false" ||
            values.screen15 === "false" ||
            values.screen16 === "false" ||
            values.screen17 === "false" ||
            values.screen18 === "false" ||
            values.screen19 === "false" ||
            values.screen20 === "false"
        ) {
            if (!displayOptional) {
                setDisplayOptional(true);
                setShortForm(false);
            }
            return false;
        } else {
            if (displayOptional) {
                setDisplayOptional(false);
                setShortForm(true);
            }
            return true;
        }
    }
    const [message, setMessage] = useState();
    const [submitting, setSubmitting] = useState(false);

    const [noteArray, setNoteArray] = useState();






    return (
                    <div>
                        <div className={styles.pageTitleContainer}>
                            <h3 className={styles.pageTitle}>Application overview part 2</h3>
                            <span className={styles.autoSaveMessageContainer}>{!readOnly && (<Autosave />)}</span>
                            <FeedbackToggle readOnly = {readOnly} applicationId = {applicationId} stage = {stage} />


                        </div>

                        <MessageDisplay message={message}/>

                        <NoteDisplay stage={stage} applicationId={applicationId} />

                        <Question className={styles.question} errors={errors.screen14} touched={touched.screen14}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01</span>Will you describe the main experimental procedures to participants in advance so that they are fully informed about what to expect?</span>
                            <Field className={styles.radioSelect} id="screen14" type="radio" name="screen14" value="false" disabled={readOnly} />No<br/>
                            <Field className={styles.radioSelect} id="screen14" type="radio" name="screen14" value="true" disabled={readOnly} />Yes<br/>
                            <Field className={styles.radioSelect} id="screen14" type="radio" name="screen14" value="na" disabled={readOnly} />Not Applicable<br/>

                            <ErrorMessage className={styles.validationWarning} component="div"
                                          name={"screen14"}/>
                </Question>

                <Question className={styles.question} errors={errors.screen15} touched={touched.screen15}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>02</span>Will you tell participants that their participation is voluntary?</span>
                    <Field className={styles.radioSelect} id="screen15" type="radio" name="screen15" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="screen15" type="radio" name="screen15" value="true" disabled={readOnly} />Yes<br/>
                    <Field className={styles.radioSelect} id="screen15" type="radio" name="screen15" value="na" disabled={readOnly} />Not Applicable<br/>

                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"screen15"}/>
                </Question>

                <Question className={styles.question} errors={errors.screen16} touched={touched.screen16}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>03</span>If the research is observational, will you ask participants for their consent to being observed?</span>
                    <Field className={styles.radioSelect} id="screen16" type="radio" name="screen16" value="false" disabled={readOnly} />No<br/>
                     <Field className={styles.radioSelect} id="screen16" type="radio" name="screen16" value="true" disabled={readOnly} />Yes<br/>
                    <Field className={styles.radioSelect} id="screen16" type="radio" name="screen16" value="na" disabled={readOnly} />Not Applicable<br/>

                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"screen16"}/>
                </Question>

                <Question className={styles.question} errors={errors.screen17} touched={touched.screen17}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>04</span>Will you tell participants that they may withdraw from the research at any time and for any reason?</span>
                    <Field className={styles.radioSelect} id="screen17" type="radio" name="screen17" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="screen17" type="radio" name="screen17" value="true" disabled={readOnly} />Yes<br/>
                    <Field className={styles.radioSelect} id="screen17" type="radio" name="screen17" value="na" disabled={readOnly} />Not Applicable<br/>

                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"screen17"}/>
                </Question>


                <Question className={styles.question} errors={errors.screen18} touched={touched.screen18}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>05</span>If using questionnaires/surveys, will you give participants the option of omitting questions that they do not want to answer?</span>
                    <Field className={styles.radioSelect} id="screen18" type="radio" name="screen18" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="screen18" type="radio" name="screen18" value="true" disabled={readOnly} />Yes<br/>
                    <Field className={styles.radioSelect} id="screen18" type="radio" name="screen18" value="na" disabled={readOnly} />Not Applicable<br/>

                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"screen18"}/>
                </Question>

                <Question className={styles.question} errors={errors.screen19} touched={touched.screen19}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>06</span>Will you tell participants that their data will be treated with full confidentiality and that, if published, it will not be identifiable as theirs?</span>
                    <Field className={styles.radioSelect} id="screen19" type="radio" name="screen19" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="screen19" type="radio" name="screen19" value="true" disabled={readOnly} />Yes<br/>
                    <Field className={styles.radioSelect} id="screen19" type="radio" name="screen19" value="na" disabled={readOnly} />Not Applicable<br/>

                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"screen19"}/>
                </Question>

                <Question className={styles.question} errors={errors.screen20} touched={touched.screen20}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>07</span>Will you debrief participants at the end of their participation (i.e. give them a brief explanation of the study and information on how to access research findings)?</span>
                    <Field className={styles.radioSelect} id="screen20" type="radio" name="screen20" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="screen20" type="radio" name="screen20" value="true" disabled={readOnly} />Yes<br/>
                    <Field className={styles.radioSelect} id="screen20" type="radio" name="screen20" value="na" disabled={readOnly} />Not Applicable<br/>

                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"screen20"}/>
                </Question>

                        <div className={styles.nextContainer}>
                            { fastTrackEligible() ? (
                                <Link to="/application/declaration01" className={styles.next}>Next</Link>
                            ) : (
                                <Link to="/application/optional01" className={styles.next}>Next</Link>
                            ) }
                        </div>
                    </div>
    )
}