import styles from "../../styles/form.module.css";
import React, {useContext, useState} from 'react';
import Autosave from '../../components/Autosave';
import {ErrorMessage, FastField, Field, FieldArray, useFormikContext,} from 'formik';
import Popup from "../../components/Popup";
import Gated from "../../components/Gated";
import {Link} from "react-router-dom";
import MessageDisplay from "../../components/MessageDisplay";
import BaseContext from "../../util/BaseContext";
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";
import Question from "../../components/Question";
import {Tooltip} from '@carbon/react';
import {TooltipHost} from "@fluentui/react";
import {Information} from "@carbon/icons-react";




export default function Step01(props) {

    const stage = "Step01"


    const {
        readOnly,
        applicationId,
        globalMessage,
        displayOptional,
        setDisplayOptional,
        setShortForm
    } = useContext(BaseContext);
    const {values, touched, errors} = useFormikContext();
    const [message, setMessage] = useState();


    const fastTrackEligible = () => {
        if (values.screen02 === "true" ||
            values.screen03 === "true" ||
            values.screen04 === "true" ||
            values.screen05 === "true" ||
            values.screen06 === "true" ||
            values.screen07 === "true" ||
            values.screen08 === "true" ||
            values.screen09 === "true" ||
            values.screen10 === "true" ||
            values.screen11 === "true" ||
            values.screen12 === "true" ||
            values.screen13 === "true" ||
            values.screen14 === "false" ||
            values.screen15 === "false" ||
            values.screen16 === "false" ||
            values.screen17 === "false" ||
            values.screen18 === "false" ||
            values.screen19 === "false" ||
            values.screen20 === "false"
        ) {
            if (!displayOptional) {
                setDisplayOptional(true);
                setShortForm(false);
            }
            return false;
        } else {
            if (displayOptional) {
                setDisplayOptional(false);
                setShortForm(true);
            }
            return true;
        }
    }


    return (
        <div>
            <div className={styles.pageTitleContainer}>
                <h3 className={styles.pageTitle}>About you</h3>
                <span className={styles.autoSaveMessageContainer}>{!readOnly && (<Autosave/>)}</span>

                <FeedbackToggle readOnly = {readOnly} applicationId = {applicationId} stage = {stage} />

            </div>


            <MessageDisplay message={message}/>




            <NoteDisplay stage={stage} applicationId={applicationId} readOnly={readOnly}/>





            <Question className={styles.question} errors={errors.applicantName} touched={touched.applicantName}>
                <span className={styles.question}><span className = {styles.questionIdentifier}>01</span>Your name</span>
                <span className={styles.example}>e.g. joe bloggs</span>

                <FastField id="applicantName" name="applicantName" className={styles.inputText} type="text"
                           disabled={readOnly}/>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"applicantName"}/>
            </Question>

            <Question className={styles.question} errors={errors.singleStudy} touched={touched.singleStudy}>
                <span className={styles.question}><span className = {styles.questionIdentifier}>02</span>Is this a single author study or will there be co-authors?
                    <TooltipHost styles = {styles.inlineTooltip} align="end" direction="right" content={
                        <Popup title='Will there be additional applicants'>
                            <p>If this is to be a join study select joint study and provide the name of your
                                co-applicants</p>
                        </Popup>
                    }><span className = {styles.tooltipIcon}><Information size = {18}/></span>
                    </TooltipHost>
                </span>


                <FastField className={styles.radioSelect} id="singleStudy" type="radio" name="singleStudy"
                           value="true" disabled={readOnly}/>Single study<br/>
                <FastField className={styles.radioSelect} id="singleStudy" type="radio" name="singleStudy"
                           value="false" disabled={readOnly}/>Joint study<br/>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"singleStudy"}/>
            </Question>

            <Gated display={values.singleStudy === "false"}>

                <FieldArray name={"coApplicants"}>
                    {({push, remove}) => (
                        <div>
                            {
                                values.coApplicants.length > 0 &&
                                values.coApplicants.map((coApplicant, index) => (
                                    <div className="row" key={index}>
                                        <Question
                                            className={styles.question}
                                            errors={errors.coApplicants !== undefined && errors.coApplicants[index] !== undefined && errors.coApplicants[index].coApplicantName}
                                            touched={touched.coApplicants !== undefined && touched.coApplicants[index] !== undefined && touched.coApplicants[index].coApplicantName !== undefined}>

                                            <span className={styles.question}><span className = {styles.questionIdentifier}>02-A</span>Name of co-applicant</span>
                                            <span className={styles.example}>e.g. joe bloggs</span>

                                            <Field name={`coApplicants.${index}.coApplicantName`}
                                                   type="text"
                                                   disabled={readOnly}
                                                   className={styles.inputText}
                                            />
                                            {!readOnly && (
                                                <>
                                                    <button type="button" className={styles.addButton}
                                                            onClick={() => push({coApplicantName: ""})}>&#10010;
                                                    </button>
                                                    {index > 0 && (
                                                        <button type="button" className={styles.removeButton}
                                                                onClick={() => remove(index)}>&#10006;
                                                        </button>
                                                    )}
                                                </>
                                            )}
                                            <ErrorMessage
                                                name={`coApplicants.${index}.coApplicantName`}
                                                component="div"
                                                className={styles.fieldError}
                                            />
                                        </Question>
                                    </div>

                                ))
                            }

                        </div>


                    )
                    }

                </FieldArray>

            </Gated>



            <Gated display={false}>

            <Question className={styles.question} errors={errors.applicantEmail} touched={touched.applicantEmail}>
                <span className={styles.question}>Your email address
                    <Tooltip align="end" direction="right">
                        <Popup title='Email Address'>
                            <p>Please ensure you use your @aecc.ac.uk email address</p>
                        </Popup>
                    </Tooltip>
                </span>

                <span className={styles.example}>e.g. applicantName@aecc.ac.uk</span>

                <Field id="applicantEmail" name="applicantEmail" className={styles.inputText} disabled={true}
                       type="text"/>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"applicantEmail"}/>
            </Question>


            </Gated>


            {/*

            <Question className={styles.question} errors={errors.applicantTelephone}
                      touched={touched.applicantTelephone}>
                <span className={styles.question}>Your contact telephone number</span>
                <span className={styles.example}>e.g. +44.17878787878</span>
                <Field id="applicantTelephone" name="applicantTelephone" disabled={readOnly}
                       className={styles.inputText}
                       type="text"/>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"applicantTelephone"}/>
            </Question>

            */}

            <div className={styles.nextContainer}>

                {fastTrackEligible() ? (
                    <Link to="/application/step02" className={styles.next}>Next</Link>
                ) : (
                    <Link to="/application/step02" className={styles.next}>Next</Link>
                )}


            </div>

        </div>
    )
}
