
import styles from "../../styles/form.module.css";
import {ErrorMessage, Field, useFormikContext} from "formik";
import Autosave from "../../components/Autosave";
import React, {useContext} from "react";
import Gated from "../../components/Gated";
import {Link} from "react-router-dom";
import BaseContext from "../../util/BaseContext";
import MessageDisplay from "../../components/MessageDisplay";
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";
import Question from "../../components/Question";


export default function Optional10() {

    const stage = "Optional10"

    const {readOnly, applicationId, globalMessage} = useContext(BaseContext);
    const {values, touched, errors} = useFormikContext();

    return (

                    <div>

                        <div className={styles.pageTitleContainer}>
                            <h3 className={styles.pageTitle}>Risk factors</h3>
                            <span className={styles.autoSaveMessageContainer}>{!readOnly && (<Autosave />)}</span>
                            <FeedbackToggle readOnly = {readOnly} applicationId = {applicationId} stage = {stage} />



                        </div>




                        <NoteDisplay stage={stage} applicationId={applicationId} />

                <Question className={styles.question} errors={errors.participantQuestionRefusal} touched={touched.participantQuestionRefusal}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01</span>Will participants be given the option of omitting questions/topics/activities that they do not want to answer/discuss/participate in?</span>
                    <Field className={styles.radioSelect} id="participantQuestionRefusal" type="radio" name="participantQuestionRefusal" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="participantQuestionRefusal" type="radio" name="participantQuestionRefusal" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"participantQuestionRefusal"}/>
                </Question>

                        <Gated display={values.participantQuestionRefusal=="true"}>
                <Question className={styles.question} errors={errors.participantQuestionRefusalHow} touched={touched.participantQuestionRefusalHow}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01-A</span>How will this be communicated to participants?</span>
                    <Field as="textarea" name="participantQuestionRefusalHow" className={styles.inputLong} disabled={readOnly} />
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"participantQuestionRefusalHow"}/>
                </Question>
                        </Gated>
                        <Gated display={values.participantQuestionRefusal=="false"}>

                <Question className={styles.question} errors={errors.participantQuestionRefusalJustification} touched={touched.participantQuestionRefusalJustification}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01-B</span>Why is this not possible?</span>
                    <Field as="textarea" name="participantQuestionRefusalJustification" className={styles.inputLong} disabled={readOnly} />
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"participantQuestionRefusalJustification"}/>
                </Question>
                        </Gated>

                <Question className={styles.question}  errors={errors.participantQuestionSensitive} touched={touched.participantQuestionSensitive}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>02</span>Will participants be asked to provide potentially sensitive information?</span>
                    <Field className={styles.radioSelect} id="participantQuestionSensitive" type="radio" name="participantQuestionSensitive" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="participantQuestionSensitive" type="radio" name="participantQuestionSensitive" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"participantQuestionSensitive"}/>
                </Question>

                <Gated display = {values.participantQuestionSensitive=="true"}>
                <Question className={styles.question} errors={errors.participantQuestionSensitiveJustification} touched={touched.participantQuestionSensitiveJustification}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>02-A</span>What is the nature of this information and what limits will be placed on participants’ disclosure?</span>
                    <Field as="textarea" name="participantQuestionSensitiveJustification" className={styles.inputLong} disabled={readOnly} />
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"participantQuestionSensitiveJustification"}/>
                </Question>
                    </Gated>
                <Question className={styles.question} errors={errors.hasDeception} touched={touched.hasDeception}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>03</span>Does your study involve deception?</span>
                    <Field className={styles.radioSelect} id="hasDeception" type="radio" name="hasDeception" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="hasDeception" type="radio" name="hasDeception" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"hasDeception"}/>
                </Question>

                        <Gated display = {values.hasDeception=="true"}>
                <Question className={styles.question} errors={errors.deceptionJustification} touched={touched.deceptionJustification}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>03-A</span>Please detail the rationale for the use of deception.</span>
                    <Field as="textarea" name="deceptionJustification" className={styles.inputLong} disabled={readOnly} />
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"deceptionJustification"}/>
                </Question>
                        </Gated>
                <Question className={styles.question} errors={errors.hasRisks} touched={touched.hasRisks}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>04</span>Do you foresee any risks to participants in participating in this study?</span>
                    <Field className={styles.radioSelect} id="hasRisks" type="radio" name="hasRisks" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="hasRisks" type="radio" name="hasRisks" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"hasRisks"}/>
                </Question>

                        <Gated display = {values.hasRisks=="true"}>
                <Question className={styles.question} errors={errors.riskJustification} touched={touched.riskJustification}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>04-A</span>Please detail potential risks and what is being done to minimise them.</span>
                    <Field as="textarea" name="riskJustification" className={styles.inputLong} disabled={readOnly} />
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"riskJustification"}/>
                </Question>
                        </Gated>
                <Question className={styles.question} errors={errors.hasAnonymity} touched={touched.hasAnonymity}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>05</span>Will participant anonymity be maintained in this study?</span>
                    <Field className={styles.radioSelect} id="hasAnonymity" type="radio" name="hasAnonymity" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="hasAnonymity" type="radio" name="hasAnonymity" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"hasAnonymity"}/>
                </Question>

                        <Gated display={values.hasAnonymity=="true"}>
                <Question className={styles.question} errors={errors.anonymityMaintainedHow} touched={touched.anonymityMaintainedHow}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>05-A</span>Please explain how it will be maintained.</span>
                    <Field as="textarea" name="anonymityMaintainedHow" className={styles.inputLong} disabled={readOnly} />
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"anonymityMaintainedHow"}/>
                </Question>
                        </Gated>

                        <Gated display={values.hasAnonymity=="false"}>
                <Question className={styles.question} errors={errors.anonymityLostJustification} touched={touched.anonymityLostJustification}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>05-B</span>Please justify why participants will not remain anonymous.</span>
                    <Field as="textarea" name="anonymityLostJustification" className={styles.inputLong} disabled={readOnly}/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"anonymityLostJustification"}/>
                </Question>
                        </Gated>

                <Question className={styles.question} errors={errors.hasConfidentiality} touched={touched.hasConfidentiality}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>06</span>Will participant confidentiality be maintained in this study?</span>
                    <Field className={styles.radioSelect} id="hasConfidentiality" type="radio" name="hasConfidentiality" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="hasConfidentiality" type="radio" name="hasConfidentiality" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"hasConfidentiality"}/>
                </Question>
                        <Gated display={values.hasConfidentiality=="true"}>
                <Question className={styles.question} errors={errors.confidentialityMaintainedHow} touched={touched.confidentialityMaintainedHow}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>06-A</span>Please explain how it will be maintained.</span>
                    <Field as="textarea" name="confidentialityMaintainedHow" className={styles.inputLong} disabled={readOnly}/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"confidentialityMaintainedHow"}/>
                </Question>
                        </Gated>
                        <Gated display={values.hasConfidentiality=="false"}>
                <Question className={styles.question} errors={errors.confidentialityLostJustification} touched={touched.confidentialityLostJustification}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>06-B</span>Please justify why participants will not remain confidential.</span>
                    <Field as="textarea" name="confidentialityLostJustification" className={styles.inputLong} disabled={readOnly}/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"confidentialityLostJustification"}/>
                </Question>
                        </Gated>

            <div className={styles.nextContainer}>
                <Link to="/application/optional11" className={styles.next}>Next</Link>
            </div>


                    </div>

    )
}

