import debounce from 'just-debounce-it';
import {useFormikContext} from 'formik';
import React from 'react';
import ApplicationService from "../Services/ApplicationService";



/**
 Inline component that triggers a form submission attempt if the formik form state
 has been altered. We attempt at most once per second and will attempt to ensure we
 don't end up in a race with another attempt at submission.

 <Autosave />

 We return a simple message indicating whether save is operational or not.
 */
export default function Autosave() {
    //min time between autosave attempts (ms)
    const timeout = 1000;

    //All of our autosave data is held within Formik
    const formik = useFormikContext();

    const [lastSaved, setLastSaved] = React.useState(null);

    const [isSubmitting, setSubmitting] = React.useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filterSubmit = React.useCallback(
        //we don;t want to autosave on every keystroke in a field so lets only update 1s without further changes
        debounce(
            (values) => {

                if (!isSubmitting) {

                console.log("submitting1", values.applicantEmail);

                setSubmitting(true);
                ApplicationService.updateApplication(values).then(
                    () => {

                        // formikHelpers.resetForm(values);
                        setLastSaved(new Date().toISOString());
                        setSubmitting(false);
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        setSubmitting(false);

                        document.getElementById("messageBox").scrollIntoView();
                    }
                )
            }



             //   formik.submitForm().then(() => {
             //      setLastSaved(new Date().toISOString());
             //   })
            },
            timeout
        ),
        [timeout ]
    );


    //Use a React Effect to trigger an autosave attempt whenever formik.values updates.
    React.useEffect(() => {
        console.log("in use Effect")

//        if (formik.isValid && formik.dirty && !formik.isSubmitting) {
        if (!isSubmitting) {
           filterSubmit(formik.values);
        }

 //   }, [debouncedSubmit, formik.dirty, formik.isSubmitting, formik.isValid, formik.values]);
   }, [filterSubmit, formik.values]);


    //The text we'll be returning to screen
    let result=null;

    //The class of the text we're returning, mainly to allow for it being
    //red in the case of failure
    let resultClass="red";

    //TODO: Refactor to be in render component
    //This still works on page re-render but ideally we'll
    //move this to more quickly reflect new state.
/*
    if (Object.keys(formik.errors).length > 0) {
        setLastSaved(null);
        result = `✘ ${formik.errors.toString()}`;
        resultClass = styles.autoSaveError.toString()
    } else if (formik.isSubmitting) {
        result = "saving";
        resultClass = styles.autoSaveWarning.toString();
    } else if (lastSaved !== null) {
       // result = `✔ ${lastSaved}`;
        result = `✔`;
        resultClass = styles.autoSaveOK.toString();
    } else {
   //     result = "initialising";
        result = "?";
        resultClass = styles.autoSaveWarning.toString();
    }
*/

    return (
        <span className={resultClass}>{result}</span>
    )

}
