import React, {Component} from "react";


import {NavLink, Route, Switch, Redirect, withRouter} from "react-router-dom";
import styles from "../styles/form.module.css";
import AuthService from "../Services/AuthService";
import * as Yup from "yup";
import TimeSpinner from "../components/TimeSpinner";
import MessageDisplay from "../components/MessageDisplay";
import {Formik, Field, Form, ErrorMessage, FieldArray} from 'formik';

const RegisterSchema = Yup.object().shape({
    username: Yup.string().trim().email('Invalid email address').required('Please ensure your email address is correct.'),
    password: Yup.string().trim().required('Please enter your password.')
});

const initialValues = {
    username: "",
    password: ""
}

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            message: "",
            submitting: false
        };
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();

        if (user) {
            this.setState({
                ready: true,
                user: user,
                redirectTo: "/home"
            });
        }

    }

    render() {

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }




        return (
            <div>
                <div className={styles.pageTitleContainer}>
                    <h3 className={styles.pageTitle}>Welcome to the application to conduct research at AECC University college (ethics) Portal. </h3>
                </div>




                <Formik


                    initialValues={initialValues}
                    validationSchema={RegisterSchema}


                    onSubmit={(values) => {
                        this.setState({
                            submitting: true,
                            message: ""
                        })
                        AuthService.login(values.username, values.password).then(
                            () => {
                                this.setState({
                                    submitting: false,
                                    message: ""
                                })

                                this.props.history.push('/');
                                window.location.reload();
                            },
                            error => {


                                if (error.response) {
                                    if (error.response.status === 401) {
                                        this.setState({
                                            submitting: false,
                                              message: "Please check your login details and try again"
                                        })
                                    } else if (error.response.status === 500) {
                                        this.setState({
                                            submitting: false,
                                            message: "We're currently encountering some issues. Please try again later"
                                        })
                                    } else {
                                        this.setState({
                                            submitting: false,
                                            message: "We're currently encountering some issues. Please try again later. If the problem persists contact support."
                                        })
                                    }
                                } else {
                                    this.setState({
                                        submitting: false,
                                        message: error.message
                                    })
                                }
                            }
                        );
                    }}


                >
                    {({errors, touched}) => (


                        <Form className = {styles.defaultForm}>




                            <MessageDisplay message={this.state.message}/>



                                    <p>Please use your college login details to use this service</p>
                                        <label className={styles.question} htmlFor="username">
                                            <span className={styles.question}>Email Address</span>
                                        <Field name="username"
                                               className={errors.username && touched.username ? `${styles.validateWarn} ${styles.inputText}` : `${styles.inputText}`}
                                               autoComplete="off"/>
                                        <ErrorMessage className={styles.validationWarning} component="div"
                                                      name={"username"}/>

                                       </label>



                                        <label className={styles.question} htmlFor="password">

                                           <span className={styles.question}>Passphrase</span>
                                        <Field name="password"
                                               type="password"
                                               className={errors.password && touched.password ? `${styles.validateWarn} ${styles.inputText}` : `${styles.inputText}`}
                                               autoComplete="off"/>
                                        <ErrorMessage className={styles.validationWarning} component="div"
                                                      name={"password"}/>
                        </label>





                                <button type="submit" >
                              <span className={styles.timeSpinnerSurround}>
                                 <TimeSpinner display={this.state.submitting} backgroundColour="#ffffff"/>
                                </span>
                                    <span className = {styles.bigLink}>
                          Log In
                              </span>
                                </button>

                        </Form>
                    )}


                </Formik>






            </div>
        );
    }
}

export default withRouter(Login);
