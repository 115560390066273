import styles from '../styles/form.module.css'

import React, {Component} from "react";
import AuthService from "../Services/AuthService";
import {Link, NavLink} from "react-router-dom";



export default class Logout extends Component {

    constructor(props) {
        super(props);
    }


    componentDidMount() {

        const user = AuthService.getCurrentUser();
        if (user) {
            AuthService.logout();
            window.location.reload();
        }
    }


    render() {

        return (
            <div>

                <div className={styles.pageTitleContainer}>
                    <h3 className={styles.pageTitle}>Logout</h3>
                </div>

                <fieldset className={styles.serverMessage}>
                    <p>You have been logged out of the service.</p>
                </fieldset>

                <p>
                    <div className={styles.bigLinkContainer}>
                        <Link to="/login"
                                 className={`${styles.bigLink}  `}>Login
                        </Link>
                    </div>


                </p>
            </div>
        )
    }

}

