
import styles from "../../styles/form.module.css";
import {ErrorMessage, Field, useFormikContext} from "formik";
import Autosave from "../../components/Autosave";
import React, {useContext} from "react";
import Gated from "../../components/Gated";
import {Link} from "react-router-dom";
import BaseContext from "../../util/BaseContext";
import MessageDisplay from "../../components/MessageDisplay";
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";
import Question from "../../components/Question";

export default function Optional12() {

    const stage = "Optional12"

    const {readOnly, applicationId, globalMessage, notesVisible} = useContext(BaseContext);
    const {values, touched, errors} = useFormikContext();
    return (


                    <div>
                        <div className={styles.pageTitleContainer}>
                            <h3 className={styles.pageTitle}>Other risks</h3>
                            <span className={styles.autoSaveMessageContainer}>{!readOnly && (<Autosave />)}</span>
                            <FeedbackToggle readOnly = {readOnly} applicationId = {applicationId} stage = {stage} />



                        </div>




                        <NoteDisplay stage={stage} applicationId={applicationId} />


                <Question className={styles.question} errors={errors.dataAdditionalUse} touched={touched.dataAdditionalUse}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01</span>Will the research data be used for any purpose other than for the project described in this application?</span>
                    <Field className={styles.radioSelect} id="dataAdditionalUse" type="radio" name="dataAdditionalUse" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="dataAdditionalUse" type="radio" name="dataAdditionalUse" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"dataAdditionalUse"}/>
                </Question>

                        <Gated display={values.dataAdditionalUse=="true"}>
                <Question className={styles.question} errors={errors.dataAdditionalUseDetails} touched={touched.dataAdditionalUseDetails}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01-A</span>Please give details</span>
                    <Field as="textarea" name="dataAdditionalUseDetails" className={styles.inputLong} disabled={readOnly} />
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"dataAdditionalUseDetails"}/>
                </Question>
                    </Gated>

                <Question className={styles.question}  errors={errors.hasAdditionalRisks} touched={touched.hasAdditionalRisks}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>02</span>Do you foresee any risks to yourself or other researcher conducting the study?</span>
                    <Field className={styles.radioSelect} id="hasAdditionalRisks" type="radio" name="hasAdditionalRisks" value="false"  disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="hasAdditionalRisks" type="radio" name="hasAdditionalRisks" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"hasAdditionalRisks"}/>
                </Question>

                        <Gated display={values.hasAdditionalRisks=="true"}>
                <Question className={styles.question} errors={errors.additionalRisksDetails} touched={touched.additionalRisksDetails}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>02-A</span>Please give details and explain how these risks will be managed.</span>
                    <Field as="textarea" name="additionalRisksDetails" className={styles.inputLong} disabled={readOnly} />
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"additionalRisksDetails"}/>
                </Question>
                        </Gated>
                <Question className={styles.question} errors={errors.hasAdditionalRisksAECC} touched={touched.hasAdditionalRisksAECC}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>03</span>Do you foresee any risks to AECC University College in connection to this study?</span>
                    <Field className={styles.radioSelect} id="hasAdditionalRisksAECC" type="radio" name="hasAdditionalRisksAECC" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="hasAdditionalRisksAECC" type="radio" name="hasAdditionalRisksAECC" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"hasAdditionalRisksAECC"}/>
                </Question>
                        <Gated display={values.hasAdditionalRisksAECC=="true"}>
                <Question className={styles.question}  errors={errors.additionalRisksAECCDetails} touched={touched.additionalRisksAECCDetails}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>03-A</span>Please give details and explain how these risks will be managed.</span>
                    <Field as="textarea" name="additionalRisksAECCDetails" className={styles.inputLong} disabled={readOnly} />
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"additionalRisksAECCDetails"}/>
                </Question>
                        </Gated>
                <Question className={styles.question} errors={errors.hasAdditionalEthicalIssues} touched={touched.hasAdditionalEthicalIssues}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>04</span>Are there <b>any other</b> ethical issues not covered elsewhere in this application that should be brought to the attention of the Ethics Panel?</span>
                    <Field className={styles.radioSelect} id="hasAdditionalEthicalIssues" type="radio" name="hasAdditionalEthicalIssues" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="hasAdditionalEthicalIssues" type="radio" name="hasAdditionalEthicalIssues" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"hasAdditionalEthicalIssues"}/>
                </Question>
            <Gated display={values.hasAdditionalEthicalIssues=="true"}>
                <Question className={styles.question} errors={errors.additionalEthicalIssuesDetails} touched={touched.additionalEthicalIssuesDetails}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>04-A</span>Please give details and explain how these risks will be managed.</span>
                    <Field as="textarea" name="additionalEthicalIssuesDetails" className={styles.inputLong} disabled={readOnly} />
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"additionalEthicalIssuesDetails"}/>
                </Question>
            </Gated>

            <div className={styles.nextContainer}>
                <Link to="/application/declaration01" className={styles.next}>Next</Link>
            </div>





                    </div>

    )
}
