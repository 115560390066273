import React, {Component} from "react";



import {Link, NavLink, Redirect} from "react-router-dom";
import styles from "../styles/form.module.css";

export default class NotFound extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            message: ""
        };
    }
/*
    componentDidMount() {
        const user = AuthService.getCurrentUser();

        if (user) {
            this.setState({
                user: user,
                showBasic: user.roles.includes("ROLE_BASIC"),
                showMRI: user.roles.includes("ROLE_MRI"),
                showXRay: user.roles.includes("ROLE_XRAY"),
                showUltrasound: user.roles.includes("ROLE_ULTRASOUND"),
                showPhysio: user.roles.includes("ROLE_PHYSIO"),
                showChiro: user.roles.includes("ROLE_CHIRO"),
                isStudent: user.roles.includes("ROLE_STUDENT"),
            });
        }

        this.setState({
            user: user,
            ready: true
        })

    }
*/

    render() {


        return (
            <div>
                <div className={styles.pageTitleContainer}>
                    <h3 className={styles.pageTitle}>Not Found</h3>
                </div>

                <p>The page you have requested could not be found</p>

            </div>

        );
    }
}
