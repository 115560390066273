
import styles from "../../styles/form.module.css";
import {ErrorMessage, Field, useFormikContext} from "formik";
import React, {useContext} from "react";
import Gated from "../../components/Gated";
import Autosave from "../../components/Autosave";
import {Link} from "react-router-dom";
import BaseContext from "../../util/BaseContext";
import MessageDisplay from "../../components/MessageDisplay";
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";
import Question from "../../components/Question";

export default function Optional06() {

    const stage = "Optional06"

    const {readOnly, applicationId, globalMessage} = useContext(BaseContext);
    const {values, touched, errors} = useFormikContext();

    return (

                    <div>

                        <div className={styles.pageTitleContainer}>
                            <h3 className={styles.pageTitle}>Participation criteria</h3>
                            <span className={styles.autoSaveMessageContainer}>{!readOnly && (<Autosave />)}</span>
                            <FeedbackToggle readOnly = {readOnly} applicationId = {applicationId} stage = {stage} />



                        </div>




                        <NoteDisplay stage={stage} applicationId={applicationId} />

                        <Question className={styles.question}  errors={errors.recruitCriteria} touched={touched.recruitCriteria}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01</span>Do you have any inclusion/exclusion criteria for your participants?</span>
                    <Field className={styles.radioSelect} id="recruitCriteria" type="radio" name="recruitCriteria" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="recruitCriteria" type="radio" name="recruitCriteria" value="true" disabled={readOnly} />Yes<br/>
                            <ErrorMessage className={styles.validationWarning} component="div"
                                          name={"recruitCriteria"}/>
                </Question>
<Gated display={values.recruitCriteria=="true"}>
                <Question className={styles.question} errors={errors.recruitCriteriaDetails} touched={touched.recruitCriteriaDetails}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01-A</span>Please give details on what these are and how they will be communicated to potential participants.</span>
                    <Field as="textarea" name="recruitCriteriaDetails" className={styles.inputLong} disabled={readOnly}/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"recruitCriteriaDetails"}/>
                </Question>
</Gated>
                <Question className={styles.question} errors={errors.recruitParticipantsTotal} touched={touched.recruitParticipantsTotal}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>02</span>How Many Participants are you aiming to recruit</span>
                    <span className={styles.example}>eg. 42</span>
                    <Field name="recruitParticipantsTotal" className={styles.inputText} type="text" disabled={readOnly} />
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"recruitParticipantsTotal"}/>
                </Question>

                <Question className={styles.question}  errors={errors.recruitParticipantsJustification} touched={touched.recruitParticipantsJustification}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>03</span>Please enter a justification for the numbers you wish to recruit.</span>
                    <Field as="textarea" name="recruitParticipantsJustification" className={styles.inputLong} disabled={readOnly} />
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"recruitParticipantsJustification"}/>
                </Question>









            <div className={styles.nextContainer}>
                <Link to="/application/optional07" className={styles.next}>Next</Link>
            </div>

                    </div>

    )
}

