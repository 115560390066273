import styles from "../../styles/form.module.css";
import ReactTooltip from "react-tooltip";
import Popup from "../../components/Popup";
import React, {useContext, useState} from "react";
import {ErrorMessage, Field, useFormikContext} from "formik";
import Autosave from "../../components/Autosave";
import {Link, NavLink} from "react-router-dom";
import BaseContext from "../../util/BaseContext";
import MessageDisplay from "../../components/MessageDisplay";
import ApplicationService from "../../Services/ApplicationService";
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Question from "../../components/Question";

import {TooltipHost} from "@fluentui/react";
import {Information} from "@carbon/icons-react";
import ActionButton from "../../components/ActionButton";

export default function Step04() {

    const stage = "Step04"

    const {
        readOnly,
        applicationId,
        globalMessage,
        displayOptional,
        setDisplayOptional,
        setShortForm
    } = useContext(BaseContext);
    const {values, touched, errors} = useFormikContext();


    const fastTrackEligible = () => {
        if (values.screen02 === "true" ||
            values.screen03 === "true" ||
            values.screen04 === "true" ||
            values.screen05 === "true" ||
            values.screen06 === "true" ||
            values.screen07 === "true" ||
            values.screen08 === "true" ||
            values.screen09 === "true" ||
            values.screen10 === "true" ||
            values.screen11 === "true" ||
            values.screen12 === "true" ||
            values.screen13 === "true" ||
            values.screen13b === "true" ||
            values.screen14 === "false" ||
            values.screen15 === "false" ||
            values.screen16 === "false" ||
            values.screen17 === "false" ||
            values.screen18 === "false" ||
            values.screen19 === "false" ||
            values.screen20 === "false"
        ) {
            if (!displayOptional) {
                setDisplayOptional(true);
                setShortForm(false);
            }
            return false;
        } else {
            if (displayOptional) {
                setDisplayOptional(false);
                setShortForm(true);
            }
            return true;
        }
    }


    const [message, setMessage] = useState();

    return (
        <div>
            <div className={styles.pageTitleContainer}>
                <h3 className={styles.pageTitle}>Application overview part 1</h3>
                <span className={styles.autoSaveMessageContainer}>{!readOnly && (<Autosave/>)}</span>
                <FeedbackToggle readOnly = {readOnly} applicationId = {applicationId} stage = {stage} />



            </div>


            <MessageDisplay message={message}/>

            <NoteDisplay stage={stage} applicationId={applicationId}/>

            <Question className={styles.question} errors={errors.screen01} touched={touched.screen01}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01</span>Will the study involve recruitment of patients or staff through the NHS?

                                         <TooltipHost styles={styles.inlineTooltip} align="end" direction="right"
                                                      content={
                                                          <Popup title="Recruitment through NHS">
                                                              <p>If the answer is <b>yes</b>. You will need to apply for
                                                                  ethics through <a href ="https://www.myresearchproject.org.uk/Signin.aspx" target = "_new">IRAS</a>.
                                                                  This requires you to create a log in and then submit
                                                                  an ethical application to the relevant area of
                                                                  research.</p>
                                                              <p>Subsequently an approved application letter must be
                                                                  uploaded onto the AECC University College online
                                                                  system for approval. </p>
                                                          </Popup>
                                                      }><span className={styles.tooltipIcon}><Information
                                             size={18}/></span>
                    </TooltipHost>

                    </span>
                <Field className={styles.radioSelect} id="screen01false" type="radio" name="screen01" value="false"
                       disabled={readOnly}/>No<br/>
                <Field className={styles.radioSelect} id="screen01true" type="radio" name="screen01" value="true"
                       disabled={readOnly}/>Yes<br/>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"screen01"}/>
            </Question>

            <Question className={styles.question} errors={errors.screen02} touched={touched.screen02}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>02</span>Will the study involve use of x-rays or magnetic resonance?

       <TooltipHost styles={styles.inlineTooltip} align="end" direction="right" content={
           <Popup title="Use of X-Ray or Magnetic Resonance">
               <p>If the answer is <b>yes</b>. You will need to apply for ethics through <a href = "https://www.myresearchproject.org.uk/Signin.aspx" target = "_new">IRAS</a>. This requires you to create a
                   log in and then submit an ethical application to the relevant area of
                   research.</p>
               <p>Subsequently an approved application letter must be uploaded onto the AECC University College online
                   system for approval. </p>
           </Popup>
       }><span className={styles.tooltipIcon}><Information size={18}/></span>
                    </TooltipHost>


                    </span>
                <Field className={styles.radioSelect} id="screen02" type="radio" name="screen02" value="false"
                       disabled={readOnly}/>No<br/>
                <Field className={styles.radioSelect} id="screen02" type="radio" name="screen02" value="true"
                       disabled={readOnly}/>Yes<br/>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"screen02"}/>
            </Question>

            <Question className={styles.question} errors={errors.screen03} touched={touched.screen03}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>03</span>Will the study require access to patient records from the AECC University College clinic?

                           <TooltipHost styles={styles.inlineTooltip} align="end" direction="right" content={
                               <Popup title="Patient Record Usage">
                                   <p>If the answer is <b>yes</b>. You will need to complete the access to <NavLink
                                       to="/">Patient Records Form</NavLink>.</p>
                                   <p>The form must be uploaded onto the AECC University College online system for
                                       approval. </p>
                               </Popup>
                           }><span className={styles.tooltipIcon}><Information size={18}/></span>
                    </TooltipHost>


                    </span>
                <Field className={styles.radioSelect} id="screen03" type="radio" name="screen03" value="false"
                       disabled={readOnly}/>No<br/>
                <Field className={styles.radioSelect} id="screen03" type="radio" name="screen03" value="true"
                       disabled={readOnly}/>Yes<br/>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"screen03"}/>
            </Question>

            <Question className={styles.question} errors={errors.screen04} touched={touched.screen04}>
                <span className={styles.question}><span className = {styles.questionIdentifier}>04</span>Does your research involve animals?</span>
                <Field className={styles.radioSelect} id="screen04" type="radio" name="screen04" value="false"
                       disabled={readOnly}/>No<br/>
                <Field className={styles.radioSelect} id="screen04" type="radio" name="screen04" value="true"
                       disabled={readOnly}/>Yes<br/>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"screen04"}/>
            </Question>


            <Question className={styles.question} errors={errors.screen05} touched={touched.screen05}>
                <span className={styles.question}><span className = {styles.questionIdentifier}>05</span>Will the study involve participants who are vulnerable or unable to give informed consent (e.g. children under 16, people with declared mental health issues, prisoners, people in health and social care settings, addicts, those with learning difficulties or cognitive impairments – or for faculty, your own students)?</span>

                <Field className={styles.radioSelect} id="screen05" type="radio" name="screen05" value="false"
                       disabled={readOnly}/>No<br/>
                <Field className={styles.radioSelect} id="screen05" type="radio" name="screen05" value="true"
                       disabled={readOnly}/>Yes<br/>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"screen05"}/>
            </Question>

            <Question className={styles.question} errors={errors.screen06} touched={touched.screen06}>
                <span className={styles.question}><span className = {styles.questionIdentifier}>06</span>Will your research involve deliberately misleading participants in anyway?</span>
                <Field className={styles.radioSelect} id="screen06" type="radio" name="screen06" value="false"
                       disabled={readOnly}/>No<br/>
                <Field className={styles.radioSelect} id="screen06" type="radio" name="screen06" value="true"
                       disabled={readOnly}/>Yes<br/>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"screen06"}/>
            </Question>

            <Question className={styles.question} errors={errors.screen07} touched={touched.screen07}>
                <span className={styles.question}><span className = {styles.questionIdentifier}>07</span>Will financial inducements (other than reasonable expenses and compensations for time) be offered to participants?</span>
                <Field className={styles.radioSelect} id="screen07" type="radio" name="screen07" value="false"
                       disabled={readOnly}/>No<br/>
                <Field className={styles.radioSelect} id="screen07" type="radio" name="screen07" value="true"
                       disabled={readOnly}/>Yes<br/>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"screen07"}/>
            </Question>

            <Question className={styles.question} errors={errors.screen08} touched={touched.screen08}>
                <span className={styles.question}><span className = {styles.questionIdentifier}>08</span>Are there risks of participants experiencing either physical or psychological distress or discomfort?</span>
                <Field className={styles.radioSelect} id="screen08" type="radio" name="screen08" value="false"
                       disabled={readOnly}/>No<br/>
                <Field className={styles.radioSelect} id="screen08" type="radio" name="screen08" value="true"
                       disabled={readOnly}/>Yes<br/>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"screen08"}/>
            </Question>

            <Question className={styles.question} errors={errors.screen09} touched={touched.screen09}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>09</span>Will personally identifiable data other than gender and age of participant be collected as part of the study?

                                               <TooltipHost styles={styles.inlineTooltip} align="end" direction="right"
                                                            content={
                                                                <Popup title="Personally identifiable data">
                                                                    <p>This might include linking data to or collecting
                                                                        items like email addresses, or postcodes that
                                                                        could be used to link your data to an
                                                                        individual.</p>
                                                                </Popup>
                                                            }><span className={styles.tooltipIcon}><Information
                                                   size={18}/></span>
                    </TooltipHost>



                    </span>
                <Field className={styles.radioSelect} id="screen09" type="radio" name="screen09" value="false"
                       disabled={readOnly}/>No<br/>
                <Field className={styles.radioSelect} id="screen09" type="radio" name="screen09" value="true"
                       disabled={readOnly}/>Yes<br/>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"screen09"}/>
            </Question>

            <Question className={styles.question} errors={errors.screen10} touched={touched.screen10}>
                <span className={styles.question}><span className = {styles.questionIdentifier}>10</span>Are drug placebos or other substances (e.g. food substances, vitamins) to be administered to participants, or will the study involve invasive, intrusive, or potentially harmful procedures?</span>
                <Field className={styles.radioSelect} id="screen10" type="radio" name="screen10" value="false"
                       disabled={readOnly}/>No<br/>
                <Field className={styles.radioSelect} id="screen10" type="radio" name="screen10" value="true"
                       disabled={readOnly}/>Yes<br/>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"screen10"}/>
            </Question>

            <Question className={styles.question} errors={errors.screen11} touched={touched.screen11}>
                <span className={styles.question}><span className = {styles.questionIdentifier}>11</span>Will blood or tissue samples be obtained from participants?</span>
                <Field className={styles.radioSelect} id="screen11" type="radio" name="screen11" value="false"
                       disabled={readOnly}/>No<br/>
                <Field className={styles.radioSelect} id="screen11" type="radio" name="screen11" value="true"
                       disabled={readOnly}/>Yes<br/>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"screen11"}/>
            </Question>

            <Question className={styles.question} errors={errors.screen12} touched={touched.screen12}>
                <span className={styles.question}><span className = {styles.questionIdentifier}>12</span>Will the study involve prolonged or repetitive testing?</span>
                <Field className={styles.radioSelect} id="screen12" type="radio" name="screen12" value="false"
                       disabled={readOnly}/>No<br/>
                <Field className={styles.radioSelect} id="screen12" type="radio" name="screen12" value="true"
                       disabled={readOnly}/>Yes<br/>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"screen12"}/>
            </Question>

            <Question className={styles.question} errors={errors.screen13} touched={touched.screen13}>
                <span className={styles.question}><span className = {styles.questionIdentifier}>13</span>Will the study involve sensitive topics that might be considered offensive, distressing, politically or socially sensitive, deeply personal, or in breach of the IRAS (e.g. criminal activities, sexual behaviour, personal appearance, experience of violence, addiction, religion, or financial circumstances)?</span>
                <Field className={styles.radioSelect} id="screen13" type="radio" name="screen13" value="false"
                       disabled={readOnly}/>No<br/>
                <Field className={styles.radioSelect} id="screen13" type="radio" name="screen13" value="true"
                       disabled={readOnly}/>Yes<br/>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"screen13"}/>
            </Question>

            <Question className={styles.question} errors={errors.screen13b} touched={touched.screen13b}>
                            <span className={styles.question}><span className = {styles.questionIdentifier}>14</span>Will the study involve using only secondary data?

                          <TooltipHost styles={styles.inlineTooltip} align="end" direction="right" content={
                              <Popup title="Secondary data">
                                  <p>Secondary data may refer to performing systematic review, meta analysis, data
                                      previously collected or analysed, using only open access datasets, or data covered
                                      by a previous ethical approval </p>
                              </Popup>
                          }><span className={styles.tooltipIcon}><Information size={18}/></span>
                    </TooltipHost>


                            </span>
                <Field className={styles.radioSelect} id="screen13b" type="radio" name="screen13b" value="false"
                       disabled={readOnly}/>No<br/>
                <Field className={styles.radioSelect} id="screen13b" type="radio" name="screen13b" value="true"
                       disabled={readOnly}/>Yes<br/>
                <ErrorMessage className={styles.validationWarning} component="div"
                              name={"screen13b"}/>
            </Question>


            <div className={styles.nextContainer}>
                {fastTrackEligible() ? (
                    <Link to="/application/step05" className={styles.next}>Next</Link>
                ) : (
                    <Link to="/application/step05" className={styles.next}>Next</Link>
                )}
            </div>

        </div>


    )
}

