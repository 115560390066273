import React, {Component} from "react";


import styles from "../../styles/form.module.css";
import {Redirect, withRouter} from "react-router-dom";
import ApplicationService from "../../Services/ApplicationService";
import MessageDisplay from "../../components/MessageDisplay";
import BaseContext from "../../util/BaseContext";
import DateFormat from "../../util/DateFormat";
import ReviewerService from "../../Services/ReviewerService";

import {
    DataTable, Table, Accordion,
    AccordionItem,
    Modal, TableBody, TableContainer,
    TableExpandHeader,
    TableHead, TableHeader,
    TableRow,
    Tooltip, TableExpandRow, TableCell, TableExpandedRow, Pagination, OverflowMenu, OverflowMenuItem

} from '@carbon/react'

import {Lightning } from '@carbon/icons-react';

import FluentTheme from "../../Services/FluentTheme";



/**
 * Defines the table headings for our applicant Lists
 *
 */
const activeApplicationsHeaders = [


    {key: "creationDate", header: "Created", isSortable: false, display: true},
    {key: "fastTrack", header: "", isSortable: false, display: false},
    {key: "researcherSchool", header: "School", isSortable: true, display: true},
    {key: "applicantName", header: "Name", isSortable: false, display: true},
    {key: "studyTitle", header: "Title", isSortable: false, display: true},
    {key: "status", header: "Status", isSortable: true, display: true},


];



//const pagination = { pageSize: 3, page: 1 };


class ApplicationAdmin extends Component {

    static contextType = BaseContext;

    constructor(props) {
        super(props);

        this.state = {

            //Pagination setting
            pageSize: 10,
            page: 1,


            ready: false,
            redirectTo: "",
            submitting: false,

            displayList: false,

            openSupervisorModal: false,
            openReviewerModal: false,


            //Pagination details for Active Applications
            ADMIN_pageNumber: 0,
            ADMIN_recordCount: null,  //Total number of records
            ADMIN_pageCount: null,
            ADMIN_db_pageSize: 100,//DB call page size
            ADMIN_pageSize: 25,
            ADMIN_currentPage: 1,
            ADMIN_rows:[],


            ADMIN2_applications: [],
            ADMIN2_pageNumber: 0,
            ADMIN2_recordCount: null,
            ADMIN2_pageCount: null,
            ADMIN2_pageSize: 10,


            REVIEWER_records: [],
            REVIEWER_pageNumber: 0,
            REVIEWER_recordCount: null,
            REVIEWER_pageCount: null,
            REVIEWER_pageSize: 10,

            SUPERVISOR_records: [],
            SUPERVISOR_pageNumber: 0,
            SUPERVISOR_recordCount: null,
            SUPERVISOR_pageCount: null,
            SUPERVISOR_pageSize: 10,


            SELECT_reviewers: [],

            progressId: null,
            progressSchool: null,


            displaySupervisors: false,
            displayReviewers: false,


            //Data for datatable new style.

            applicationsInProgress: [],


        };
    }


    componentDidMount() {
        //clear out any exiting applicationID

        const {applicationId, setApplicationId, readOnly, setReadOnly, globalMessage, setGlobalMessage} = this.context;

        setApplicationId(null);
        setReadOnly(false);
        setGlobalMessage("");

        //Load in the application List
//        this.getActiveApplications(0, this.state.ADMIN_pageSize);
        this.getActiveApplications(0, this.state.ADMIN_db_pageSize);





        //Load in the Old Applications List
        this.getCompletedApplications(0, this.state.ADMIN2_pageSize);


        //Load the full list of active reviewers to populate our drop downs
        this.getActiveReviewers();

        //Load the pageable list of reviewers for the admin interface
        //   this.getAllReviewers(0, this.state.REVIEWER_pageSize);

        //Load the pageable list of supervisors for the admin interface
        //   this.getAllSupervisors(0, this.state.SUPERVISOR_pageSize);

    }

    getActiveApplications(pageNo, pageSize) {
//        if (!this.state.submitting) {
        if (true) {

            const {globalMessage, setGlobalMessage} = this.context;
            setGlobalMessage("");

            this.setState({
                submitting: true
            })



            ApplicationService.getActiveList(
                pageNo,
                pageSize
            ).then(
                response => {
                    let applications = response.data.applications;


                    let rows = applications.slice((this.state.ADMIN_currentPage - 1 ) * this.state.ADMIN_pageSize, (this.state.ADMIN_currentPage - 1 ) * this.state.ADMIN_pageSize + this.state.ADMIN_pageSize);

                    this.setState({
                        submitting: false,
//                        ADMIN_applications: response.data.applications,
                        ADMIN_pageNumber: response.data.pageNumber,
                        ADMIN_recordCount: response.data.recordCount,
                        ADMIN_pageCount: response.data.pageCount,


                        applicationsInProgress: applications,
                        ADMIN_rows: rows,
                    })
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        submitting: false,
                    });
                    setGlobalMessage(resMessage);

                    document.getElementById("messageBox").scrollIntoView();
                }
            );
        }
    }

    getCompletedApplications(pageNo, pageSize) {
//        if (!this.state.submitting) {
        if (true) {

            const {globalMessage, setGlobalMessage} = this.context;
            setGlobalMessage("");

            this.setState({
                submitting: true
            })

            ApplicationService.getCompletedList(
                pageNo,
                pageSize
            ).then(
                response => {
                    this.setState({
                        submitting: false,
                        ADMIN2_applications: response.data.applications,
                        ADMIN2_pageNumber: response.data.pageNumber,
                        ADMIN2_recordCount: response.data.recordCount,
                        ADMIN2_pageCount: response.data.pageCount,
                    })
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        submitting: false,
                    });
                    setGlobalMessage(resMessage);

                    document.getElementById("messageBox").scrollIntoView();
                }
            );
        }
    }

    getActiveReviewers() {

        const {globalMessage, setGlobalMessage} = this.context;
        setGlobalMessage("");


        //       if (!this.state.submitting) {
        if (true) {

            this.setState({
                submitting: true,
            })

            ReviewerService.getActiveReviewers(

            ).then(
                response => {
                    this.setState({
                        submitting: false,
                        SELECT_reviewers: response.data.reviewers,
                    })
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        submitting: false,
                    });

                    setGlobalMessage(resMessage);

                    document.getElementById("messageBox").scrollIntoView();
                }
            );
        }
    }

    assignReviewers(progressId) {

        console.log("in assign reviewers")

        const {globalMessage, setGlobalMessage} = this.context;
        setGlobalMessage("");


//        let assessors = document.getElementById("assessorList");
        let assessorListFirst = document.getElementById("assessorListFirst");
        let assessorListSecond = document.getElementById("assessorListSecond");

        let selectedAssessors = [];


        for (var i = 0; i < assessorListFirst.length; i++) {
            if (assessorListFirst.options[i].selected) {
                let obj = {id: assessorListFirst.options[i].value}

                selectedAssessors.push(obj);
            }
        }

        if (selectedAssessors.length < 1) {
            setGlobalMessage("Please select your reviewers");
            return
        }



        if (!this.state.fastTrack) {

            for (var i = 0; i < assessorListSecond.length; i++) {
                if (assessorListSecond.options[i].selected) {
                    let obj = {id: assessorListSecond.options[i].value}

                    selectedAssessors.push(obj);
                }
            }


            if (selectedAssessors.length < 1) {
                setGlobalMessage("Please select your reviewers");
                return
            }

            //TODO relies on us keeping only 1 entry per array
            if (selectedAssessors.at(0).id === selectedAssessors.at(1).id ) {
                setGlobalMessage("Lead and second reviewer cannot be the same ");
                return
            }


        }





        ReviewerService.assignReviewers(progressId, selectedAssessors).then(
            response => {

                console.log("received response to set reviewers");

                this.setState({
                    submitting: false,
                    message: "",
                })
//                this.getActiveApplications(this.state.ADMIN_pageNumber, this.state.ADMIN_pageSize);
                this.getActiveApplications(0, this.state.ADMIN_db_pageSize);


            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({
                    submitting: false,
                    message: resMessage
                });
                document.getElementById("messageBox").scrollIntoView();
            }
        )
    }

    approveApplication(applicationId) {
        const {globalMessage, setGlobalMessage} = this.context;
        setGlobalMessage("");

        if (!this.state.submitting) {
            this.setState({
                submitting: true,
            })

            ApplicationService.approve(applicationId)
                .then(
                    response => {
                        this.setState({
                            submitting: false,
                        })
           //             this.getActiveApplications(this.state.ADMIN_pageNumber, this.state.ADMIN_pageSize);
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.setState({
                            submitting: false,
                        });
                        setGlobalMessage(resMessage);
                        document.getElementById("messageBox").scrollIntoView();
                    }
                );
        }
    }

    declineMinorApplication(applicationId) {
        const {globalMessage, setGlobalMessage} = this.context;
        setGlobalMessage("");

        if (!this.state.submitting) {
            this.setState({
                submitting: true,
            })

            ApplicationService.declineMinor(applicationId)
                .then(
                    response => {
                        this.setState({
                            submitting: false,
                        })
//                        this.getActiveApplications(this.state.ADMIN_pageNumber, this.state.ADMIN_pageSize);
                        this.getActiveApplications(0, this.state.ADMIN_db_pageSize);
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.setState({
                            submitting: false,
                        });
                        setGlobalMessage(resMessage);
                        document.getElementById("messageBox").scrollIntoView();
                    }
                );
        }
    }

    declineMajorApplication(applicationId) {
        const {globalMessage, setGlobalMessage} = this.context;
        setGlobalMessage("");

        if (!this.state.submitting) {
            this.setState({
                submitting: true,
            })

            ApplicationService.declineMajor(applicationId)
                .then(
                    response => {
                        this.setState({
                            submitting: false,
                        })
//                        this.getActiveApplications(this.state.ADMIN_pageNumber, this.state.ADMIN_pageSize);
                        this.getActiveApplications(0, this.state.ADMIN_db_pageSize);

                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.setState({
                            submitting: false,
                        });
                        setGlobalMessage(resMessage);
                        document.getElementById("messageBox").scrollIntoView();
                    }
                );
        }
    }

    rejectApplication(applicationId) {
        const {globalMessage, setGlobalMessage} = this.context;
        setGlobalMessage("");

        if (!this.state.submitting) {
            this.setState({
                submitting: true,
            })

            ApplicationService.reject(applicationId)
                .then(
                    response => {
                        this.setState({
                            submitting: false,
                        })
//                        this.getActiveApplications(this.state.ADMIN_pageNumber, this.state.ADMIN_pageSize);
                        this.getActiveApplications(0, this.state.ADMIN_db_pageSize);
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.setState({
                            submitting: false,
                        });
                        setGlobalMessage(resMessage);
                        document.getElementById("messageBox").scrollIntoView();
                    }
                );
        }
    }


    headerProps() {
        return {isSortable: true}
    }

    render() {

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }


        const {

            ADMIN_pageNumber,
            ADMIN_recordCount,
            ADMIN_pageCount,
            ADMIN_pageSize,
            ADMIN_currentPage,
            ADMIN_db_pageCount,
            ADMIN_rows,



            ADMIN2_applications,
            ADMIN2_pageNumber,
            ADMIN2_recordCount,
            ADMIN2_pageCount,
            ADMIN2_pageSize,


            SELECT_reviewers
        } = this.state;


        const {setApplicationId, readOnly, setReadOnly, globalMessage} = this.context;
        const {
            displayList,
            progressId,
            progressSchool,
            displayReviewers,
            displaySupervisors,
            openSupervisorModal,
            openReviewerModal
        } = this.state;



        //Import the current applications in progress
        const {applicationsInProgress} = this.state;


        //Pagination values, contained in state so datatable and pagination can remain in sync
        const {pageSize, page} = this.state;


        return (
            <div>

                <div className={styles.pageTitleContainer}>
                    <h3 className={styles.pageTitle}>Administration</h3>
                </div>


                <FluentTheme>
                    <MessageDisplay message={globalMessage}/>
                    <Accordion>
                        <AccordionItem title="Active applications" open={true}>
                            <Modal
                                passiveModal="true"
                                open={displayList}
                                onRequestClose={() => {
                                    this.setState({displayList: false})
                                }}
                                size="sm"
                            >
                                <div className={styles.displayList}>
                                    <h5 className={styles.noteHeader}>Assign Reviewer(s)</h5>
                                    <div className={styles.noteBody}>
                                        <table>
                                            <tr>
                                                <td>
                                                    <h4>Choose lead reviewer</h4>
                                                    <select size = "10" id="assessorListFirst" name="assessorListFirst"
                                                            className={styles.listOptions}>
                                                        {SELECT_reviewers && SELECT_reviewers.map((reviewer, index) => (
                                                            <>
                                                                {(progressSchool === reviewer.school) && (
                                                                    <>
                                                                        <option
                                                                            value={reviewer.id}>{reviewer.name}  </option>
                                                                    </>
                                                                )}
                                                            </>
                                                        ))}
                                                    </select>
                                                </td>
                                            </tr>

                                            {!this.state.fastTrack && (

                                            <tr>
                                                <td>
                                                    <h4>Choose second reviewer</h4>
                                                    <select size="10" id="assessorListSecond" name="assessorListSecond"
                                                            className={styles.listOptions}>
                                                        {SELECT_reviewers && SELECT_reviewers.map((reviewer, index) => (
                                                            <>
                                                                {(progressSchool === reviewer.school) && (
                                                                    <>
                                                                        <option
                                                                            value={reviewer.id}>{reviewer.name}  </option>
                                                                    </>
                                                                )}
                                                            </>
                                                        ))}
                                                    </select>
                                                </td>
                                            </tr>

                                            )}
                                            <tr>
                                                <td valign="bottom">
                                                    <div className={styles.bigLink} onClick={

                                                        () => {
                                                            this.assignReviewers(progressId);
                                                            this.setState({displayList: false});
                                                        }
                                                    }>Assign
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>


                                    </div>
                                </div>
                            </Modal>


                            <DataTable
                                isSortable
                                rows={ADMIN_rows}
                                headers={activeApplicationsHeaders}
                                overflowMenuOnHover={false}
                            >
                                {({
                                      rows,
                                      headers,
                                      getHeaderProps,
                                      getRowProps,
                                      getTableProps,
                                      getTableContainerProps,
                                  }) => (
                                    <TableContainer
                                       // title="Active applications"
                                       // description="List of active applications"

                                        {...getTableContainerProps()}>

                                        <Table  {...getTableProps()}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableExpandHeader/>
                                                    {headers.map((header, i) => (
                                                        <>

                                                        <TableHeader {...getHeaderProps({
                                                            header,
                                                            key: i,
                                                            isSortable: header.isSortable,
                                                            colSpan: header.colSpan
                                                        })  }>
                                                            {header.header}
                                                        </TableHeader>


                                                        </>
                                                    ))}
                                                    <TableHeader>

                                                    </TableHeader>

                                                </TableRow>
                                            </TableHead>

                                            <TableBody>

                                                {rows.map((row,k) => (

                                                    <>
                                                        <TableExpandRow key={row.id}   {...getRowProps({row })}>
                                                            {row.cells.map((cell, j) => (
                                                                <>
                                                                    <TableCell key={"cell" + k + " " + j }>
                                                                        {j === 0 ? (
                                                                            <>
                                                                                {DateFormat.formatDateOnly(cell.value)}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {j === 1 ? (
                                                                                    <>
                                                                                    {cell.value === false ? (<></>) : (<><Lightning /></>)}
                                                                                    </>

                                                                                ) : (
                                                                                    <>
                                                                                    {cell.value}
                                                                                    </>
                                                                                )}

                                                                            </>
                                                                        )}

                                                                    </TableCell>
                                                                </>
                                                            ))}





                                                            <TableCell className="cds--table-column-menu">
                                                                {applicationsInProgress[k] && applicationsInProgress[k].status === "REVIEW" && (

                                                                    <OverflowMenu  size="sm" >

                                                                        <OverflowMenuItem itemText="Reviewers"
                                                                                          onClick={(e) => {
                                                                                            this.setState({
                                                                                            displayList: true,
                                                                                            progressId: applicationsInProgress[k].id,
                                                                                            progressSchool: applicationsInProgress[k].researcherSchool,
                                                                                            fastTrack: applicationsInProgress[k].fastTrack,
                                                                            });
                                                                        }}
                                                                        ></OverflowMenuItem>









                                                                </OverflowMenu>
                                                                )}

                                                            </TableCell>

                                                        </TableExpandRow>
                                                        <TableExpandedRow {...getRowProps({row})} colSpan={headers.length + 3} >
                                                            <>
                                                                <Table className = {styles.expandedRowNested}>
                                                                    <tr>
                                                                        <td><strong>Applicant Email</strong></td>
                                                                        <td>{applicationsInProgress[k].username}</td>
                                                                        <td>&nbsp;</td>
                                                                        <td className = {styles.dataTableNestedContainer} rowSpan={5} valign={"top"}>
                                                                        <table className={styles.dataTableNested}>
                                                                            <tr>
                                                                                <td className={styles.center}>
                                                                                    <button title="View the form" className={styles.filterButton}
                                                                                            onClick={() => {
                                                                                                setApplicationId(applicationsInProgress[k].id);
                                                                                                setReadOnly(true);
                                                                                                this.props.history.push("/application/step01", {});
                                                                                            }}
                                                                                    >
                                                                                        &#9655;
                                                                                    </button>
                                                                                </td>
                                                                                <td className={styles.center}>
                                                                                    <button title="Approve the application"
                                                                                            className={styles.filterButton}
                                                                                            onClick={() => {
                                                                                                // eslint-disable-next-line no-restricted-globals
                                                                                                if (confirm("Please confirm you wish to indicate your approval of this application")) {
                                                                                                    this.approveApplication(applicationsInProgress[k].id)
                                                                                                }
                                                                                            }}>

                                                                                        &#10004;
                                                                                    </button>
                                                                                </td>
                                                                                <td className={styles.center}>
                                                                                    <button
                                                                                        title="Reject the application, allowing resubmission and supervisor final approval"
                                                                                        className={styles.filterButton}
                                                                                        onClick={() => {
                                                                                            // eslint-disable-next-line no-restricted-globals
                                                                                            if (confirm("Reject the application, allowing resubmission and supervisor final approval")) {
                                                                                                this.declineMinorApplication(applicationsInProgress[k].id)
                                                                                            }
                                                                                        }}>
                                                                                        &#8630;
                                                                                    </button>
                                                                                </td>
                                                                                <td className={styles.center}>
                                                                                    <button
                                                                                        title="Reject the application, allowing resubmission and repeat of full process"
                                                                                        className={styles.filterButton}
                                                                                        onClick={() => {
                                                                                            // eslint-disable-next-line no-restricted-globals
                                                                                            if (confirm("Rejection the application, allowing resubmission with full review")) {
                                                                                                this.declineMajorApplication(applicationsInProgress[k].id)
                                                                                            }
                                                                                        }}>
                                                                                        &#8634;
                                                                                    </button>
                                                                                </td>
                                                                                <td className={styles.center}>
                                                                                    <button title="Reject the application outright"
                                                                                            className={styles.filterButton}
                                                                                            onClick={() => {
                                                                                                // eslint-disable-next-line no-restricted-globals
                                                                                                if (confirm("Decline outright and allow no further considerations")) {
                                                                                                    this.rejectApplication(applicationsInProgress[k].id)
                                                                                                }
                                                                                            }}>
                                                                                        &#10006;
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><strong>Lead Reviewer</strong></td>
                                                                        <td>{applicationsInProgress[k].reviewers !== null && (<>{applicationsInProgress[k].reviewers[0].name}</> )}  </td>
                                                                        <td className={styles.center}>
                                                                            <span
                                                                                className={styles.assessorOutcome}>
                                                                            {applicationsInProgress[k].reviewers  !== null && (
                                                                                <>
                                                                                    {applicationsInProgress[k].reviewers[0].outcome === "APPROVE" && (
                                                                                        <>&#10004;</>
                                                                                    )}
                                                                                    {applicationsInProgress[k].reviewers[0].outcome === "DRAFT2" && (
                                                                                        <>&#8630;</>
                                                                                    )}
                                                                                    {applicationsInProgress[k].reviewers[0].outcome === "DRAFT1" && (
                                                                                        <>&#8634;</>
                                                                                    )}
                                                                                    {applicationsInProgress[k].reviewers[0].outcome === "REJECT" && (
                                                                                        <>&#10006;</>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                            </span>
                                                                        </td>





                                                                    </tr>
                                                                    <tr>
                                                                        <td><strong>Second Reviewer</strong></td>
                                                                        <td>{applicationsInProgress[k].reviewers !== null && !applicationsInProgress[k].fastTrack && (<>{applicationsInProgress[k].reviewers[1].name}</> )}  </td>
                                                                        <td className={styles.center}>
                                                                            <span
                                                                                className={styles.assessorOutcome}>
                                                                            {applicationsInProgress[k].reviewers  !== null && !applicationsInProgress[k].fastTrack && (
                                                                                <>
                                                                                    {applicationsInProgress[k].reviewers[1].outcome === "APPROVE" && (
                                                                                        <>&#10004;</>
                                                                                    )}
                                                                                    {applicationsInProgress[k].reviewers[1].outcome === "DRAFT2" && (
                                                                                        <>&#8630;</>
                                                                                    )}
                                                                                    {applicationsInProgress[k].reviewers[1].outcome === "DRAFT1" && (
                                                                                        <>&#8634;</>
                                                                                    )}
                                                                                    {applicationsInProgress[k].reviewers[1].outcome === "REJECT" && (
                                                                                        <>&#10006;</>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                            </span>
                                                                        </td>

                                                                    </tr>

                                                                <tr>
                                                                    <td><strong>Last Updated</strong></td>
                                                                    <td>{DateFormat.formatDate(applicationsInProgress[k].updatedDate)}</td>
                                                                    <td>&nbsp;</td>

                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Approval Id</strong></td>
                                                                    <td>{applicationsInProgress[k].applicationIdString}</td>
                                                                    <td>&nbsp;</td>

                                                                </tr>

                                                                </Table>

                                                            </>
                                                        </TableExpandedRow>


                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </DataTable>


                            <Pagination onChange={(pageInfo) => {

                                alert("Setting current page:" + pageInfo.page + " PageSize:" + pageInfo.pageSize);
                     //           console.log("ADMIN_Rows");
                     //           console.log(applicationsInProgress.slice((ADMIN_currentPage - 1 ) * ADMIN_pageSize, (ADMIN_currentPage - 1 ) * ADMIN_pageSize + ADMIN_pageSize));

                                this.setState(
                                    {
                                        ADMIN_currentPage: pageInfo.page,
                                        ADMIN_pageSize: pageInfo.pageSize,
                                        ADMIN_rows: applicationsInProgress.slice((ADMIN_currentPage - 1 ) * ADMIN_pageSize, (ADMIN_currentPage - 1 ) * ADMIN_pageSize + ADMIN_pageSize)
                                    }
                                )
                            }}


                                pageSize={ADMIN_pageSize}
                                pageSizes={[{text:"Twenty five", value: 25}]}
                                page={ADMIN_currentPage}
                                totalItems={applicationsInProgress.length}
                                //pageSizeInputDisabled
                            />

                            {/*
                            <table className={styles.listTable}>
                                <tr>
                                    <th width="10%" title="Time of last update">Updated</th>
                                    <th width="11%" title="School">School</th>
                                    <th width="25%" title="Username of applicant">Owner</th>
                                    <th width="21%" title="Reviewers assigned to this application">Reviewers</th>
                                    <th width="3%" title="Confirm assignment"></th>
                                    <th width="15%" title="Current stage of the application">Status</th>
                                    <th width="3%" title="View &#9655; the form">View</th>
                                    <th width="3%" title="Approve the application">&#10004;</th>
                                    <th width="3%"
                                        title="Reject &#8630; the application, allowing resubmission and supervisor final approval &#10004">&#8630;</th>
                                    <th width="3%"
                                        title="Reject &#8634; the application, allowing resubmission and full process">&#8634;</th>
                                    <th width="3%" title="Reject &#10006; the application outright">&#10006;</th>

                                </tr>

                                {ADMIN_applications && ADMIN_applications.map((application, index) => (
                                        <tr key={index}>
                                            <td className={styles.center}
                                                title={DateFormat.formatDate(application.updatedDate)}>{DateFormat.formatDateOnly(application.updatedDate)}</td>
                                            <td className={styles.center}>{application.researcherSchool}</td>
                                            <td className={styles.left}
                                                title={application.username}>{application.applicantName}</td>


                                            <td className={styles.center}>
                                                <>
                                                    {application.reviewers && application.reviewers.map((reviewer, j) => (
                                                        <>
                                                            <div className={styles.assessor} title={reviewer.username}>
                                                                <span>{reviewer.name}</span><span
                                                                className={styles.assessorOutcome}>

                                            {reviewer.outcome === "APPROVE" && (
                                                <>&#10004;</>
                                            )}
                                                                {reviewer.outcome === "DRAFT2" && (
                                                                    <>&#8630;</>
                                                                )}
                                                                {reviewer.outcome === "DRAFT1" && (
                                                                    <>&#8634;</>
                                                                )}
                                                                {reviewer.outcome === "REJECT" && (
                                                                    <>&#10006;</>
                                                                )}

                                               </span></div>
                                                        </>

                                                    ))}
                                                </>
                                            </td>

                                            <td>
                                                {application.status === "REVIEW" && (
                                                    <>
                                                        <button title="Assign Assessors" className={styles.addButton}
                                                                onClick={(e) => {

                                                                    this.setState({
                                                                        displayList: true,
                                                                        progressId: application.id,
                                                                        progressSchool: application.researcherSchool

                                                                    });
                                                                }}
                                                        >
                                                            ✚
                                                        </button>

                                                    </>
                                                )}


                                            </td>
                                            <td className={styles.center}>{application.status}</td>
                                            <td className={styles.center}>
                                                <button title="View the form" className={styles.filterButton}
                                                        onClick={() => {
                                                            setApplicationId(application.id);
                                                            setReadOnly(true);
                                                            this.props.history.push("/application/step01", {});
                                                        }}
                                                >
                                                    &#9655;
                                                </button>
                                            </td>
                                            <td className={styles.center}>
                                                <button title="Approve the application"
                                                        className={styles.filterButton}
                                                        onClick={() => {
                                                            // eslint-disable-next-line no-restricted-globals
                                                            if (confirm("Please confirm you wish to indicate your approval of this application")) {
                                                                this.approveApplication(application.id)
                                                            }
                                                        }}>

                                                    &#10004;
                                                </button>
                                            </td>
                                            <td className={styles.center}>
                                                <button
                                                    title="Reject the application, allowing resubmission and supervisor final approval"
                                                    className={styles.filterButton}
                                                    onClick={() => {
                                                        // eslint-disable-next-line no-restricted-globals
                                                        if (confirm("Reject the application, allowing resubmission and supervisor final approval")) {
                                                            this.declineMinorApplication(application.id)
                                                        }
                                                    }}>
                                                    &#8630;
                                                </button>
                                            </td>
                                            <td className={styles.center}>
                                                <button
                                                    title="Reject the application, allowing resubmission and repeat of full process"
                                                    className={styles.filterButton}
                                                    onClick={() => {
                                                        // eslint-disable-next-line no-restricted-globals
                                                        if (confirm("Rejection the application, allowing resubmission with full review")) {
                                                            this.declineMajorApplication(application.id)
                                                        }
                                                    }}>
                                                    &#8634;
                                                </button>
                                            </td>
                                            <td className={styles.center}>
                                                <button title="Reject the application outright"
                                                        className={styles.filterButton}
                                                        onClick={() => {
                                                            // eslint-disable-next-line no-restricted-globals
                                                            if (confirm("Decline outright and allow no further considerations")) {
                                                                this.rejectApplication(application.id)
                                                            }
                                                        }}>
                                                    &#10006;
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                )
                                }

                                <tr className={styles.footerRow}>
                                    <td colSpan="11">
                                        <div className={styles.flexContainer}>

                                            <div
                                                className={styles.tableStatsContainer}>Page {ADMIN_pageNumber + 1}/{ADMIN_pageCount} [Records: {ADMIN_recordCount}]
                                            </div>

                                            <div className={styles.spaceContainer}></div>
                                            <div className={styles.paginationContainer}>
                                                <div className={styles.previousButtonContainer}>
                                                    {ADMIN_pageNumber > 0 && (
                                                        <button className={styles.paginationButton}
                                                                onClick={() => {
                                                                    this.getPersonalApplications(ADMIN_pageNumber - 1, ADMIN_pageSize)
                                                                }}
                                                        >Previous</button>
                                                    )}
                                                </div>
                                                <div className={styles.nextButtonContainer}>
                                                    {(ADMIN_pageNumber + 1) < ADMIN_pageCount && (

                                                        <button className={styles.paginationButton}
                                                                onClick={(e) => {
                                                                    this.getPersonalApplications(ADMIN_pageNumber + 1, ADMIN_pageSize)
                                                                }}
                                                        >Next</button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>


                            </table>
*/}

                        </AccordionItem>

                        <AccordionItem title="Historic applications" open={false}>


                            <table className={styles.listTable}>
                                <tr>
                                    <th width="10%" title="Time of last update">Updated</th>
                                    <th width="11%" title="School">School</th>
                                    <th width="25%" title="Username of applicant">Owner</th>
                                    <th width="25%" title="Application Id">Application ID</th>
                                    <th width="15%" title="Current stage of the application">Status</th>
                                    <th width="3%" title="View &#9655; the form">View</th>

                                </tr>

                                {ADMIN2_applications && ADMIN2_applications.map((application, index) => (
                                        <tr key={index}>
                                            <td className={styles.center}
                                                title={DateFormat.formatDate(application.updatedDate)}>{DateFormat.formatDateOnly(application.updatedDate)}</td>
                                            <td className={styles.center}>{application.researcherSchool}</td>
                                            <td className={styles.left}
                                                title={application.username}>{application.applicantName}</td>
                                            <td className={styles.left}
                                                title={application.applicationIdString}>{application.applicationIdString}</td>


                                            <td className={styles.center}>{application.status}</td>
                                            <td className={styles.center}>
                                                <button title="View the form" className={styles.filterButton}
                                                        onClick={() => {
                                                            setApplicationId(application.id);
                                                            setReadOnly(true);
                                                            this.props.history.push("/application/step01", {});
                                                        }}
                                                >
                                                    &#9655;
                                                </button>
                                            </td>

                                        </tr>
                                    )
                                )
                                }

                                <tr className={styles.footerRow}>
                                    <td colSpan="11">
                                        <div className={styles.flexContainer}>

                                            <div
                                                className={styles.tableStatsContainer}>Page {ADMIN_pageNumber + 1}/{ADMIN_pageCount} [Records: {ADMIN_recordCount}]
                                            </div>

                                            <div className={styles.spaceContainer}></div>
                                            <div className={styles.paginationContainer}>
                                                <div className={styles.previousButtonContainer}>
                                                    {ADMIN2_pageNumber > 0 && (
                                                        <button className={styles.paginationButton}
                                                                onClick={() => {
                                                                    this.getCompletedApplications(ADMIN2_pageNumber - 1, ADMIN2_pageSize)
                                                                }}
                                                        >Previous</button>
                                                    )}
                                                </div>
                                                <div className={styles.nextButtonContainer}>
                                                    {(ADMIN2_pageNumber + 1) < ADMIN2_pageCount && (

                                                        <button className={styles.paginationButton}
                                                                onClick={(e) => {
                                                                    this.getCompletedApplications(ADMIN2_pageNumber + 1, ADMIN2_pageSize)
                                                                }}
                                                        >Next</button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>


                            </table>


                        </AccordionItem>

                    </Accordion>
                </FluentTheme>
            </div>


        );
    }
}

export default withRouter(ApplicationAdmin);