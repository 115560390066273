
import styles from "../../styles/form.module.css";
import {ErrorMessage, Field, useFormikContext} from "formik";
import Autosave from "../../components/Autosave";
import React, {useContext} from "react";
import {Link} from "react-router-dom";
import BaseContext from "../../util/BaseContext";
import MessageDisplay from "../../components/MessageDisplay";
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";
import Question from "../../components/Question";
import Gated from "../../components/Gated";

export default function Optional09() {

    const stage = "Optional09"

    const {readOnly, applicationId, globalMessage} = useContext(BaseContext);
    const {values, touched, errors} = useFormikContext();
    return (


                    <div>
                        <div className={styles.pageTitleContainer}>
                            <h3 className={styles.pageTitle}>Debriefing procedures</h3>
                            <span className={styles.autoSaveMessageContainer}>{!readOnly && (<Autosave />)}</span>
                            <FeedbackToggle readOnly = {readOnly} applicationId = {applicationId} stage = {stage} />



                        </div>




                        <NoteDisplay stage={stage} applicationId={applicationId} />

                        <Question className={styles.question} errors={errors.hasDebriefForm} touched={touched.hasDebriefForm}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01</span>Will your study debrief the participants?</span>
                            <Field className={styles.radioSelect} id="hasDebriefForm" type="radio" name="hasDebriefForm" value="false" disabled={readOnly} />No<br/>
                            <Field className={styles.radioSelect} id="hasDebriefForm" type="radio" name="hasDebriefForm" value="true" disabled={readOnly} />Yes<br/>
                            <ErrorMessage className={styles.validationWarning} component="div"
                                          name={"hasDebriefForm"}/>
                </Question>


                        {/*}
                        <Gated display={values.hasDebriefForm==1}>
                            <div className={styles.question}>
                                <span className={styles.question}>Please supply a copy of your form</span>

                                <input type="file" className={styles.upload} title="upload"/>

                            </div>
                        </Gated>
*/}


                        <Gated display={values.hasDebriefForm==='true'}>


                        <Question className={styles.question}  errors={errors.debriefFormPurpose} touched={touched.debriefFormPurpose}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01-B</span>Does the debrief provide participants with adequate information to understand the purpose of the study?</span>
                    <Field className={styles.radioSelect} id="debriefFormPurpose" type="radio" name="debriefFormPurpose" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="debriefFormPurpose" type="radio" name="debriefFormPurpose" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"debriefFormPurpose"}/>
                </Question>

                <Question className={styles.question} errors={errors.debriefFormContactDetails} touched={touched.debriefFormContactDetails}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01-C</span>Does the debrief provide participants with details of how to contact you should they wish to do so?</span>
                    <Field className={styles.radioSelect} id="debriefFormContactDetails" type="radio" name="debriefFormContactDetails" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="debriefFormContactDetails" type="radio" name="debriefFormContactDetails" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"debriefFormContactDetails"}/>
                </Question>

                <Question className={styles.question} errors={errors.debriefFormLinks} touched={touched.debriefFormLinks}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01-D</span>Does your debrief provide participants with details on how to find external sources of information and/or support relating to issues raise in this study?</span>
                    <Field className={styles.radioSelect} id="debriefFormLinks" type="radio" name="debriefFormLinks" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="debriefFormLinks" type="radio" name="debriefFormLinks" value="true"  disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"debriefFormLinks"}/>
                </Question>


                        </Gated>
                        <Gated display={values.hasDebriefForm==='false'}>

                        <Question className={styles.question} errors={errors.debriefFormJustification} touched={touched.debriefFormJustification}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01-A</span>Please provide a justification for the absence of a debrief.</span>
                    <Field as="textarea" name="debriefFormJustification" className={styles.inputLong} disabled={readOnly}/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"debriefFormJustification"}/>
                </Question>
                        </Gated>

                        <Gated display={values.debriefFormLinks==='false' || values.debriefFormContactDetails==='false' || values.debriefFormPurpose==='false' }>

                            <Question className={styles.question} errors={errors.debriefFormJustification} touched={touched.debriefFormJustification}>
                                <span className={styles.question}><span className = {styles.questionIdentifier}>01-F</span>Please provide a justification for your answers above.</span>
                                <Field as="textarea" name="debriefFormJustification" className={styles.inputLong} disabled={readOnly}/>
                                <ErrorMessage className={styles.validationWarning} component="div"
                                              name={"debriefFormJustification"}/>
                            </Question>
                        </Gated>



            <div className={styles.nextContainer}>
                <Link to="/application/optional10" className={styles.next}>Next</Link>
            </div>
                    </div>

    )
}

