import React, {Component} from "react";





export default class TimeSpinner extends Component {


    constructor(props) {
//TODO override default state with entries from props
//TODO calculate path lengths and adjust stylesheet dynamically

        super(props);
        this.state = {
            width: 20,
            height: 20,
            radius: 8,
            thickness: 2,
            duration:1,
            cx:10,
            cy:10,
            backgroundColour:"#ffffff",
            foregroundColour:"#e10098"
        };
    }

    componentDidMount() {
        if (this.props.backgroundColour != null ) {
            this.setState({
                backgroundColour: this.props.backgroundColour
            })
        }
    }

    render() {

        //If we're called without a display prop assume we're to be displayed
        //display prop exists only display when true
        if (this.props.display || this.props.display === undefined) {
            return (
                <div className="timeSpinnerContainer">
                    <svg className="workingBehind" width={`${this.state.width}px`} height={`${this.state.height}px`}>
                        <circle cx={`${this.state.cx}px`} cy={`${this.state.cy}px`} r={`${this.state.radius}px`} stroke={this.state.backgroundColour} stroke-width={`${this.state.thickness}px`} fill="none">
                        </circle>
                    </svg>
                    <svg className="workingFore" width={`${this.state.width}px`} height={`${this.state.height}px`}>
                        <circle cx={`${this.state.cx}px`} cy={`${this.state.cy}px`} r={`${this.state.radius}px`} stroke={this.state.foregroundColour} stroke-width={`${this.state.thickness}px`} fill="none">
                            <animateTransform attributeName="transform" type="rotate" from={`0 ${this.state.cx} ${this.state.cy}`} to={`360 ${this.state.cx} ${this.state.cy}`} dur={`${this.state.duration}s`} repeatCount="indefinite" />

                            <animate attributeType="css" attributeName="stroke-dashoffset" from="-50.3" to="0" dur="2s" repeatCount="indefinite"  />
                        </circle>

                    </svg>
                </div>
            );

        } else {
            return null;
        }

    }
}
