
import styles from "../../styles/form.module.css";
import {ErrorMessage, Field, useFormikContext} from "formik";
import Autosave from "../../components/Autosave";
import React, {useContext} from "react";
import Popup from "../../components/Popup";

import {Link} from "react-router-dom";
import BaseContext from "../../util/BaseContext";
import MessageDisplay from "../../components/MessageDisplay";
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Question from "../../components/Question";

import {TooltipHost} from "@fluentui/react";
import {Information} from "@carbon/icons-react";
import Gated from "../../components/Gated";

export default function Optional08() {

    const stage = "Optional08"

    const {readOnly, applicationId, globalMessage} = useContext(BaseContext);
    const {values, touched, errors} = useFormikContext();




    return (


                    <div>
                        <div className={styles.pageTitleContainer}>
                            <h3 className={styles.pageTitle}>Informed consent</h3>
                            <span className={styles.autoSaveMessageContainer}>{!readOnly && (<Autosave />)}</span>
                            <FeedbackToggle readOnly = {readOnly} applicationId = {applicationId} stage = {stage} />



                        </div>




                        <NoteDisplay stage={stage} applicationId={applicationId} />


                <Question className={styles.question} errors={errors.hasConsentForm} touched={touched.hasConsentForm}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01</span>Will your study have an information/consent form?</span>

                    <Field className={styles.radioSelect} id="hasConsentForm" type="radio" name="hasConsentForm" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="hasConsentForm" type="radio" name="hasConsentForm" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"hasConsentForm"}/>
                </Question>
                        {/*}
                        <Gated display={values.hasConsentForm==1}>
                        <div className={styles.question}>
                            <span className={styles.question}>Please supply a copy of your form</span>

                            <input type="file" className={styles.upload} title="upload" disabled={readOnly} />

                        </div>
                        </Gated>
                        */}

                <Question className={styles.question} errors={errors.canWithdraw} touched={touched.canWithdraw}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>02</span>Does the information/consent form inform participants of their right to withdraw from the study?</span>
                    <Field className={styles.radioSelect} id="canWithdraw" type="radio" name="canWithdraw" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="canWithdraw" type="radio" name="canWithdraw" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"canWithdraw"}/>
                </Question>



                <Question className={styles.question} errors={errors.informedConsent} touched={touched.informedConsent}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>03</span>Does the information/consent form provide adequate information to give informed consent?



                                                                 <TooltipHost styles = {styles.inlineTooltip} align="end" direction="right" content={
                                                                     <Popup title="Informed Consent">
                                                                         <p>Informed consent entails giving sufficient information about the research and ensuring that there is no explicit or implicit coercion so that prospective participants can make an informed and free decision on their possible involvement.</p>
                                                                     </Popup>
                                                                 }><span className = {styles.tooltipIcon}><Information size = {18}/></span>
                    </TooltipHost>

                    </span>
                    <Field className={styles.radioSelect} id="informedConsent" type="radio" name="informedConsent" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="informedConsent" type="radio" name="informedConsent" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"informedConsent"}/>
                </Question>


                        <Gated display={values.hasConsentForm==="false" || values.canWithdraw==="false" || values.informedConsent==="false"}>

                <Question className={styles.question} errors={errors.consentDetails} touched={touched.consentDetails}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>04</span>Please provide a justification for the consent options you've indicated.</span>
                    <Field as="textarea" name="consentDetails" className={styles.inputLong} disabled={readOnly} />
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"consentDetails"}/>
                </Question>
                        </Gated>


            <div className={styles.nextContainer}>
                <Link to="/application/optional09" className={styles.next}>Next</Link>
            </div>



                    </div>

    )
}

