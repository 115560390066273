

import styles from "../../styles/form.module.css";
import React, {useContext, useState} from 'react';
import Autosave from '../../components/Autosave';
import {ErrorMessage, FastField, Field, FieldArray, useFormikContext,} from 'formik';
import Popup from "../../components/Popup";
import ReactTooltip from "react-tooltip";
import Gated from "../../components/Gated";
import {Link} from "react-router-dom";
import MessageDisplay from "../../components/MessageDisplay";
import BaseContext from "../../util/BaseContext";
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Question from "../../components/Question";






export default function Success(props) {
    const {readOnly, applicationId, globalMessage, displayOptional, setDisplayOptional, setShortForm} = useContext(BaseContext);
    const {values, touched, errors} = useFormikContext();
    const [message, setMessage] = useState();










   return (
        <div>
            <div className={styles.pageTitleContainer}>
                <h3 className={styles.pageTitle}>Thank you</h3>
                <span className={styles.autoSaveMessageContainer}> </span>



            </div>


            <MessageDisplay message={message}/>

                <p>We've received your application, thankyou.</p>

            <div className={styles.nextContainer}>

                <div className={styles.bigLinkContainer}>
                    <Link to="/home" className={styles.bigLink}>home</Link>
                </div>

                <p>or</p>

                <div className={styles.bigLinkContainer}>
                    <Link to="/logout" className={styles.bigLink}>Logout</Link>
                </div>

            </div>

        </div>
    )
}
