import React, {Component} from 'react';
import {Formik, Form} from 'formik';
import ApplicationService from "../Services/ApplicationService";
import BaseContext from "../util/BaseContext";
import * as Yup from "yup";

/**
 * Sets up our formik component, hydrating the initialProps and defining the validations
 * required.
 *
 * We're not using inline validation as we're instead relying on a react effect to submit the
 * form for save on change.
 *
 * We can still call the validator on demand and have it populate the formik errors which will
 * display in screen when required.
 *
 * All fields are required except where they're a clarification field, eg you answered yes and
 * we now need to know why you answered yes, Those fields are typically hidden from view when
 * not required so for display purposes it matters not if formik flags them as not filled in, because
 * hidden data is present from the save load.
 *
 */
export default class FormikConfig extends Component {

    static contextType = BaseContext;

    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            redirectTo: "",
            submitting: false,
            message: "",
            application: {},
            validationSchema: ""
        };
      }


    componentDidMount() {

        const {applicationId, readOnly, shortForm, globalMessage, setGlobalMessage} = this.context;




        if (applicationId===null) {
            //no application id we can't proceed any further
        } else {

            ApplicationService.getApplication(applicationId).then(
                response => {
                    this.setState({
                        loading: false,
                        application: response.data,
                        message: ""
                    })
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.setState({
                        message: resMessage
                    });

                    document.getElementById("messageBox").scrollIntoView()
                }
            );
        }
    }


    render() {


        const {application} = this.state;
        const {shortForm, globalMessage, setGlobalMessage, readOnly, setReadOnly} = this.context;

        const initialValues= {
            id: application.id,
            //Step01
            applicantName: application.applicantName,
            singleStudy: application.singleStudy,
            applicantEmail: application.username,
            applicantTelephone: application.applicantTelephone,
            coApplicants: application.coApplicants,

            //Step02
            researcherSchool: application.researcherSchool,
            researcherType: application.researcherType,

//            supervisors: application.supervisors,
//            supervisorName: application.supervisorName,
//            supervisorEmail: application.supervisorEmail,

            supervisorsList: application.supervisorsList,

            //Step03
            studyTitle: application.studyTitle,
            studyDescription: application.studyDescription,
            //step04
            screen01: application.screen01,
            screen02: application.screen02,
            screen03: application.screen03,
            screen04: application.screen04,
            screen05: application.screen05,
            screen06: application.screen06,
            screen07: application.screen07,
            screen08: application.screen08,
            screen09: application.screen09,
            screen11: application.screen11,
            screen10: application.screen10,
            screen12: application.screen12,
            screen13: application.screen13,
            screen13b: application.screen13b,

            //step05
            screen14: application.screen14,
            screen15: application.screen15,
            screen16: application.screen16,
            screen17: application.screen17,
            screen18: application.screen18,
            screen19: application.screen19,
            screen20: application.screen20,


            //optional01
            studyJustification: application.studyJustification,
            studyHypothesis: application.studyHypothesis,
            //optional02
            studyInstructions: application.studyInstructions,
            //filename
            //optional03
            questionnaireUse: application.questionnaireUse,
            measures: application.measures,
            //optional04
            interviewUse: application.interviewUse,
            interviewDescription: application.interviewDescription,
            otherUse: application.otherUse,
            otherUseDescription: application.otherUseDescription,
            //optional05
            recruitmentDetails: application.recruitmentDetails,
            recruitVulnerable: application.recruitVulnerable,
            recruitVulnerableDetails: application.recruitVulnerableDetails,
            recruitChildren: application.recruitChildren,
            recruitChildrenDetails: application.recruitChildrenDetails,
            recruitRelationship: application.recruitRelationship,
            recruitRelationshipDetails: application.recruitRelationshipDetails,
            //optional06
            recruitCriteria: application.recruitCriteria,
            recruitCriteriaDetails: application.recruitCriteriaDetails,
            recruitParticipantsTotal: application.recruitParticipantsTotal,
            recruitParticipantsJustification: application.recruitParticipantsJustification,
            //optional07
            recruitIncentives: application.recruitIncentives,
            recruitPayments: application.recruitPayments,
            recruitIncentivesDetails: application.recruitIncentivesDetails,
            recruitPaymentsDetails: application.recruitPaymentsDetails,
            //optional08
            hasConsentForm: application.hasConsentForm,
            //filename
            canWithdraw: application.canWithdraw,
            informedConsent: application.informedConsent,
            consentDetails: application.consentDetails,
            //optional09
            hasDebriefForm: application.hasDebriefForm,
            //filename
            debriefFormPurpose: application.debriefFormPurpose,
            debriefFormContactDetails: application.debriefFormContactDetails,
            debriefFormLinks: application.debriefFormLinks,
            debriefFormJustification: application.debriefFormJustification,
            //optional10
            participantQuestionRefusal: application.participantQuestionRefusal,
            participantQuestionRefusalHow: application.participantQuestionRefusalHow,
            participantQuestionRefusalJustification: application.participantQuestionRefusalJustification,

            participantQuestionSensitive: application.participantQuestionSensitive,
            hasDeception: application.hasDeception,
            hasRisks: application.hasRisks,
            hasAnonymity: application.hasAnonymity,
            hasConfidentiality: application.hasConfidentiality,

            participantQuestionSensitiveJustification: application.participantQuestionSensitiveJustification,
            deceptionJustification: application.deceptionJustification,
            riskJustification: application.riskJustification,
            anonymityMaintainedHow: application.anonymityMaintainedHow,
            confidentialityMaintainedHow: application.confidentialityMaintainedHow,



            anonymityLostJustification: application.anonymityLostJustification,
            confidentialityLostJustification: application.anonymityLostJustification,




            //optional11
            dataProtectionDetails: application.dataProtectionDetails,
            dataProtectionPeriod: application.dataProtectionPeriod,
            collectTissue: application.collectTissue,
            collectTissueDetails: application.collectTissueDetails,
            //optional12
            dataAdditionalUse: application.dataAdditionalUse,
            hasAdditionalRisks: application.hasAdditionalRisks,
            hasAdditionalRisksAECC: application.hasAdditionalRisksAECC,
            hasAdditionalEthicalIssues: application.hasAdditionalEthicalIssues,
            dataAdditionalUseDetails: application.dataAdditionalUseDetails,
            additionalRisksDetails: application.additionalRisksDetails,
            additionalRisksAECCDetails: application.additionalRisksAECCDetails,
            additionalEthicalIssuesDetails: application.additionalEthicalIssuesDetails,

        }
        const validationShort = Yup.object().shape({
            //Step01
            applicantName: Yup.string().min(2, 'Please enter your full name').max(100, 'Do you have a shorter variant of your name?').required("Please supply us with your name"),
            applicantEmail: Yup.string().email('Invalid email address').required('Please ensure your email address is correct.'),
            singleStudy: Yup.boolean().required('Do you have any co-authors'),

            coApplicants: Yup.array().when(['singleStudy'], {
                is: false,
                then: Yup.array().of(
                        Yup.object().shape({
                            coApplicantName: Yup.string().required("Please identify your co-applicants"),
                    })
                )
            } ),


            // applicantTelephone: Yup.string().required("Please supply us with your contact number"),

            //Step02
            researcherSchool: Yup.string().required("Please select a school"),
            researcherType: Yup.string().required("Please indicate your role in the organisation"),


//            supervisorsList: Yup.array().of(Yup.string()).min(1).required("Please select your supervisor"),





                supervisorsList: Yup.array().when(['researcherType'], {
                    is: (value) => {
                        if ( value==='staff' ) {
                            return false
                        } else {
                            return true
                        }
                    },
                    then: Yup.array().of(Yup.string()).min(1).required("Please select your supervisor")
            }),



            /*

                        supervisorsList: Yup.array().when(['researcherType'], {
                            is: (value) => {
                                if ( value==='staff' ) {
                                    return false
                                } else {
                                    return true
                                }
                                },
                            then: Yup.string()).min(1).required("Please select your supervisor")
                            //otherwise
                        }),

            */
      /*      supervisors: Yup.array().when(['researcherType'], {
                is: true,
                then: Yup.array().of(
                    Yup.object().shape({
                        supervisorName: Yup.string().trim().required("This answer is required"),
                        supervisorEmail: Yup.string().trim().email('Invalid email address').required('Please ensure your email address is correct.'),
                    })
                ).required('Supervisor details are required').min(2, 'You must have at least 1 supervisor'),


            }) ,
*/
            studyTitle: Yup.string().required("Please let us know your study title"),
           // studyDescription: Yup.string().required("This answer is required"),

            studyDescription: Yup.string().test(
                'Max500Words',
                'Your response must be below 500 words',
                function(item) {
                    if (item && item.split(/[\s]+/).length > 500) {
                        return false;
                    } else {
                        return true;
                    }
                }
            ).required("Please enter your study description"),





            //step04
            screen01: Yup.boolean().required("Overview 1 Items are required"),
            screen02: Yup.boolean().required("Overview 1 Items are required"),
            screen03: Yup.boolean().required("Overview 1 Items are required"),
            screen04: Yup.boolean().required("Overview 1 Items are required"),
            screen05: Yup.boolean().required("Overview 1 Items are required"),
            screen06: Yup.boolean().required("Overview 1 Items are required"),
            screen07: Yup.boolean().required("Overview 1 Items are required"),
            screen08: Yup.boolean().required("Overview 1 Items are required"),
            screen09: Yup.boolean().required("Overview 1 Items are required"),
            screen11: Yup.boolean().required("Overview 1 Items are required"),
            screen10: Yup.boolean().required("Overview 1 Items are required"),
            screen12: Yup.boolean().required("Overview 1 Items are required"),
            screen13: Yup.boolean().required("Overview 1 Items are required"),
            screen13b: Yup.boolean().required("Overview 1 Items are required"),

            //step05
            screen14: Yup.string().matches(/(true|false|na)/, {excludeEmptyString: true, message: "Please ensure you select an option"}).required("Overview 2 Items are required"),
            screen15: Yup.string().matches(/(true|false|na)/, {excludeEmptyString: true, message: "Please ensure you select an option"}).required("Overview 2 Items are required"),
            screen16: Yup.string().matches(/(true|false|na)/, {excludeEmptyString: true, message: "Please ensure you select an option"}).required("Overview 2 Items are required"),
            screen17: Yup.string().matches(/(true|false|na)/, {excludeEmptyString: true, message: "Please ensure you select an option"}).required("Overview 2 Items are required"),
            screen18: Yup.string().matches(/(true|false|na)/, {excludeEmptyString: true, message: "Please ensure you select an option"}).required("Overview 2 Items are required"),
            screen19: Yup.string().matches(/(true|false|na)/, {excludeEmptyString: true, message: "Please ensure you select an option"}).required("Overview 2 Items are required"),
            screen20: Yup.string().matches(/(true|false|na)/, {excludeEmptyString: true, message: "Please ensure you select an option"}).required("Overview 2 Items are required"),


        })
        const validationLong = Yup.object().shape({

            //optional01
            studyJustification: Yup.string().required("Study justification is required"),
            studyHypothesis: Yup.string().required("Study hypothesis is required"),
            //optional02
            studyInstructions: Yup.string().required("Basic instructions are required"),
            //filename
            //optional03
            questionnaireUse: Yup.boolean().required("Please indicate whether you'll be using questionnares"),



            measures: Yup.array().when(['questionnaireUse'], {
                is: true,
                then: Yup.array().of(
                    Yup.object().shape({
                        measureName: Yup.string().required("Measure name is required"),
                        measureCopyrighted: Yup.boolean().required("Please indicate whether your measure is copyrighted"),
                        measurePermission:Yup.boolean().required("Please indicate whether permission is required to use this measure"),
                    })
                )
            } ),


                        //optional04
            interviewUse: Yup.boolean().required("Please indicate whether interviews will be used"),

            interviewDescription: Yup.string().when(['interviewUse'], {
                is: true,
                then: Yup.string().required("Please provide details of your interview approach")
                //otherwise
            }),

            otherUse: Yup.boolean().required("Please indicate if additional means of data collection are to be used"),
            otherUseDescription: Yup.string().when(['otherUse'], {
                is: true,
                then: Yup.string().required("Please provide details of the additional data collection means")
                //otherwise
            }),

            //optional05
            recruitmentDetails: Yup.string().required("Please indicate how you will recruit for your study"),
            recruitVulnerable: Yup.boolean().required("Please indicate if your participants may be deemed vulnerable"),
            recruitVulnerableDetails: Yup.string().when(['recruitVulnerable'], {
                is: true,
                then: Yup.string().required("Please give details about your recruitment of those deemed vulnerable")
                //otherwise
            }),

            recruitChildren: Yup.boolean().required("Please indicate whether your study will recruit children"),

            recruitChildrenDetails: Yup.string().when(['recruitChildren'], {
                is: true,
                then: Yup.string().required("Please give details about your recruitment of children")
                //otherwise
            }),

            recruitRelationship: Yup.boolean().required("Please indicate whether you'll be recruiting those known to you"),
            recruitRelationshipDetails: Yup.string().when(['recruitRelationship'], {
                is: true,
                then: Yup.string().required("Please give details on the steps you're taking to minimise coercion of those known to you.")
                //otherwise
            }),
            //optional06
            recruitCriteria: Yup.boolean().required("Please indicate if you have selection criteria for participation"),
            recruitCriteriaDetails: Yup.string().when(['recruitCriteria'], {
                is: true,
                then: Yup.string().required("Please give details of your participation selection criteria")
                //otherwise
            }),
            recruitParticipantsTotal: Yup.number().required("How many participants are you hoping to recruit"),
            recruitParticipantsJustification: Yup.string().required("Please enter a justification for your study numbers"),
            //optional07
            recruitIncentives: Yup.boolean().required("Please indicate if you are offering incentives"),
            recruitPayments: Yup.boolean().required("Please indicate if you're offering payments"),

            recruitIncentivesDetails: Yup.string().when(['recruitIncentives'], {
                is: true,
                then: Yup.string().required("Please supply details of your incentives to participants")
                //otherwise
            }),
            recruitPaymentsDetails: Yup.string().when(['recruitPayments'], {
                is: true,
                then: Yup.string().required("Please supply details of your payments to participants")
                //otherwise
            }),
            //optional08
            hasConsentForm: Yup.boolean().required("Please indicate if your study has a consent form"),
            //filename
            canWithdraw: Yup.boolean().when(['hasConsentForm'], {
            is: true,
            then: Yup.boolean().required("Please indicate if participants can withdraw from the study")
            //otherwise
        }),

            informedConsent:Yup.boolean().when(['hasConsentForm'], {
                is: true,
                then: Yup.boolean().required("Please indicate if information is sufficient to provide informed consent")
                //otherwise
            }),



//            consentDetails: Yup.string().required("This answer is required"),

            consentDetails: Yup.string().when(['hasConsentForm','informedConsent','canWithdraw'], {
                is: (hasConsentForm, canWithdraw, informedConsent ) => {
                    return ( !hasConsentForm || !canWithdraw || !informedConsent)
                },
                then: Yup.string().required("Please detail your consent process")

                }


            ),





            //optional09
            hasDebriefForm: Yup.boolean().required("Please indicate if you'll be using a debrief form"),
            //filename

            debriefFormPurpose: Yup.boolean().when(['hasDebriefForm'], {
                is: true,
                then: Yup.boolean().required("Please indicate whether your debrief form has study purpose details")
                //otherwise
            }),
            debriefFormContactDetails: Yup.boolean().when(['hasDebriefForm'], {
                is: true,
                then: Yup.boolean().required("Please indicate if your debrief form includes contact details.")
                //otherwise
            }),
            debriefFormLinks: Yup.boolean().when(['hasDebriefForm'], {
                is: true,
                then: Yup.boolean().required("Please indicate if your debrief from refers to information sources.")
                //otherwise
            }),
            debriefFormJustification: Yup.string().when(['hasDebriefForm'], {
                is: false,
                then: Yup.string().required("Please justify your debrief form choices")
                //otherwise
            }),

            //optional10
            participantQuestionRefusal: Yup.boolean().required("Please indicate if participants can decline individual actions"),
            participantQuestionRefusalJustification: Yup.string().when(['participantQuestionRefusal'], {
                is: false,
                then: Yup.string().required("Please indicate why declining an activity is not an option ")
            }),
            participantQuestionRefusalHow: Yup.string().when(['participantQuestionRefusal'], {
                is: true,
                then: Yup.string().required("Please indicate how the option to decline an activity is communicated.")
            }),

            participantQuestionSensitive: Yup.boolean().required("Please indicate if participants will need to provide sensitive information"),

            participantQuestionSensitiveJustification: Yup.string().when(['participantQuestionSensitive'], {
               is: true,
                then: Yup.string().required("Please justify why sensitive information is collected")
           }),

            hasDeception: Yup.boolean().required("Please indicate if you'll be deceiving participants"),
            deceptionJustification: Yup.string().when(['hasDeception'], {
                is: true,
                then: Yup.string().required("Please justify your position on deceiving participants")
            }),

            hasRisks: Yup.boolean().required("Please indicate if you foresee any risks to participants"),
            riskJustification: Yup.string().when(['hasRisks'], {
                is: true,
                then: Yup.string().required("Please detail potential risks.")
            }),


            hasAnonymity: Yup.boolean().required("Please indicate if participant anonymity will be maintained"),
            anonymityMaintainedHow: Yup.string().when(['hasAnonymity'], {
                is: true,
                then: Yup.string().required("Please indicate how anonymity is maintained.")
            }),
            anonymityLostJustification: Yup.string().when(['hasAnonymity'], {
                is: false,
                then: Yup.string().required("Please indicate why anonymity is not possible.")
            }),


            hasConfidentiality: Yup.boolean().required("Please indicate how confidentiality will be maintained"),
            confidentialityMaintainedHow: Yup.string().when(['hasConfidentiality'], {
                is: true,
                then: Yup.string().required("Please indicate how confidentiality will be maintained")
            }),
            confidentialityLostJustification: Yup.string().when(['hasConfidentiality'], {
                is: false,
                then: Yup.string().required("Please justify why confidentiality is not possible")
            }),


            //optional11
            dataProtectionDetails: Yup.string().required("Please describe how data security will be maintained"),
            dataProtectionPeriod: Yup.string().required("Please indicate how PID will be destroyed"),
            collectTissue: Yup.boolean().required("Please indicate if tissue samples are to be collected"),
            collectTissueDetails: Yup.string().when(['collectTissue'], {
                is: true,
                then: Yup.string().required("Please give details on the collection storage and disposal of those samples")
            }),
            //optional12

            dataAdditionalUse: Yup.boolean().required("Please indicate if the data will be used for other projects?"),
            dataAdditionalUseDetails: Yup.string().when(['dataAdditionalUse'], {
                is: true,
                then: Yup.string().required("Please indicate how data will be used")
            }),

            hasAdditionalRisks: Yup.boolean().required("Please indicate if you foresee any risks to yourself or researchers"),
            additionalRisksDetails: Yup.string().when(['hasAdditionalRisks'], {
                is: true,
                then: Yup.string().required("Please describe the risks you foresee for your team")
            }),


            hasAdditionalRisksAECC: Yup.boolean().required("Please indicate if you foresee any risks to the AECC"),
            additionalRisksAECCDetails:  Yup.string().when(['hasAdditionalRisksAECC'], {
                is: true,
                then: Yup.string().required("Please describe the additional risks you foresee for the AECCC")
            }),

            hasAdditionalEthicalIssues: Yup.boolean().required("Please indicate if you foresee any additional ethical issues"),
            additionalEthicalIssuesDetails: Yup.string().when(['hasAdditionalEthicalIssues'], {
                is: true,
                then: Yup.string().required("PLease describe the additional ethical issues you foresee")
            }),

        });

/*
        let testSchema = Yup.string().test(
            'Max500Words',
            'Your response must be below 500 words',
            function(item) {
                if (this.parent.studyDescription.length > 10) {
                    return false;
                } else {
                    return true;
                }
            }
        )
*/

        //Build a validation schema appropriate for this form
        let validationSchema;
        if (shortForm) {
                validationSchema = validationShort
        } else {
                validationSchema = validationShort.concat(validationLong)
        }



        return (
            <Formik

            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            validateOnBlur={true}
            validateOnChange={true}
            validateOnMount={true}

             onSubmit={async (values, formikHelpers) => {
                formikHelpers.setSubmitting(true);
                    ApplicationService.submit(values.id).then(
                        () => {
                            this.setState({
                                submitting: false,
                            })
                            setGlobalMessage("Application Received, You can logout now");
                            setReadOnly(true);
                            formikHelpers.setSubmitting(false);


                        },
                        error => {
                            const resMessage =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();

                            this.setState({
                                submitting: false,

                            });
                            setGlobalMessage(resMessage);
                            formikHelpers.setSubmitting(false);
                            document.getElementById("messageBox").scrollIntoView();
                        }
                    )
           }}


        >
            <Form id = "applicationData">
            {this.props.children}
            </Form>
        </Formik>
    )
}
}
