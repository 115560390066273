import React from 'react';
import styles from "../styles/popup.module.css";


class Popup extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={styles.infoPopup}>
                <div className={styles.infoPopupHeader}>
                    {this.props.title}
                </div>
                <div className={styles.infoPopupBreadcrumb}>&nbsp;
                </div>
                <div className={styles.infoPopupBody}>
                    {this.props.children}
                </div>
                <div className={styles.infoPopupFooter}>&nbsp;
                </div>
            </div>
        );
    }
}

export default Popup;
