import React, {Component, useContext} from "react";


import styles from "../styles/form.module.css";
import {Link, NavLink, Redirect, withRouter} from "react-router-dom";
import Gated from "../components/Gated";
import ApplicationService from "../Services/ApplicationService";
import DateFormat from "../util/DateFormat";
import MessageDisplay from "../components/MessageDisplay";
import BaseContext from "../util/BaseContext";
import VisibilityIcon from '@material-ui/icons/Visibility';





const activeApplicationsHeaders = [


    {key: "creationDate", header: "Created", isSortable: false, display: true},
    {key: "fastTrack", header: "", isSortable: false, display: false},
    {key: "researcherSchool", header: "School", isSortable: true, display: true},
    {key: "applicantName", header: "Name", isSortable: false, display: true},
    {key: "studyTitle", header: "Title", isSortable: false, display: true},
    {key: "status", header: "Status", isSortable: true, display: true},


];


class Home extends Component {

    static contextType = BaseContext;

    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            redirectTo: "",
            submitting: false,
            message: "",
            PA_applications: [],
            PA_pageNumber: 0,
            PA_applicationCount: null,
            PA_pageCount: null,
            PA_pageSize: 5
        };
    }




    componentDidMount() {
        //clear out any exiting applicationID
        const {applicationId, setApplicationId, readOnly, setReadOnly, setGlobalMessage} = this.context;

        setApplicationId(null);
        setReadOnly(false);
        setGlobalMessage("");

        //Load in the application List
        this.getPersonalApplications(0, this.state.PA_pageSize);
    }


    newApplication() {
        if (!this.state.submitting) {
            this.setState({
                submitting: true,
                message: ""
            })

            ApplicationService.newApplication()
                .then(
                    response => {



                        const {applicationId, setApplicationId, readOnly, setReadOnly} = this.context;


                        this.setState({
                            submitting: false,
                            message: "",
                        })
                     //   this.getPersonalApplications(this.state.PA_pageNumber, this.state.PA_pageSize);



                        setApplicationId(response.data.applicationId);
                        setReadOnly(false);
                        this.props.history.push("/application/step01", {

                        })






    },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        submitting: false,
                        message: resMessage
                    });
                    document.getElementById("messageBox").scrollIntoView();
                }
            );
        }
    }


    deleteApplication(id) {
        if (!this.state.submitting) {
            this.setState({
                submitting: true,
                message: ""
            })

            ApplicationService.deleteApplication(id)
                .then(
                    response => {
                        this.setState({
                            submitting: false,
                            message: "",
                        })
                        this.getPersonalApplications(this.state.PA_pageNumber, this.state.PA_pageSize);
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        this.setState({
                            submitting: false,
                            message: resMessage
                        });
                        document.getElementById("messageBox").scrollIntoView();
                    }
                );
        }
    }


    getPersonalApplications(pageNo, pageSize) {
        if (!this.state.submitting) {
            this.setState({
                submitting: true,
                message: ""
            })

            ApplicationService.getList(
                pageNo,
                pageSize
            ).then(
                response => {
                    this.setState({
                        submitting: false,
                        message: "",
                        PA_applications: response.data.applications,
                        PA_pageNumber: response.data.pageNumber,
                        PA_applicationCount: response.data.applicationCount,
                        PA_pageCount: response.data.pageCount,
                    })
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log("this message:",resMessage)
                    this.setState({
                        submitting: false,
                        message: resMessage
                    });
                    document.getElementById("messageBox").scrollIntoView();
                }
            );
        }
    }

    render() {

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        const {PA_applications, PA_pageNumber, PA_applicationCount, PA_pageCount, PA_pageSize } = this.state;



        const {applicationId, setApplicationId, readOnly, setReadOnly} = this.context;


        return (
            <div>

                <div className={styles.pageTitleContainer}>
                    <h3 className={styles.pageTitle}>Your applications in progress</h3>
                </div>


                <MessageDisplay message={this.state.message}/>


                <table className={styles.listTable}>
                    <tr>
                        <th width="13%" title="Date and time the application was started">Created</th>
                        <th width="13%" title="Time of last update">Updated</th>
                        <th width="58%" title="Title of research">Title</th>
                        <th width="16%" title="Current stage of the application">Status</th>
                        <th width="10%" title="View &#9655; or edit &#9654; the form">View</th>
                        <th width="10%" title="Delete this application">Del</th>

                    </tr>

                {PA_applications && PA_applications.map((application, index) => (
                    <tr key={index}>
                        <td className={styles.center} title={DateFormat.formatDate(application.creationDate)}>{DateFormat.formatDateOnly(application.creationDate)}</td>
                        <td className={styles.center} title={DateFormat.formatDate(application.updatedDate)}>{DateFormat.formatDateOnly(application.updatedDate)}</td>
                        <td className={styles.center}>{application.studyTitle}</td>
                        <td className={styles.center}>{application.status}</td>
                        <td className={styles.center}>

                            {(application.status==="DRAFT1" || application.status==="DRAFT2") ? (
                            <button title = "Edit the form" className={styles.filterButton}
                                    onClick={() => {
                                        setApplicationId(application.id);
                                        setReadOnly(false);
                                        this.props.history.push("/application/step01", {
                                        });
                                    }}
                            >
                                &#9654;
                            </button>
                                ) : (
                                <button title = "View the form" className={styles.filterButton}
                                        onClick={() => {
                                            setApplicationId(application.id);
                                            setReadOnly(true);
                                            this.props.history.push("/application/step01", {
                                            });
                                        }}
                                >
                                    &#9655;
                                </button>
                                )}
                        </td>
                        <td className={styles.center}>
                            {(application.status==="DRAFT1" || application.status==="APPROVED" || application.status==="REJECTED" || application.status==="DRAFT2") ? (
                                <button title = "Delete this application" className={styles.filterButton}
                                        onClick={() => {
                                            // eslint-disable-next-line no-restricted-globals
                                            if ( confirm("Your are about to delete this application. Are you sure you wish to continue?") ) { this.deleteApplication(application.id)}}}>
                                    &#10006;
                                </button>
                            ) : (
                                <span>&#8722;</span>
                            )}

                        </td>
                    </tr>
                    )
                )
                }

                    <tr className={styles.footerRow}>
                        <td colSpan="6">
                            <div className={styles.flexContainer}>

                                <div className={styles.tableStatsContainer}>Page {PA_pageNumber + 1}/{PA_pageCount} [Records: {PA_applicationCount}]</div>

                                <div className={styles.spaceContainer}></div>
                                <div className={styles.paginationContainer}>
                                    <div className={styles.previousButtonContainer}>
                                        {PA_pageNumber > 0 && (
                                            <button className={styles.paginationButton}
                                                    onClick={() => {
                                                        this.getPersonalApplications(PA_pageNumber-1,this.state.PA_pageSize)
                                                     }}
                                                    >Previous</button>
                                        )}
                                    </div>
                                    <div className={styles.nextButtonContainer}>
                                        {(PA_pageNumber + 1) < PA_pageCount && (

                                            <button className={styles.paginationButton}
                                                    onClick={(e) => {
                                                        this.getPersonalApplications(PA_pageNumber+1,this.state.PA_pageSize)
                                                    }}
                                            >Next</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>


                </table>



                 <h3>Start a new application</h3>
                <p className={styles.instruction}>Select the "New Application" button below to create a new blank application. Once created it'll appear in your application list above as a draft. The application doesn't need to be completed in one sitting you can return to complete it as time allows.</p>



                <div className={styles.bigLinkContainer}>
                    <button onClick={() => {
                        this.newApplication()




                    }
                    }><span className = {styles.bigLink}>New Application</span></button>
                </div>



            </div>
        );
    }
}

export default withRouter(Home);