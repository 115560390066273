import React, {Component} from "react";
import { ParamsProvider } from './util/BaseContext.js'
import Layout from "./components/Layout";

import './styles/app.scss';
import './styles/reap.scss';

class App extends Component {

    render() {
        return (
            <ParamsProvider value={ {} }>
                <Layout>

                </Layout>
            </ParamsProvider>
        )
    }
}

export default App;