import React, {Component, useContext} from "react";

import styles from "../styles/form.module.css";
import {CSSTransition} from "react-transition-group";
import ApplicationService from "../Services/ApplicationService";
import BaseContext from "../util/BaseContext";
import {NoteContext} from "./NoteEnabled";
import FeedbackIcon from '@material-ui/icons/Feedback'
import NoteAddIcon from '@material-ui/icons/NoteAdd'

import {Notebook, Upload, Edit, Time} from '@carbon/icons-react';
import {Panel, PanelType, Stack, Tooltip, TooltipHost} from "@fluentui/react";
import {Link} from "react-router-dom";
import TimeSpinner from "./TimeSpinner";
import FileService from "../Services/FileService";
import AuthService from "../Services/AuthService";
import {Button, ButtonSet} from "@carbon/react";
import {DateTime} from "luxon";


const stackProps = {childrenGap: "1rem"};


/**
 * Handles the onscreen display of our notes.
 */
export default class NoteDisplay extends Component {

    static contextType = NoteContext;

    constructor(props) {
        super(props);

        this.state = {
            applicationNotes: [],
            stage: this.props.stage,
            readOnly: this.props.readOnly,
            applicationId: this.props.applicationId
        };
    }


    componentDidMount() {
        //clear out any exiting applicationID

        //Load in the Note list
        this.getNotes(this.state.applicationId)
    }


    addNote(applicationId, stage, note) {

        ApplicationService.addNote(
            applicationId, stage, note
        ).then(
            response => {
                this.getNotes(applicationId);

                document.getElementById("newNote").value = "";
            },
            error => {

                document.getElementById("messageBox").scrollIntoView();
            }
        );

    }


    handleUpload(id) {

        const {displayNotes, setDisplayNotes} = this.context;
        const {displayUpload, setDisplayUpload} = this.context;

        FileService.uploadFile(document.getElementById("fileform1").file.files[0], this.state.applicationId, document.getElementById("fileform1").documentDescription.value, document.getElementById("fileform1").documentType.value)
            .then(
                response => {
                    this.setState({
                        submitting: false,
                        message: "",
                    })

                    document.getElementById("fileform1").reset();
                    setDisplayUpload(false);

                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        submitting: false,
                        message: resMessage
                    });

                    document.getElementById("messageBox").scrollIntoView();
                    document.getElementById("fileform1").documentDescription.value = "";
                    document.getElementById("fileform1").documentType.value = "Other";

                }
            )
    }

    getNotes(applicationId) {
        ApplicationService.getNotes(
            applicationId
        ).then(
            response => {
                this.setState({
                    applicationNotes: response.data.applicationNotes
                })
            },
            error => {
                document.getElementById("messageBox").scrollIntoView();
            }
        );

    }


//     footerContent = (applicationId, stage) => {
//
//         console.log("in footer content")
//
//         return (
//             <>
//
//
// <p>footer content</p>
//         {/*    <textarea className={styles.newNote} id = "newNote" name = "newNote" placeholder="Type note and select add note" />*/}
//
//         {/*<span onClick={(e) => {*/}
//         {/*    this.addNote(applicationId, stage, document.getElementById("newNote").value)*/}
//
//         {/*}} className = {styles.noteAddIcon}><span className = {styles.bigLink} >*/}
//         {/*                                Add Note*/}
//         {/*                            </span></span>*/}
//
//
//
//             </>
//         )
//     }

    /**
     * The footer content for our notes field
     * @param applicationId
     * @param stage
     * @returns {JSX.Element}
     */
    footerContent(applicationId, stage) {
        return (
            <div className="newNoteContainer">
                <h4>Add new note</h4>

                <textarea className="newNote" id="newNote" name="newNote" placeholder="Type note and select add note"/>

                <div className={styles.nextContainer}>
                    <div className="bigLink" onClick={(e) => {
                        this.addNote(applicationId, stage, document.getElementById("newNote").value)
                    }}
                    >
                        Add Note
                    </div>
                </div>
            </div>
        )
    }


    render() {

        const {displayUpload, setDisplayUpload} = this.context;
        //New notes system
        const {displayNotesPanel, setDisplayNotesPanel} = this.context;

        //Readonly if we're not currently involved in the assesment process - ie student after submission
        const {readOnly} = this.context;

        const {applicationNotes, stage, applicationId} = this.state;

        if (displayNotesPanel) {


            return (
                <>


                    <Panel
                        isOpen={displayNotesPanel}
                        onDismiss={() => {
                            setDisplayNotesPanel(false)
                        }}
                        type={PanelType.smallFixedFar}
                        headerText={"Notes and comments"}
                        isHiddenOnDismiss={true}
                        isBlocking={false}
                        closeButtonAriaLabel="Close Panel"
                        isFooterAtBottom={true}
                        isLightDismiss={true}
                        onRenderFooterContent={() => {
                            return this.footerContent(applicationId, stage);
                        }}

                    >
                        <div className="panelContainer">
                            <h4>Existing notes</h4>

                            <div className="existingNotesContainer">
                                {/*<h5 className={styles.noteHeader}>Page Notes <span className={styles.noteClose} onClick={()=>{setDisplayNotes(false)}}>&#10006;</span></h5>*/}

                                <div className="noteList">
                                    {applicationNotes && applicationNotes.filter((value) => {
                                        return value.stage === stage;
                                    }).map((note, index) => (
                                        <>
                                            {(note.username === AuthService.getId()) ? (
                                                <div className = "noteContainer">
                                                    <div className = "noteHeaderContainer">
                                                        <span className = "noteHeaderTimeIcon">
                                                        <TooltipHost
                                                            content={DateTime.fromISO(note.creationDate).toLocaleString(DateTime.DATETIME_MED)}
                                                            // This id is used on the tooltip itself, not the host
                                                            // (so an element with this id only exists when the tooltip is shown)
                                                            id={note.ceeationDate}
                                                            //                                                            calloutProps={calloutProps}
                                                            //                                                            styles={hostStyles}
                                                        >
                                                            <Time />
                                                        </TooltipHost>
                                                        </span>
                                                        <span className="noteHeaderText">You</span>
                                                        <span className = "noteHeaderEditIcon">
                                                         <Edit />
                                                    </span>
                                                    </div>
                                                    <p key={index} className="noteContent">
                                                         {note.text}
                                                    </p>

                                                </div>


                                            ) : (
                                                <p key={index} className={styles.noteContent}>
                                                    <strong>Other:</strong> {note.text}</p>
                                            )}
                                        </>
                                    ))}

                                    {applicationNotes && applicationNotes.filter((value) => {
                                        return value.stage === stage;
                                    }).length < 1 && (
                                        <p className={styles.noteContent}>
                                            No notes available
                                        </p>
                                    )}

                                    {!applicationNotes && (
                                        <p className={styles.noteContent}>
                                            No notes available
                                        </p>
                                    )}


                                </div>
                            </div>
                        </div>
                    </Panel>


                    {/*<div className = {styles.noteTest}>*/}
                    {/*   <p>PANEL</p>*/}
                    {/*</div>*/}
                    {/*<Panel*/}
                    {/*    isOpen={displayNotesPanel}*/}
                    {/*    onDismiss={setDisplayNotesPanel(false)}*/}
                    {/*    isBlocking={true}*/}
                    {/*    closeButtonAriaLabel="Close Panel"*/}
                    {/*    isFooterAtBottom={true}*/}
                    {/*    isLightDismiss={true}*/}
                    {/*>*/}
                    {/*    <div className="panelContainer">*/}
                    {/*        <h3>Panel</h3>*/}

                    {/*        <Stack tokens={stackProps}>*/}

                    {/*        </Stack>*/}
                    {/*    </div>*/}
                    {/*</Panel>*/}


                    {/*<CSSTransition*/}
                    {/*    in={displayNotesPanel}*/}
                    {/*    timeout={300}*/}
                    {/*    classNames=".noteBox"*/}
                    {/*    unmountOnExit*/}
                    {/*    onEnter={()=>setDisplayNotesPanel(true)}*/}
                    {/*    onExited={()=>setDisplayNotesPanel(false)}*/}
                    {/*>*/}
                    {/*    <div className = {styles.noteTest}>*/}
                    {/*        <h5 className={styles.noteHeader}>Page Notes <span className={styles.noteClose} onClick={()=>{setDisplayNotes(false)}}>&#10006;</span></h5>*/}
                    {/*        <div className ={styles.noteBody}>*/}

                    {/*            <div className={styles.noteList}>*/}
                    {/*                {applicationNotes && applicationNotes.map((note, index) => (*/}
                    {/*                    <>*/}

                    {/*                        {note.stage == stage && (*/}
                    {/*                            <>*/}
                    {/*                                { ( note.username === AuthService.getId() ) ? (*/}
                    {/*                                    <p key={index} className={styles.noteContent}><strong>You:</strong> {note.text}</p>*/}
                    {/*                                ) : (*/}
                    {/*                                    <p key={index} className={styles.noteContent}><strong>Other:</strong> {note.text}</p>*/}
                    {/*                                ) }*/}
                    {/*                            </>*/}
                    {/*                        )}*/}
                    {/*                    </>*/}
                    {/*                ))}*/}
                    {/*            </div>*/}


                    {/*            <textarea className={styles.newNote} id = "newNote" name = "newNote" placeholder="Type note and select add note" />*/}

                    {/*            <span onClick={(e) => {*/}
                    {/*                this.addNote(applicationId, stage, document.getElementById("newNote").value)*/}

                    {/*            }} className = {styles.noteAddIcon}><span className = {styles.bigLink} >*/}
                    {/*            Add Note*/}
                    {/*        </span></span>*/}


                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</CSSTransition>*/}


                </>
            )
        } else if (displayUpload) {

            return (
                <>

                    <Panel
                        isOpen={displayUpload}
                        onDismiss={() => {
                            setDisplayUpload(false)
                        }}
                        type={PanelType.smallFixedFar}
                        headerText="Attach document"
                        isHiddenOnDismiss={true}
                        isBlocking={true}
                        closeButtonAriaLabel="Close Panel"
                        isFooterAtBottom={true}

                    >
                        <div className="panelContainer">
                            <form id="fileform1">
                                <div>
                                    <span className={styles.question}>Document description</span>
                                    <input type="text" name="documentDescription" className={styles.inputText}
                                           disabled={readOnly}/>

                                    <span className={styles.question}><br/>Select file</span>

                                    <input type="file" name="file" className={styles.inputText} title="upload"
                                           disabled={readOnly}/>

                                    <span className={styles.question}><br/>Select file category </span>

                                    <select name="documentType" disabled={readOnly}>
                                        <option value="">Other</option>
                                        <option value="IRAS">Iras</option>
                                        <option value="PATIENTRECORD">Patient Record Form</option>
                                        <option value="STANDARDINSTRUCTIONS">Standard Instruction</option>
                                        <option value="INFORMEDCONSENT">Informed Consent Form</option>
                                        <option value="DEBRIEF">Debrief Form</option>
                                        <option value="MEASURES">Measure Permission</option>
                                        <option value="ADDITIONAL">Additional Documentation</option>

                                    </select>


                                    <br/>

                                    <div className={styles.nextContainer}>
                                        <div className="bigLink" onClick={(e) => {
                                            this.handleUpload(applicationId)
                                        }}
                                        >
                                            Upload
                                        </div>
                                    </div>


                                </div>

                            </form>
                        </div>
                    </Panel>


                    {/*<CSSTransition*/}
                    {/*    in={displayUpload}*/}
                    {/*    timeout={300}*/}
                    {/*    classNames=".noteBox"*/}
                    {/*    unmountOnExit*/}
                    {/*    onEnter={()=>setDisplayUpload(true)}*/}
                    {/*    onExited={()=>setDisplayUpload(false)}*/}
                    {/*>*/}
                    {/*    <div className = {styles.uploadTest}>*/}
                    {/*        <h5 className={styles.noteHeader}>Uploads <span className={styles.noteClose} onClick={()=>{setDisplayUpload(false)}}>&#10006;</span></h5>*/}
                    {/*        <div className ={styles.noteBody}>*/}


                    {/*


                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</CSSTransition>*/}


                </>
            )


        } else {
            return (<></>)
        }


        // if (displayNotes) {
        //     return (
        //         <>
        //             <CSSTransition
        //                 in={displayNotes}
        //                 timeout={300}
        //                 classNames=".noteBox"
        //                 unmountOnExit
        //                 onEnter={()=>setDisplayNotes(true)}
        //                 onExited={()=>setDisplayNotes(false)}
        //             >
        //                 <div className = {styles.noteTest}>
        //                     <h5 className={styles.noteHeader}>Page Notes <span className={styles.noteClose} onClick={()=>{setDisplayNotes(false)}}>&#10006;</span></h5>
        //                     <div className ={styles.noteBody}>
        //
        //                         <div className={styles.noteList}>
        //                         {applicationNotes && applicationNotes.map((note, index) => (
        //                             <>
        //
        //                                 {note.stage == stage && (
        //                                     <>
        //                                     { ( note.username === AuthService.getId() ) ? (
        //                                         <p key={index} className={styles.noteContent}><strong>You:</strong> {note.text}</p>
        //                                     ) : (
        //                                         <p key={index} className={styles.noteContent}><strong>Other:</strong> {note.text}</p>
        //                                     ) }
        //                                     </>
        //                                 )}
        //                             </>
        //                         ))}
        //                         </div>
        //
        //
        //                         <textarea className={styles.newNote} id = "newNote" name = "newNote" placeholder="Type note and select add note" />
        //
        //
        //
        //
        //                         <span onClick={(e) => {
        //                             this.addNote(applicationId, stage, document.getElementById("newNote").value)
        //
        //                         }} className = {styles.noteAddIcon}><span className = {styles.bigLink} >
        //                         Add Note
        //                     </span></span>
        //
        //
        //
        //
        //                     </div>
        //                 </div>
        //             </CSSTransition>
        //
        //
        //         </>
        //     )
        // } else if (displayUpload) {
        //
        //     return (
        //         <>
        //             <CSSTransition
        //                 in={displayUpload}
        //                 timeout={300}
        //                 classNames=".noteBox"
        //                 unmountOnExit
        //                 onEnter={()=>setDisplayUpload(true)}
        //                 onExited={()=>setDisplayUpload(false)}
        //             >
        //                 <div className = {styles.uploadTest}>
        //                     <h5 className={styles.noteHeader}>Uploads <span className={styles.noteClose} onClick={()=>{setDisplayUpload(false)}}>&#10006;</span></h5>
        //                     <div className ={styles.noteBody}>
        //
        //
        //
        //                         <form id="fileform1">
        //
        //                             <div className={styles.question}>
        //                                 <span className={styles.question}>Document Description</span>
        //                                 <input type="text" name="documentDescription" className={styles.inputText}
        //                                        disabled={readOnly}/>
        //
        //
        //                                 <span className={styles.question}><br/>Select File</span>
        //
        //                                 <input type="file" name="file" className={styles.inputText} title="upload"
        //                                        disabled={readOnly}/>
        //
        //
        //                                 <span className={styles.question}><br/>Select File Category </span>
        //
        //                                 <select name="documentType" disabled={readOnly}>
        //                                     <option value="">Other</option>
        //
        //                                     <option value="IRAS">Iras</option>
        //                                     <option value="PATIENTRECORD">Patient Record Form</option>
        //                                     <option value="STANDARDINSTRUCTIONS">Standard Instruction</option>
        //                                     <option value="INFORMEDCONSENT">Informed Consent Form</option>
        //                                     <option value="DEBRIEF">Debrief Form</option>
        //                                     <option value="MEASURES">Measure Permission</option>
        //                                     <option value="ADDITIONAL">Additional Documentation</option>
        //
        //                                 </select>
        //
        //
        //                                 <br/>
        //
        //
        //                                 <div className={styles.uploadSurround}>
        //
        //
        //                                     <div onClick={() => this.handleUpload(applicationId)}>
        //                     <span className={styles.timeSpinnerSurround}>
        //                         {this.state.submitting && (
        //                             <TimeSpinner backgroundColour="#ffffff"/>
        //                         )}
        //                     </span>
        //                                         <span className={styles.bigLink}>
        //                         Upload
        //                     </span>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </form>
        //
        //
        //                     </div>
        //                 </div>
        //             </CSSTransition>
        //
        //
        //         </>
        //     )
        //
        //
        //
        // } else {
        //     return (<></>)
        // }

    }
}


export class FeedbackToggle extends Component {

    static contextType = NoteContext;

    constructor(props) {
        super(props);

        this.state = {
            applicationNotes: [],

        }


    }



    getNotes(applicationId) {
        ApplicationService.getNotes(
            applicationId
        ).then(
            response => {
                this.setState({
                    applicationNotes: response.data.applicationNotes
                })
            },
            error => {
                document.getElementById("messageBox").scrollIntoView();
            }
        );

    }


    componentDidMount() {
        //clear out any exiting applicationID

        //Load in the Note list
        this.getNotes(this.props.applicationId)
    }


    render() {
        // const {displayNotes, setDisplayNotes} = this.context;
        const {displayUpload, setDisplayUpload} = this.context;

        const {displayNotesPanel, setDisplayNotesPanel} = this.context;

        //Don't display the upload options for applications in a read only state
        const readOnly = this.props.readOnly;
        const stage = this.props.stage;

        if (displayNotesPanel) {
            return (<></>)
        } else if (displayUpload) {
            return (<></>)
        } else {
            //Determine if we have any notes for this page and set a variable to indicate the style to use

            let notebookStyle = "notebookInverse";

            const {applicationNotes} = this.state;

            if (applicationNotes && applicationNotes.filter((value) => {
                return value.stage === stage;
            }).length > 0) {
                notebookStyle = "notebookInverse";
            } else {
                notebookStyle = "notebookStandard";
            }

            return (





                <>
                    {!readOnly && (
                        <>
                                <span className={styles.feedbackIcon} onClick={
                                    () => {
                                        setDisplayUpload(true);
                                    }
                                }>
                        <TooltipHost content="Upload Material"><Upload size={32}/></TooltipHost>
                    </span>
                            <span>&nbsp;</span>
                        </>
                    )}

                    <span className={styles.feedbackIcon} onClick={
                        () => {
                            setDisplayNotesPanel(true);
                        }
                    }>
                        <TooltipHost content="View or add notes"><span className = {notebookStyle}><Notebook size={32}/></span></TooltipHost>
                    </span>

                </>
            )
        }




    }
}

