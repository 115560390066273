import React, {Component} from "react";


import {Redirect, Route, Switch} from "react-router-dom";
import Step01 from "./application/Step01";
import Step02 from "./application/Step02";
import Step03 from "./application/Step03";
import Step04 from "./application/Step04";
import Step05 from "./application/Step05";
import FormikConfig from "../components/FormikConfig";
import Optional02 from "./application/Optional02";
import Optional03 from "./application/Optional03";
import Optional04 from "./application/Optional04";
import Optional05 from "./application/Optional05";
import Optional06 from "./application/Optional06";
import Optional07 from "./application/Optional07";
import Optional08 from "./application/Optional08";
import Optional09 from "./application/Optional09";
import Optional10 from "./application/Optional10";
import Optional11 from "./application/Optional11";
import Optional12 from "./application/Optional12";
import Declaration01 from "./application/Declaration01";
import Declaration02 from "./application/Declaration02";
import AuthenticatedRoute from "../components/AuthenticatedRoute";
import NotFound from "./NotFound";
import Optional01 from "./application/Optional01";
import ApplicationIdCheck from "../components/ApplicationIdCheck";
import BaseContext from "../util/BaseContext";
import NoteEnabled from "../components/NoteEnabled";
import Success from "./application/Success";



export default class Application extends Component {

    static contextType = BaseContext;

    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            message: ""
        };
    }



    componentDidMount() {




    }


    render() {



        return (


            <FormikConfig>
                <ApplicationIdCheck>
                    <Switch>
                        <AuthenticatedRoute exact path="/application">
                                <Redirect to="/application/step1"/>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute path="/application/success">
                            <NoteEnabled>
                                <Success />
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute path="/application/step01">
                            <NoteEnabled>
                                    <Step01 />
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute path={["/application/step02"]}>
                            <NoteEnabled>
                                <Step02/>
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact path={["/application/step03"]}>
                            <NoteEnabled>
                            <Step03/>
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact path={["/application/step04"]}>
                            <NoteEnabled>
                            <Step04/>
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact path={["/application/step05"]}>
                            <NoteEnabled>
                            <Step05/>
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact path={["/application/optional01"]}>

                            <NoteEnabled>
                                <Optional01 />
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact path={["/application/optional02"]}>
                            <NoteEnabled>
                                <Optional02/>
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact path={["/application/optional03"]}>

                            <NoteEnabled>
                                <Optional03/>
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact path={["/application/optional04"]}>
                            <NoteEnabled>
                                <Optional04/>
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact path={["/application/optional05"]}>
                            <NoteEnabled>
                                <Optional05/>
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact path={["/application/optional06"]}>
                            <NoteEnabled>
                                <Optional06/>
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact path={["/application/optional07"]}>
                            <NoteEnabled>
                                <Optional07/>
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact path={["/application/optional08"]}>
                            <NoteEnabled>
                                <Optional08/>
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact path={["/application/optional09"]}>
                            <NoteEnabled>
                                <Optional09/>
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact path={["/application/optional10"]}>
                            <NoteEnabled>
                                <Optional10/>
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact path={["/application/optional11"]}>
                            <NoteEnabled>
                                <Optional11/>
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact path={["/application/optional12"]}>
                            <NoteEnabled>
                                <Optional12/>
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact path={["/application/declaration01"]}>
                            <NoteEnabled>
                                <Declaration01/>
                            </NoteEnabled>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact path={["/application/declaration02"]}>
                            <NoteEnabled>
                                <Declaration02/>
                            </NoteEnabled>
                        </AuthenticatedRoute>



                        <Route path="*" component={NotFound}/>
                    </Switch>
                </ApplicationIdCheck>
            </FormikConfig>

        );
    }
}
