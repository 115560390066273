import React from 'react';
import {Redirect, Route} from "react-router-dom";
import AuthService from "../Services/AuthService";

/**
 Wrapper component to ensure we're logged in before viewing the page. This is really
 a defence against deep linking rather than a security system, this is all handled
 client side, easily bypassed and simply checks the user browser session object is present.

 All interactions with the server are authenticated server side based on the server issued
 auth tokens

 <AuthenticatedRoute path="/somePath">
     {//accessible only if the user object exists in the browser session}
 </ApplicationIdCheck>

 Failure to be logged in will instead direct people to the Login Page component.
 */
export default function AuthenticatedRoute({children}, ...rest) {

    //We're considering ourselves logged in if the user object exists
    const user = AuthService.getCurrentUser();

    return (
        <Route {...rest}
            render={({ location }) =>
                user ? (
                    children
                ) : (
                    <Redirect to = {{
                        pathname: "/login",
                        //we might want to return people to the original page they arrived at
                        //provide that to the Login component
                        state: {from: location },
                        }}
                    />
                )
            }
        />
    );
}

