import React, {Component} from "react";


import styles from "../../styles/form.module.css";
import {Redirect, withRouter} from "react-router-dom";
import ApplicationService from "../../Services/ApplicationService";
import MessageDisplay from "../../components/MessageDisplay";
import BaseContext from "../../util/BaseContext";
import DateFormat from "../../util/DateFormat";
import ReviewerService from "../../Services/ReviewerService";
import {CSSTransition} from "react-transition-group";
import SupervisionService from "../../Services/SupervisionService";
//import {Accordion, AccordionItem, Modal, Tooltip} from "@carbon/react";
import Popup from "../../components/Popup";
import { Pivot, PivotItem} from '@fluentui/react';
import FluentTheme from "../../Services/FluentTheme";
import {Modal}  from  '@carbon/react';



class UserAdmin extends Component {

    static contextType = BaseContext;

    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            redirectTo: "",
            submitting: false,

            displayList: false,

            openSupervisorModal: false,
            openReviewerModal: false,

            ADMIN_applications: [],
            ADMIN_pageNumber: 0,
            ADMIN_recordCount: null,
            ADMIN_pageCount: null,
            ADMIN_pageSize: 10,


            ADMIN2_applications: [],
            ADMIN2_pageNumber: 0,
            ADMIN2_recordCount: null,
            ADMIN2_pageCount: null,
            ADMIN2_pageSize: 10,



            REVIEWER_records: [],
            REVIEWER_pageNumber: 0,
            REVIEWER_recordCount: null,
            REVIEWER_pageCount: null,
            REVIEWER_pageSize: 10,

            SUPERVISOR_records: [],
            SUPERVISOR_pageNumber: 0,
            SUPERVISOR_recordCount: null,
            SUPERVISOR_pageCount: null,
            SUPERVISOR_pageSize: 10,


            SELECT_reviewers: [],

            progressId: null,
            progressSchool: null,


            displaySupervisors: false,
            displayReviewers: false


        };
    }


    componentDidMount() {
        //clear out any exiting applicationID

        const {applicationId, setApplicationId, readOnly, setReadOnly, globalMessage, setGlobalMessage} = this.context;

        setApplicationId(null);
        setReadOnly(false);
        setGlobalMessage("");

        //Load in the application List
   //     this.getActiveApplications(0, this.state.ADMIN_pageSize);

        //Load in the Old Applications List
    //    this.getCompletedApplications(0, this.state.ADMIN2_pageSize);


        //Load the full list of active reviewers to populate our drop downs
    //    this.getActiveReviewers();

        //Load the pageable list of reviewers for the admin interface
        this.getAllReviewers(0, this.state.REVIEWER_pageSize);

        //Load the pageable list of supervisors for the admin interface
        this.getAllSupervisors(0, this.state.SUPERVISOR_pageSize);

    }





    getAllReviewers(pageNo, pageSize) {


        // if (!this.state.submitting) {
        if (true) {
            const {globalMessage, setGlobalMessage} = this.context;
            setGlobalMessage("");


            this.setState({
                submitting: true,
            })

            ReviewerService.getAllReviewers(
                pageNo,
                pageSize
            ).then(
                response => {
                    this.setState({
                        submitting: false,
                        REVIEWER_records: response.data.reviewers,
                        REVIEWER_pageNumber: response.data.pageNumber,
                        REVIEWER_recordCount: response.data.recordCount,
                        REVIEWER_pageCount: response.data.pageCount,
                    })
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        submitting: false,
                    });
                    setGlobalMessage(resMessage);


                    document.getElementById("messageBox").scrollIntoView();
                }
            );
        }
    }

    getAllSupervisors(pageNo, pageSize) {


        // if (!this.state.submitting) {
        if (true) {
            const {globalMessage, setGlobalMessage} = this.context;
            setGlobalMessage("");


            this.setState({
                submitting: true,
            })

            SupervisionService.getSupervisors(
                pageNo,
                pageSize
            ).then(
                response => {
                    this.setState({
                        submitting: false,
                        SUPERVISOR_records: response.data.records,
                        SUPERVISOR_pageNumber: response.data.pageNumber,
                        SUPERVISOR_recordCount: response.data.recordCount,
                        SUPERVISOR_pageCount: response.data.pageCount,
                    })
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        submitting: false,
                    });
                    setGlobalMessage(resMessage);


                    document.getElementById("messageBox").scrollIntoView();
                }
            );
        }
    }



    newSupervisor(supervisor_name, supervisor_email, supervisor_school) {
        if (!this.state.submitting) {

            const {globalMessage, setGlobalMessage} = this.context;
            setGlobalMessage("");


            this.setState({
                submitting: true,
            })


            if (supervisor_email.length < 3) {
                this.setState({
                    submitting: false,
                })

                setGlobalMessage("Please check the supervisor's email address")
                this.setState({openSupervisorModal: false})
                document.getElementById("messageBox").scrollIntoView();
            } else if (supervisor_name.length < 3) {
                this.setState({
                    submitting: false,
                })
                setGlobalMessage("Please Check the supervisor's Name")
                this.setState({openSupervisorModal: false})
                document.getElementById("messageBox").scrollIntoView();
            } else {


                SupervisionService.newSupervisor(supervisor_name, supervisor_email, supervisor_school)
                    .then(
                        response => {
                            this.setState({
                                submitting: false,
                            })


                            this.getAllSupervisors(this.state.SUPERVISOR_pageNumber, this.state.SUPERVISOR_pageSize);
                            this.setState({openSupervisorModal: false})
                        },
                        error => {
                            const resMessage =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();

                            this.setState({
                                submitting: false,
                            });

                            setGlobalMessage(resMessage);
                            this.setState({openSupervisorModal: false})
                            document.getElementById("messageBox").scrollIntoView();
                        }
                    );
            }
        }
    }


    newReviewer(reviewer_name, reviewer_email, reviewer_school) {
        if (!this.state.submitting) {

            const {globalMessage, setGlobalMessage} = this.context;
            setGlobalMessage("");


            this.setState({
                submitting: true,
            })


            if (reviewer_email.length < 3) {
                this.setState({
                    submitting: false,
                })

                setGlobalMessage("Please Check the reviewer's email address")
                this.setState({openReviewerModal: false})
                document.getElementById("messageBox").scrollIntoView();
            } else if (reviewer_name.length < 3) {
                this.setState({
                    submitting: false,
                })
                setGlobalMessage("Please Check the reviewer's Name")
                this.setState({openReviewerModal: false})
                document.getElementById("messageBox").scrollIntoView();
            } else {


                ReviewerService.newReviewer(reviewer_name, reviewer_email, reviewer_school)
                    .then(
                        response => {
                            this.setState({
                                submitting: false,
                            })


                            this.getActiveReviewers();
                            this.setState({openReviewerModal: false})
                            this.getAllReviewers(this.state.REVIEWER_pageNumber, this.state.REVIEWER_pageSize);
                        },
                        error => {
                            const resMessage =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();

                            this.setState({
                                submitting: false,
                            });

                            setGlobalMessage(resMessage);
                            this.setState({openReviewerModal: false})
                            document.getElementById("messageBox").scrollIntoView();
                        }
                    );
            }
        }
    }



    updateReviewer(reviewerId, reviewerState) {
        if (!this.state.submitting) {
            const {globalMessage, setGlobalMessage} = this.context;
            setGlobalMessage("");

            this.setState({
                submitting: true,
            })


            ReviewerService.updateReviewer(reviewerId, reviewerState)
                .then(
                    response => {
                        this.setState({
                            submitting: false,
                        })


             //           this.getActiveReviewers();
                        this.getAllReviewers(this.state.REVIEWER_pageNumber, this.state.REVIEWER_pageSize);


                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        this.setState({
                            submitting: false,

                        });
                        setGlobalMessage(resMessage);

                        document.getElementById("messageBox").scrollIntoView();
                    }
                );

        }
    }


    updateSupervisor(supervisorId, supervisorState) {
        if (!this.state.submitting) {
            const {globalMessage, setGlobalMessage} = this.context;
            setGlobalMessage("");

            this.setState({
                submitting: true,
            })


            SupervisionService.updateSupervisor(supervisorId, supervisorState)
                .then(
                    response => {
                        this.setState({
                            submitting: false,
                        })

                        this.getAllSupervisors(this.state.SUPERVISOR_pageNumber, this.state.SUPERVISOR_pageSize);


                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        this.setState({
                            submitting: false,

                        });
                        setGlobalMessage(resMessage);

                        document.getElementById("messageBox").scrollIntoView();
                    }
                );

        }
    }



    render() {

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }


        const {
            REVIEWER_records,
            REVIEWER_recordCount,
            REVIEWER_pageNumber,
            REVIEWER_pageCount,
            REVIEWER_pageSize,

            SUPERVISOR_records,
            SUPERVISOR_pageNumber,
            SUPERVISOR_recordCount,
            SUPERVISOR_pageCount,
            SUPERVISOR_pageSize,


            SELECT_reviewers
        } = this.state;


        const {setApplicationId, readOnly, setReadOnly, globalMessage} = this.context;
        const {
            displayList,
            progressId,
            progressSchool,
            displayReviewers,
            displaySupervisors,
            openSupervisorModal,
            openReviewerModal
        } = this.state;



        const pivotStyles = {

            fontSize: '14px'


        }



        return (
            <div>

                <div className={styles.pageTitleContainer}>
                    <h3 className={styles.pageTitle}>User administration</h3>
                </div>

                <p>Select the relevent tab below to administer your reviewers and supervisors</p>
                <p>Active reviewers will appear in your the list of available reviewers when assigning one to an ethics applications</p>
                <p>Active supervisors will appear in the list of available supervisors to applicants as they make their application (NB already assigned supervisors will not be reset)</p>



                <FluentTheme>
                    <MessageDisplay message={globalMessage}/>





                <Pivot linkFormat="tabs" linkSize="large" styles={pivotStyles}>

                    <PivotItem headerText="Reviewers" styles={pivotStyles} >


                        <table className={styles.listTable}>
                            <tr>
                                <th className={styles.center} width="45%" title="Name">Name</th>
                                <th className={styles.center} width="30%" title="Email address">Email</th>
                                <th className={styles.center} width="15%" title="School">School</th>

                                <th className={styles.center} width="7%" title="Number of reviews undertaken">#</th>
                                <th className={styles.center} width="3%"
                                    title="Reviewer is available for selection">&#10004;</th>
                            </tr>
                            {REVIEWER_records && REVIEWER_records.map((reviewer, index) => (


                                <tr key={index}>
                                    <td>
                                        {reviewer.name}
                                    </td>

                                    <td>
                                        {reviewer.username}
                                    </td>

                                    <td className={styles.center}>
                                        {reviewer.school}
                                    </td>


                                    <td className={styles.center}>
                                        {reviewer.jobCount}
                                    </td>

                                    <td className={styles.center}>
                                        {reviewer.inRotation ? (
                                            <button className={styles.filterButtonActive} onClick={() => {
                                                this.updateReviewer(reviewer.id, false)
                                            }

                                            }>&#10004;</button>
                                        ) : (
                                            <button className={styles.filterButton} onClick={() => {
                                                this.updateReviewer(reviewer.id, true)
                                            }}>&#10004;</button>
                                        )}
                                        {reviewer.inRotation}
                                    </td>


                                </tr>
                            ))}


                            <tr className={styles.footerRow}>
                                <td colSpan="5">
                                    <div className={styles.flexContainer}>

                                        <div
                                            className={styles.tableStatsContainer}>Page {REVIEWER_pageNumber + 1}/{REVIEWER_pageCount} [Records: {REVIEWER_recordCount}]
                                        </div>

                                        <div className={styles.spaceContainer}></div>
                                        <div className={styles.paginationContainer}>
                                            <div className={styles.previousButtonContainer}>
                                                {REVIEWER_pageNumber > 0 && (
                                                    <button className={styles.paginationButton}
                                                            onClick={() => {
                                                                this.getAllReviewers(REVIEWER_pageNumber - 1, REVIEWER_pageSize)
                                                            }}
                                                    >Previous</button>
                                                )}
                                            </div>
                                            <div className={styles.nextButtonContainer}>
                                                {(REVIEWER_pageNumber + 1) < REVIEWER_pageCount && (

                                                    <button className={styles.paginationButton}
                                                            onClick={(e) => {
                                                                this.getAllReviewers(REVIEWER_pageNumber + 1, REVIEWER_pageSize)
                                                            }}
                                                    >Next</button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                        </table>


                        <button  onClick={() => {
                            this.setState({
                                openReviewerModal: true
                            })
                        }
                        }><span className ={styles.bigLink}>Add Reviewer</span>
                        </button>


                        <Modal
                            passiveModal="true"
                            open={openReviewerModal}
                            onRequestClose={() => {
                                this.setState({openReviewerModal: false})
                            }}

                            size="sm"
                        >
                            <div className={styles.displayList}>
                                <h5 className={styles.noteHeader}>Add new reviewer</h5>
                                <div className={styles.noteBody}>
                            <div className={styles.question}>

                                <span className={styles.question}>Name</span>
                                <input id="reviewer_name" name="reviewer_name" type="text" className={styles.inputText}
                                />
                                <span className={styles.question}>Email</span>
                                <input id="reviewer_email" name="reviewer_email" type="text"
                                       className={styles.inputText}
                                />
                                <span className={styles.question}>School</span>
                                <select id="reviewer_school" name="reviewer_school" className={styles.inputText}>
                                    <option value="Chiropractic">Chiropractic</option>
                                    <option value="HRS">Health and rehabilitation sciences</option>
                                    {/*<option value="RSP">Rehabilitation, Sport and Psychology</option>*/}
                                    {/*<option value="Radiology">Radiology</option>*/}
                                </select>

                                <div className={styles.bigLinkContainer}>


                                    <button className={styles.bigLink} onClick={() => {
                                        this.newReviewer(document.getElementById("reviewer_name").value, document.getElementById("reviewer_email").value, document.getElementById("reviewer_school").value);
                                    }
                                    }>Add reviewer
                                    </button>
                                </div>

                            </div>
                                </div>
                            </div>
                        </Modal>


                    </PivotItem>

                    <PivotItem headerText="Supervisors">


                        <table className={styles.listTable}>
                            <tr>
                                <th className={styles.center} width="45%" title="Name">Name</th>
                                <th className={styles.center} width="37%" title="Email address">Email</th>
                                <th className={styles.center} width="15%" title="School">School</th>


                                <th className={styles.center} width="3%"
                                    title="Reviewer is available for selection">&#10004;</th>
                            </tr>
                            {SUPERVISOR_records && SUPERVISOR_records.map((supervisor, index) => (


                                <tr key={index}>
                                    <td>
                                        {supervisor.supervisorName}
                                    </td>

                                    <td>
                                        {supervisor.supervisorEmail}
                                    </td>

                                    <td className={styles.center}>
                                        {supervisor.school}
                                    </td>


                                    <td className={styles.center}>
                                        {supervisor.inRotation ? (
                                            <button className={styles.filterButtonActive} onClick={() => {
                                                this.updateSupervisor(supervisor.id, false)
                                            }

                                            }>&#10004;</button>
                                        ) : (
                                            <button className={styles.filterButton} onClick={() => {
                                                this.updateSupervisor(supervisor.id, true)
                                            }}>&#10004;</button>
                                        )}
                                        {supervisor.inRotation}
                                    </td>


                                </tr>
                            ))}


                            <tr className={styles.footerRow}>
                                <td colSpan="4">
                                    <div className={styles.flexContainer}>

                                        <div
                                            className={styles.tableStatsContainer}>Page {SUPERVISOR_pageNumber + 1}/{SUPERVISOR_pageCount} [Records: {SUPERVISOR_recordCount}]
                                        </div>

                                        <div className={styles.spaceContainer}></div>
                                        <div className={styles.paginationContainer}>
                                            <div className={styles.previousButtonContainer}>
                                                {SUPERVISOR_pageNumber > 0 && (
                                                    <button className={styles.paginationButton}
                                                            onClick={() => {
                                                                this.getAllSupervisors(SUPERVISOR_pageNumber - 1, SUPERVISOR_pageSize)
                                                            }}
                                                    >Previous</button>
                                                )}
                                            </div>
                                            <div className={styles.nextButtonContainer}>
                                                {(SUPERVISOR_pageNumber + 1) < SUPERVISOR_pageCount && (

                                                    <button className={styles.paginationButton}
                                                            onClick={(e) => {
                                                                this.getAllSupervisors(SUPERVISOR_pageNumber + 1, SUPERVISOR_pageSize)
                                                            }}
                                                    >Next</button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                        </table>


                        <button  onClick={() => {
                            this.setState({
                                openSupervisorModal: true
                            })
                        }
                        }><span className = {styles.bigLink}>Add supervisor</span>
                        </button>


                        <Modal
                            passiveModal="true"
                            open={openSupervisorModal}
                            onRequestClose={() => {
                                this.setState({openSupervisorModal: false})
                            }}

                            size="sm"
                        >



                                <div className={styles.displayList}>
                                    <h5 className={styles.noteHeader}>Add new supervisor</h5>
                                    <div className={styles.noteBody}>

                                    <div className={styles.question}>

                                <span className={styles.question}>Name</span>
                                <input id="supervisor_name" name="supervisor_name" type="text"
                                       className={styles.inputText}
                                />
                                <span className={styles.question}>Email</span>
                                <input id="supervisor_email" name="supervisor_email" type="text"
                                       className={styles.inputText}
                                />
                                <span className={styles.question}>School</span>
                                <select id="supervisor_school" name="supervisor_school" className={styles.inputText}>
                                    <option value="Chiropractic">Chiropractic</option>
                                    <option value="HRS">Health and rehabilitation sciences</option>
                                    {/*<option value="RSP">Rehabilitation, Sport and Psychology</option>*/}
                                    {/*<option value="Radiology">Radiology</option>*/}
                                </select>

                                <div className={styles.bigLinkContainer}>


                                    <button className={styles.bigLink} onClick={() => {
                                        this.newSupervisor(document.getElementById("supervisor_name").value, document.getElementById("supervisor_email").value, document.getElementById("supervisor_school").value);
                                    }
                                    }>Add supervisor
                                    </button>
                                </div>

                            </div>
                                </div>
                                </div>
                        </Modal>
                    </PivotItem>

                </Pivot>


                </FluentTheme>


            </div>


        );
    }
}

export default withRouter(UserAdmin);