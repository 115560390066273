import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import FluentTheme from "./styles/FluentTheme";
import { initializeIcons } from '@fluentui/react/lib/Icons';

import './styles/index.scss'


initializeIcons();

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>

            <App />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);


