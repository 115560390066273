/* Component to selectively display contents

<Gated display=true>
<div>This will display</div>
</Gated>

<Gated display=false>
<div>This will not display</div>
</Gated>
*/

import React from 'react';
import AuthService from "../Services/AuthService";
import styles from "../styles/form.module.css";

export default function Question(props) {

      //  console.log("Question:", props.errors);
     //   let className=props.className;


        if (props.touched && props.errors ) {

            console.log("Errors:", props.touched, props.errors)

            return (
                <div className={`${props.className} ${styles.questionWarn}`}>
                    {props.children}
                </div>
            )
        } else if (props.touched) {
            return (
                <div className={`${props.className} ${styles.questionValid}`}>
                    {props.children}
                </div>
            )
        } else {
            return (
                <div className={`${props.className}`}>
                    {props.children}
                </div>
            )
        }



/*
    //   className = `${className} ${styles.questionWarn}`
        } else if (props.touched) {
            className = `${className} ${styles.questionValid}`
        }

        return (


            <div className={props.className}  >
                {props.children}
            </div>
        )
*/
}
