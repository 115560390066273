import axios from "axios";

const API_URL = process.env.REACT_APP_API_TARGET;

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "/auth/login", {
        username,
        password
      }, {headers: {"content-type":"application/json"}})
      .then(response => {
        if (response.data.accessToken) {
          sessionStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  /**
   * Returns true if the logged in user has the requested role
   * @param role to test
   */
  hasRole(role) {
    return this.getCurrentUser().roles.includes(role);
  }


  logout() {
    sessionStorage.removeItem("user");
  }


  getCurrentUser() {
    return JSON.parse(sessionStorage.getItem("user"));;
  }

  getAuthToken() {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user && user.accessToken) {
      return user.accessToken;
    } else {
      return
    }
  }


  getId() {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user && user.id) {
      return user.id;
    } else {
      return
    }

  }
}



export default new AuthService();
