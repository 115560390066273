import React, {Component} from "react";

import styles from "../styles/form.module.css";

export default class MessageDisplay extends Component {

    render() {


        let errorValues = null;
        if ( typeof(this.props.errors) !== 'undefined' && this.props.errors !== null ) {

            console.log("errors not null");

  //          let errors = this.props.errors;

            errorValues = Object.values(this.props.errors)

        }

        if (this.props.message && this.props.message.length > 1) {
            return (
                <fieldset id="messageBox" className={styles.serverMessage}>
                    <p>{this.props.message}</p>
                    <p></p>
                    { errorValues !== null && errorValues.map((error, index) => (

                        <p>{error}</p>

                    )) }

                </fieldset>
            );
        } else {
            return (
            <fieldset id="messageBox" className={styles.hidden}>
                <p></p>
            </fieldset>
            )
//            return null;
        }

    }
}
