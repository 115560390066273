
import styles from "../../styles/form.module.css";
import {ErrorMessage, Field, useFormikContext} from 'formik';
import Autosave from "../../components/Autosave";
import React, {useContext} from "react";
import {Link } from "react-router-dom";
import BaseContext from "../../util/BaseContext";
import MessageDisplay from "../../components/MessageDisplay";
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";
import Question from "../../components/Question";

export default function Optional01() {

    const stage = "Optional01"

    const {readOnly, applicationId, globalMessage} = useContext(BaseContext);
    const {values, touched, errors} = useFormikContext();

    return (


                    <div>

                        <div className={styles.pageTitleContainer}>
                            <h3 className={styles.pageTitle}>Procedures and justification</h3>
                            <span className={styles.autoSaveMessageContainer}>{!readOnly && (<Autosave />)}</span>
                            <FeedbackToggle readOnly = {readOnly} applicationId = {applicationId} stage = {stage} />



                        </div>




                        <NoteDisplay stage={stage} applicationId={applicationId} />

                        <Question className={styles.question} errors={errors.studyJustification} touched={touched.studyJustification}>
                    <span class={styles.question}><span className = {styles.questionIdentifier}>01</span>Please provide a brief justification for this study. Please include previous research to highlight why this study should be conducted.</span>
                    <Field as="textarea" name="studyJustification" className={styles.inputLong} disabled={readOnly}/>

                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"studyJustification"}/>
                        </Question>

                <Question className={styles.question} errors={errors.studyHypothesis} touched={touched.studyHypothesis}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>02</span>What is your research question/hypothesis</span>
                    <Field as="textarea" name="studyHypothesis" className={styles.inputShort} disabled={readOnly} />
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"studyHypothesis"}/>
                </Question>


            <div className={styles.nextContainer}>
                <Link to="/application/optional02" className={styles.next}>Next</Link>
            </div>

                    </div>


    )
}


