import React, { Component } from 'react'

const BaseContext = React.createContext({});

/**
 * HOD to initialise our global state
 *
 *
 */
class ParamsProvider extends Component {


    state = {
        displayOptional: false,
        applicationId: null,
        readOnly: true,
        globalMessage: "",
        shortForm: true,

        //IS the notes modal on the form open or not
//        notesVisible: false,
        }



    setDisplayOptional = (displayOptional) => {
        this.setState({displayOptional: displayOptional});
    }

    setApplicationId = (applicationId) => {
        this.setState({applicationId: applicationId});
    }

    setReadOnly = (readOnly) => {
        this.setState({readOnly: readOnly});
    }

    setGlobalMessage = (message) => {
        this.setState({globalMessage: message});
    }

    setShortForm = (shortForm) => {
        this.setState({shortForm: shortForm});
    }

//    setNotesVisible = (notesVisible) => {
//        this.setState({notesVisible: notesVisible});
//    }




    render() {
        const { children } = this.props
   //     const { params } = this.state
   //     const { setParams } = this

        return (
            <BaseContext.Provider
                value={{
                    displayOptional: this.state.displayOptional,
                    setDisplayOptional: this.setDisplayOptional,

                    applicationId: this.state.applicationId,
                    setApplicationId: this.setApplicationId,

                    readOnly: this.state.readOnly,
                    setReadOnly: this.setReadOnly,

                    globalMessage: this.state.globalMessage,
                    setGlobalMessage: this.setGlobalMessage,

                    shortForm: this.state.shortForm,
                    setShortForm: this.setShortForm,

//                    notesVisible: this.state.shortForm,
//                    setNotesVisible: this.setNotesVisible,

                }}
            >
                {children}
            </BaseContext.Provider>
        )
    }
}

export default BaseContext

export { ParamsProvider }