import axios from "axios";
import AuthService from "./AuthService";

const API_URL = process.env.REACT_APP_API_TARGET;

class ReviewerService {

    getAllReviewers(page, size) {
        const url = API_URL + "/admin/reviewers/all" + "?"
            + "pageNo=" + page
            + "&pageSize=" + size


        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }


    getActiveReviewers() {
        const url = API_URL + "/admin/reviewers/active"


        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }




    newReviewer(name, email, school) {
        const url = API_URL + "/admin/reviewer"
        return axios.post(url, {username:email,name:name, school:school}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }


    updateReviewer(reviewerId, newStatus) {
        const url = API_URL + "/admin/reviewer/" + reviewerId
        return axios.put(url, {inRotation:newStatus}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }


    assignReviewers(applicationId, reviewers) {
        const url = API_URL + "/admin/reviewer/assign/" + applicationId


console.log(reviewers);

        return axios.post(url, JSON.stringify(reviewers), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }

    approve(applicationId) {
        const url = API_URL + "/review/" + applicationId + "/APPROVE"

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );

    }


    declineMinor(applicationId) {
        const url = API_URL + "/review/" + applicationId + "/DRAFT2"

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );

    }

    declineMajor(applicationId) {
        const url = API_URL + "/review/" + applicationId + "/DRAFT1"

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );

    }


    reject(applicationId) {
        const url = API_URL + "/review/" + applicationId + "/REJECT"

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );

    }


}

export default new ReviewerService();
