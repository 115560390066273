import styles from "../../styles/form.module.css";
import {Link} from "react-router-dom";
import React, {Component} from "react";
import BaseContext from "../../util/BaseContext";
import MessageDisplay from "../../components/MessageDisplay";
import TimeSpinner from "../../components/TimeSpinner";
import FileService from "../../Services/FileService";
import Description from '@material-ui/icons/Description';
import {withFormik} from 'formik';
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";
import Gated from "../../components/Gated";
import {FileUploader}  from "@carbon/react";


const stage = "Declaration01";
export default class Declaration01 extends Component {




    static contextType = BaseContext;

    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            redirectTo: "",
            submitting: false,
            message: "",



            FILE_files: [],
            FILE_pageNumber: 0,
            FILE_fileCount: null,
            FILE_pageCount: null,
            FILE_pageSize: 5,
            FILE_requiredFiles: []

        };
    }

    handleUpload(id) {

        const {applicationId, readOnly} = this.context;


        FileService.uploadFile(document.getElementById("fileform1").file.files[0], applicationId, document.getElementById("fileform1").documentDescription.value, document.getElementById("fileform1").documentType.value)
            .then(
                response => {
                    this.setState({
                        submitting: false,
                        message: "",
                    })

                    document.getElementById("fileform1").reset();

                    this.getFileList(applicationId);
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        submitting: false,
                        message: resMessage
                    });

                    document.getElementById("messageBox").scrollIntoView();
                    document.getElementById("fileform1").documentDescription.value = "";
                    document.getElementById("fileform1").documentType.value = "Other";

                }
            )
    }

    getFileList(applicationId) {
        if (!this.state.submitting) {
            this.setState({
                submitting: true,
                message: ""
            })

            FileService.getList(
                applicationId
            ).then(
                response => {
                    this.setState({
                        submitting: false,
                        message: "",
                    })

                    if (response.data.attachedDocuments) {
                        this.setState({
                                FILE_files: response.data.attachedDocuments,
                                FILE_fileCount: response.data.fileCount,
                            }
                        )
                    } else {
                        this.setState(({
                            FILE_fileCount: 0
                        }))
                    }


                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.setState({
                        submitting: false,
                        message: resMessage
                    });
                    document.getElementById("messageBox").scrollIntoView();
                }
            );
        }
    }

    getRequiredList(applicationId) {
        console.log("in required list");

        if (!this.state.submitting) {
            this.setState({
                submitting: true,
                message: ""
            })

            FileService.getRequiredList(
                applicationId
            ).then(
                response => {
                    this.setState({
                        submitting: false,
                        message: "",
                    })

                    if (response.data.requiredFiles) {
                        this.setState({
                                FILE_requiredFiles: response.data.requiredFiles,
                            }
                        )
                    } else {

                    }
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.setState({
                        submitting: false,
                        message: resMessage
                    });
                    document.getElementById("messageBox").scrollIntoView();
                }
            );
        }
    }


    getFile(applicationId, fileIdentifier, originalFilename) {
        console.log("running getfile");


        FileService.getFile(applicationId,fileIdentifier,originalFilename);
    }


    deleteFile(applicationId, index) {
        if (!this.state.submitting) {
            this.setState({
                submitting: true,
                message: ""
            })

            FileService.deleteFile(
                applicationId, index
            ).then(
                response => {
                    this.setState({
                        submitting: false,
                        message: "",
                    })
                    this.getFileList(applicationId);
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.setState({
                        submitting: false,
                        message: resMessage
                    });
                    document.getElementById("messageBox").scrollIntoView();
                }

            )
        }

    }

    changeDocumentType(applicationId, index, newType) {
        if (!this.state.submitting) {
            this.setState({
                submitting: true,
                message: ""
            })

            FileService.updateDocumentType(
                applicationId, index, newType
            ).then(
                response => {
                    this.setState({
                        submitting: false,
                        message: "",
                    })
                    this.getFileList(applicationId)
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.setState({
                        submitting: false,
                        message: resMessage
                    });
                    document.getElementById("messageBox").scrollIntoView();
                }
            );

        }
    }




    componentDidMount() {
        const {applicationId, shortForm, readOnly} = this.context;


        //Retrieve the file listings for this application
        this.getFileList(applicationId);


        if (!readOnly) {
            this.getRequiredList(applicationId);
        }
    }


    render() {


        const {FILE_files, FILE_fileCount, FILE_requiredFiles} = this.state;
        const {applicationId, readOnly} = this.context;


       return (



            <div>
                <div className={styles.pageTitleContainer}>
                    <h3 className={styles.pageTitle}>Supporting Documents</h3>
                    <FeedbackToggle readOnly = {readOnly} applicationId = {applicationId} stage = {stage} />



                </div>


                <MessageDisplay message={this.state.message}/>

                <NoteDisplay stage={stage} applicationId={applicationId} />



                <p className={styles.instruction}>Please ensure you upload supporting documentation for your application, items may include any materials your study will use such as:
                </p>

                    <ul className={styles.instruction}>
                        <li>Questionnaires</li>
                        <li>Recruitment materials</li>
                        <li>Permissions to use techniques/methods</li>
                    </ul>

                <p className={styles.instruction}>In some cases your application may not be able to proceed until mandatory items are submitted, but its your responsibility to supply documents that support your application eg copies of any forms, questionnares or debrief forms. Failure to do so may cause delays to the processing of your application.</p>


                <h3>Stored Documents</h3>

                <table className={styles.listTable}>
                    <tr>
                        <th width="5%"></th>
                        <th width="25%"></th>
                        <th width="45%">Supplied Description</th>
                        <th width="20%">Document Type</th>
                        <th width="5%"></th>

                    </tr>

                    {FILE_files && FILE_files.map((attachedDocument, index) => (

                        <tr key={index}>
                            <td className={styles.left}><Description
                                className={styles.download}
                                onClick={() => {
                                    this.getFile(applicationId, attachedDocument.fileIdentifier, attachedDocument.originalFilename)
                                }}/>
                            </td>
                            <td>{attachedDocument.originalFilename}</td>
                            <td>{attachedDocument.description}                                 {attachedDocument.assignedFlags}</td>
                            <td>
                                <div>
                                    <select name = "formType" disabled={readOnly} onChange={(e) => {
                                        this.changeDocumentType(applicationId, index, e.target.value);

                                    }}>
                                        <option value = "">Other</option>
                                        {attachedDocument.formType==="IRAS" ? (
                                            <option selected={true} value = "IRAS">Iras</option>
                                            ) : (
                                            <option value = "IRAS">Iras</option>
                                        )}
                                        {attachedDocument.formType==="PATIENTRECORD" ? (
                                            <option selected={true} value = "PATIENTRECORD">Patient Record Form</option>
                                        ) : (
                                            <option value = "PATIENTRECORD">Patient Record Form</option>
                                        )}
                                        {attachedDocument.formType==="STANDARDINSTRUCTIONS" ? (
                                            <option selected={true} value = "STANDARDINSTRUCTIONS">Standard Instruction</option>
                                        ) : (
                                            <option value = "STANDARDINSTRUCTIONS">Standard Instruction</option>
                                        )}
                                        {attachedDocument.formType==="INFORMEDCONSENT" ? (
                                            <option selected={true} value = "INFORMEDCONSENT">Informed Consent Form</option>
                                        ) : (
                                            <option value = "INFORMEDCONSENT">Informed Consent Form</option>
                                        )}
                                        {attachedDocument.formType==="DEBRIEF" ? (
                                            <option selected={true} value = "DEBRIEF">Debrief Form</option>
                                        ) : (
                                            <option value = "DEBRIEF">Debrief Form</option>
                                        )}
                                        {attachedDocument.formType==="MEASURES" ? (
                                            <option selected={true} value = "MEASURES">Measure Permission</option>
                                        ) : (
                                            <option value = "MEASURES">Measure Permission</option>
                                        )}
                                        {attachedDocument.formType==="ADDITIONAL" ? (
                                            <option selected={true} value = "ADDITIONAL">Additional Documentation</option>
                                        ) : (
                                            <option value = "ADDITIONAL">Additional Documentation</option>
                                        )}





                                    </select>
                                </div>

                                                            </td>



                            <td className={styles.right}>
                                {!readOnly && (
                                    <div className={styles.button30}
                                         onClick={() => {
                                             // eslint-disable-next-line no-restricted-globals
                                             if ( confirm("Your are about to delete this file. Are you sure you wish to continue?") ) { this.deleteFile(applicationId, index)}}}>
                                        &#10006;
                                    </div>

                                )}

                            </td>

                        </tr>
                    ))}


                    <tr className={styles.footerRow}>
                        <td colSpan="5">
                            <div className={styles.flexContainer}>

                                <div
                                    className={styles.tableStatsContainer}>[Records: {FILE_fileCount}]
                                </div>

                                <div className={styles.spaceContainer}></div>


                            </div>
                        </td>
                    </tr>


                </table>
                <Gated display = {!readOnly}>
                <h3>Missing Documents</h3>
                <p className={styles.instruction}>Your application cannot proceed without the following supporting documents. If you;ve uploaded the requested documents please ensure you've indicated the document type correctly in the form above.</p>


            <ul className={styles.documentListContainer}>
                {FILE_requiredFiles && FILE_requiredFiles.map((fileDescription, index) => (

                    <li className={styles.documentList}>{fileDescription}</li>


                    ))}
            </ul>

                <h3>Upload New Document</h3>


                <form  id="fileform1">

                    <div className={styles.question}>
                        <span className={styles.question}>Document Description</span>
                        <input type="text" name="documentDescription" class={styles.inputText} disabled={readOnly} />


                        <span className={styles.question}><br/>Select File</span>

                        <input type="file" name="file" className={styles.inputText} title="upload" disabled={readOnly} />


                        <span className={styles.question}><br/>Select File Category </span>

                        <select name = "documentType" disabled={readOnly}>
                            <option value = "">Other</option>

                                <option value = "IRAS">Iras</option>
                                <option value = "PATIENTRECORD">Patient Record Form</option>
                                <option value = "STANDARDINSTRUCTIONS">Standard Instruction</option>
                                <option value = "INFORMEDCONSENT">Informed Consent Form</option>
                                <option value = "DEBRIEF">Debrief Form</option>
                                <option value = "MEASURES">Measure Permission</option>
                                <option value = "ADDITIONAL">Additional Documentation</option>

                        </select>


                <br/>


                    <div className={styles.uploadSurround}>


                        <div  onClick={() => this.handleUpload(applicationId)}>
                            <span className={styles.timeSpinnerSurround}>
                                {this.state.submitting && (
                                    <TimeSpinner backgroundColour="#ffffff"/>
                                )}
                            </span>
                            <span className = {styles.bigLink} >
                                Upload
                            </span>
                        </div>
                    </div>
                </div>
                </form>

                <div className={styles.nextContainer}>
                    <Link to="/application/declaration02" className={styles.next}>Next</Link>
                </div>
            </Gated>





            </div>





        )
    }

}