import React, {Component} from "react";


import styles from "../styles/form.module.css";
import {Link, NavLink, Redirect} from "react-router-dom";
import AuthService from "../Services/AuthService";


export default class Landing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            message: ""
        };
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();

        if (user) {
            this.setState({
                ready: true,
                user: user,
                redirectTo: '/home'
            });
        }

    }

    render() {

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }


        return (
            <div>
                <div className={styles.pageTitleContainer}>
                    <h3 className={styles.pageTitle}>AECC University College (Ethics) Portal <span className={styles.important}>[Beta Service]</span></h3>
                </div>

                <p className={styles.instruction}>Welcome, within this portal you can submit new requests for ethical approval for research, and review other applications that have been assigned to you to review either as a supervisor and/or review panelist.</p>

                <p className={styles.instruction}>Please note this is a new service, if you have any comments, concerns, or queries regarding the workings of the portal please direct them in the first instance to the <a href ="https://ithelpdesk.aecc.ac.uk">IT helpdesk</a> (Category Other Software). To continue please login.</p>

                <p>&nbsp;</p>
                <div className={styles.bigLinkContainer}>
                    <Link to="/home" className={styles.bigLink}>Login</Link>
                </div>
                <p>&nbsp;</p>
                <p className={styles.instruction}>
                    It is AECC University College policy that all research studies must be registered on the University College Online Ethics System (UCOES) This is to determine what type ethical approval is appropriate and for the purposes of sponsorship and gatekeeping where applicable. Individuals intending to carry out research (staff and students) must seek ethical approval of their study by completing this on-line application.
                </p>

                <p  className={styles.instruction}>
                    Potential participants and/or organisations must not be approached to take part in any research, nor may data collection commence until ethical approval of the research has been granted. No changes can be made to the research projects previously gaining ethics without first seeking amendments to the original application by resubmitting this form for ethical approval. Any non-ethics approved data collection or unauthorized change to the process set out in the original application renders ethical approval null and void and may result in research misconduct proceedings <a href = "https://public.aecc.ac.uk/files/01. Administration/03. Staff Information/1. Staff Policies and Procedures/General Policies/Research Misconduct Policy and Procedure.pdf">(Research Misconduct Policy and Procedure)</a>
                </p>

                <p className={styles.instruction}>
                    Please ensure you have read and understood the AECC University College Ethics Policy and Procedures as well as any that may relate to your specific discipline (e.g. British Psychological Society) before submitting your form. Please note that it is your responsibility to follow the University College Policy concerning the ethical conduct of research and knowledge exchange and any relevant academic or professional codes of practice and guidelines pertaining to your study.
                </p>

                <p className={styles.instruction}>
                    Please ensure that all necessary sections of this form are completed, and all additional information is included in the appendices. Incomplete submissions will result in a delayed response, may be returned to you without being considered by the relevant University College Ethics Panel and will require a resubmission
                </p>

                <p className={styles.important}>
                    NOTE FOR STUDENTS: You will need to discuss this form with your Supervising Tutor. It is IMPORTANT THAT THEY APPROVE THE FORM before it is submitted to the School Ethics Panel. The School Ethics Panel cannot and will not provide advice to individual students on any aspect of their application prior to submission. You will need to allow your Tutor ample time to review your form prior to submission. Please ensure you enter your supervisors email address correctly or it may delay them reviewing your application.
                </p>






            </div>
        );
    }
}
