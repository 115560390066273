import React, {Component, useContext} from "react";


import styles from "../styles/form.module.css";
import {Link, NavLink, Redirect, withRouter} from "react-router-dom";
import AuthService from "../Services/AuthService";
import Gated from "../components/Gated";
import SupervisionService from "../Services/SupervisionService";
import MessageDisplay from "../components/MessageDisplay";
import BaseContext from "../util/BaseContext";
import DateFormat from "../util/DateFormat";



class Supervise extends Component {

    static contextType = BaseContext;

    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            redirectTo: "",
            submitting: false,
            message: "",



            S_applications: [],
            S_pageNumber: 0,
            S_recordCount: null,
            S_pageCount: null,
            S_pageSize: 5

        };
    }


    getApplicationsToSupervise(pageNo, pageSize) {
        if (!this.state.submitting) {
            this.setState({
                submitting: true,
                message: ""
            })

            SupervisionService.getList(
                pageNo,
                pageSize
            ).then(
                response => {
                    this.setState({
                        submitting: false,
                        message: "",
                        S_applications: response.data.applications,
                        S_pageNumber: response.data.pageNumber,
                        S_recordCount: response.data.recordCount,
                        S_pageCount: response.data.pageCount,
                    })
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.setState({
                        submitting: false,
                        message: resMessage
                    });
                    document.getElementById("messageBox").scrollIntoView();
                }
            );
        }
    }


    approveApplication(applicationId) {
        if (!this.state.submitting) {
            this.setState({
                submitting: true,
                message: ""
            })

            SupervisionService.approve(applicationId)
                .then(
                response => {
                    this.setState({
                        submitting: false,
                        message: "",
                    })
                    this.getApplicationsToSupervise(this.state.S_pageNumber,this.state.S_pageSize);
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.setState({
                        submitting: false,
                        message: resMessage
                    });
                    document.getElementById("messageBox").scrollIntoView();
                }
            );
        }
    }

    declineApplication(applicationId) {
        if (!this.state.submitting) {
            this.setState({
                submitting: true,
                message: ""
            })

            SupervisionService.decline(applicationId)
                .then(
                    response => {
                        this.setState({
                            submitting: false,
                            message: "",
                        })
                        this.getApplicationsToSupervise(this.state.S_pageNumber,this.state.S_pageSize);
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.setState({
                            submitting: false,
                            message: resMessage
                        });
                        document.getElementById("messageBox").scrollIntoView();
                    }
                );
        }
    }

    componentDidMount() {
        //clear out any exiting applicationID
        const {setApplicationId} = this.context;

        setApplicationId(null);


        this.getApplicationsToSupervise(this.state.S_pageNumber, this.state.S_pageSize);
    }


    render() {

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        const {S_applications, S_pageNumber, S_recordCount, S_pageCount, S_pageSize} = this.state;
        const {applicationId, setApplicationId, readOnly, setReadOnly} = this.context;


        return (
            <div>

                <div className={styles.pageTitleContainer}>
                    <h3 className={styles.pageTitle}>Applications awaiting your approval</h3>
                </div>


                <MessageDisplay message={this.state.message}/>


                <table className={styles.listTable}>
                    <tr>
                        <th width="21%" title="Username of applicant">Student</th>
                        <th width="15%" title="Time of last update">Updated</th>
                        <th width="55%" title="Title of research">Title</th>

                        <th width="3%" title="View the form">View</th>
                        <th width="3%" title="Approve &#10004; or proceed &#8680; to next stage">&#10004;</th>
                        <th width="3%" title="Return to student for modifications">&#10006;</th>
                    </tr>

                {S_applications && S_applications.map((application, index) => (
                    <tr key={index}>
                        <td title={application.username}>{application.applicantName}</td>
                        <td className={styles.center} title={DateFormat.formatDate(application.updatedDate)}>{DateFormat.formatDateOnly(application.updatedDate)}</td>
                        <td className={styles.center}>{application.studyTitle}</td>

                        <td className={styles.center}>
                            <button title = "View the form" className={styles.filterButton}
                                    onClick={() => {
                                        setApplicationId(application.id);
                                        setReadOnly(true);
                                        this.props.history.push("/application/step01", {
                                        });
                                    }}
                            >
                                &#9655;
                            </button>
                        </td>
                        <td className={styles.center}>
                            {(application.status==="SUPERVISOR1" ) ? (
                                <button title = "Forward this application to the ethics panel" className={styles.filterButton}
                                        onClick={() => {
                                            // eslint-disable-next-line no-restricted-globals
                                            if ( confirm("You are about to accept this application for submission to the college wide ethics panel. Are you sure you wish to continue?") ) { this.approveApplication(application.id)}}}>
                                    &#8680;
                                </button>
                            ) : (
                                <button title = "Approve this application" className={styles.filterButton}
                                        onClick={() => {
                                            // eslint-disable-next-line no-restricted-globals
                                            if ( confirm("You are about to accept this application, accepting the student has made the recommended changes to their application from the ethics panel. Are you sure you wish to continue?") ) { this.approveApplication(application.id)}}}>
                                    &#10004;
                                </button>
                            )}

                        </td>

                        <td className={styles.center}>
                            {(application.status==="SUPERVISOR1" || application.status==="SUPERVISOR2") ? (
                                <button title = "Return to student to make amendments" className={styles.filterButton}
                                        onClick={() => {
                                            // eslint-disable-next-line no-restricted-globals
                                            if ( confirm("You are about to return this application to your student for amendments. Are you sure you wish to continue?") ) { this.declineApplication(application.id)}}}>                                    &#10006;
                                </button>
                            ) : (
                                <span>&#10006;</span>
                            )}

                        </td>




                    </tr>
                    )
                )
                }

                    <tr className={styles.footerRow}>
                        <td colSpan="6">
                            <div className={styles.flexContainer}>

                                <div className={styles.tableStatsContainer}>Page {S_pageNumber + 1}/{S_pageCount} [Records: {S_recordCount}]</div>

                                <div className={styles.spaceContainer}></div>
                                <div className={styles.paginationContainer}>
                                    <div className={styles.previousButtonContainer}>
                                        {S_pageNumber > 0 && (
                                            <button className={styles.paginationButton}
                                                    onClick={() => {
                                                        this.getPersonalApplications(parseInt(S_pageNumber) + 1-1,this.state.S_pageSize)
                                                     }}
                                                    >Previous</button>
                                        )}
                                    </div>
                                    <div className={styles.nextButtonContainer}>
                                        {(S_pageNumber + 1) < S_pageCount && (

                                            <button className={styles.paginationButton}
                                                    onClick={(e) => {
                                                        alert("next + " +  parseInt(S_pageNumber) + parseInt(1) + " " + this.state.S_pageSize );
                                                        this.getPersonalApplications(parseInt(S_pageNumber) + parseInt("1"),this.state.S_pageSize)
                                                    }}
                                            >Next</button>
                                        )}
                                    </div>
                                </div>






                            </div>
                        </td>
                    </tr>


                </table>




            </div>
        );
    }
}

export default withRouter(Supervise);