import React, {Component, useContext} from "react";


import styles from "../styles/form.module.css";
import {Link, NavLink, Redirect, withRouter} from "react-router-dom";
import AuthService from "../Services/AuthService";
import Gated from "../components/Gated";
import SupervisionService from "../Services/SupervisionService";
import MessageDisplay from "../components/MessageDisplay";
import BaseContext from "../util/BaseContext";
import DateFormat from "../util/DateFormat";
import ReviewerService from "../Services/ReviewerService";
import ApplicationService from "../Services/ApplicationService";




class Review extends Component {

    static contextType = BaseContext;

    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            redirectTo: "",
            submitting: false,




            S_applications: [],
            S_pageNumber: 0,
            S_recordCount: null,
            S_pageCount: null,
            S_pageSize: 5

        };
    }


    getApplicationsToReview(pageNo, pageSize) {

        const {globalMessage, setGlobalMessage} = this.context;
        setGlobalMessage("");

        ApplicationService.getListToReview(
                pageNo,
                pageSize
        ).then(
                response => {
                    this.setState({

                        S_applications: response.data.applications,
                        S_pageNumber: response.data.pageNumber,
                        S_recordCount: response.data.recordCount,
                        S_pageCount: response.data.pageCount,
                    })
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                   setGlobalMessage(resMessage);
                    document.getElementById("messageBox").scrollIntoView();
                }
            );
        }



    approveApplication(applicationId) {
        const {globalMessage, setGlobalMessage} = this.context;
        setGlobalMessage("");

        if (!this.state.submitting) {
            this.setState({
                submitting: true,
            })

            ReviewerService.approve(applicationId)
                .then(
                    response => {
                        this.setState({
                            submitting: false,
                        })
                        this.getApplicationsToReview(this.state.S_pageNumber, this.state.S_pageSize);
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.setState({
                            submitting: false,
                        });
                        setGlobalMessage(resMessage);
                        document.getElementById("messageBox").scrollIntoView();
                    }
                );
        }
    }

    declineMinorApplication(applicationId) {
        const {globalMessage, setGlobalMessage} = this.context;
        setGlobalMessage("");

        if (!this.state.submitting) {
            this.setState({
                submitting: true,
            })

            ReviewerService.declineMinor(applicationId)
                .then(
                    response => {
                        this.setState({
                            submitting: false,
                        })
                        this.getApplicationsToReview(this.state.S_pageNumber, this.state.S_pageSize);
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.setState({
                            submitting: false,
                        });
                        setGlobalMessage(resMessage);
                        document.getElementById("messageBox").scrollIntoView();
                    }
                );
        }
    }

    declineMajorApplication(applicationId) {
        const {globalMessage, setGlobalMessage} = this.context;
        setGlobalMessage("");

        if (!this.state.submitting) {
            this.setState({
                submitting: true,
            })

            ReviewerService.declineMajor(applicationId)
                .then(
                    response => {
                        this.setState({
                            submitting: false,
                        })
                        this.getApplicationsToReview(this.state.S_pageNumber, this.state.S_pageSize);
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.setState({
                            submitting: false,
                        });
                        setGlobalMessage(resMessage);
                        document.getElementById("messageBox").scrollIntoView();
                    }
                );
        }
    }

    rejectApplication(applicationId) {
        const {globalMessage, setGlobalMessage} = this.context;
        setGlobalMessage("");

        if (!this.state.submitting) {
            this.setState({
                submitting: true,
            })

            ReviewerService.reject(applicationId)
                .then(
                    response => {
                        this.setState({
                            submitting: false,
                        })
                        this.getApplicationsToReview(this.state.S_pageNumber, this.state.S_pageSize);
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.setState({
                            submitting: false,
                        });
                        setGlobalMessage(resMessage);
                        document.getElementById("messageBox").scrollIntoView();
                    }
                );
        }
    }

    componentDidMount() {
        //clear out any exiting applicationID
        const {setApplicationId, setGlobalMessage} = this.context;

        setApplicationId(null);
        setGlobalMessage("");


        this.getApplicationsToReview(this.state.S_pageNumber, this.state.S_pageSize);
    }


    render() {



        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        const {S_applications, S_pageNumber, S_recordCount, S_pageCount, S_pageSize} = this.state;
        const {applicationId, setApplicationId, readOnly, setReadOnly, globalMessage} = this.context;


        return (
            <div>

                <div className={styles.pageTitleContainer}>
                    <h3 className={styles.pageTitle}>Applications under your review</h3>
                </div>



                <MessageDisplay message={globalMessage}/>

                <p className={styles.instruction}>Listed below are the applications in progress you've been allocated a reviewer role on. Please review and indicate your verdict. Applications will cease to be listed once all relevant parties have reached their verdict and the application has progressed to its next step.</p>

                <table className={styles.listTable}>
                    <tr>
                        <th width="24%" title="Name of Applicant">Student</th>
                        <th width="12%" title="Time of last update">Last Updated</th>
                        <th width="55%" title="Title of research">Title</th>

                        <th width="3%" title="View the form">View</th>
                        <th width="3%" title="Indicate your acceptance of this application">&#10004;</th>
                        <th width="3%" title="Indicate rejection of the application, allowing resubmission and supervisor final approval">&#8630;</th>
                        <th width="3%" title="Indicate rejection of the application, allowing resubmission with full review">&#8634;</th>
                        <th width="3%" title="Reject the application outright">&#10006;</th>

                    </tr>

                    {S_applications && S_applications.map((application, index) => (
                        <>
                        {application.reviewers && application.reviewers.map((reviewer, j) => (
                            <>
                                {reviewer.username===AuthService.getId() && (

                            <tr key={index}>
                                <td title={application.username}>{application.applicantName}</td>
                                <td className={styles.center} title={DateFormat.formatDate(application.updatedDate)}>{DateFormat.formatDateOnly(application.updatedDate)}</td>
                                <td className={styles.center}>{application.studyTitle}</td>
                                <td className={styles.center}>
                                    <button title = "View the form" className={styles.filterButton}
                                            onClick={() => {
                                                setApplicationId(application.id);
                                                setReadOnly(true);
                                                this.props.history.push("/application/step01", {
                                                });
                                            }}
                                    >
                                        &#9655;
                                    </button>
                                </td>
                                <td className={styles.center}>
                                        <button title = "Indicate your acceptance of this application"
                                                className={reviewer.outcome && reviewer.outcome==='APPROVE' ? `${styles.filterButtonActive}` : `${styles.filterButton}`}
                                                onClick={() => {
                                                    // eslint-disable-next-line no-restricted-globals
                                                    if ( confirm("Please confirm you wish to indicate your acceptance of this application") ) { this.approveApplication(application.id)}}}>
                                            &#10004;
                                        </button>

                                </td>

                                <td>
                                    <button title = "Indicate rejection of the application, allowing resubmission and supervisor final approval"
                                            className={reviewer.outcome && reviewer.outcome==='DRAFT2' ? `${styles.filterButtonActive}` : `${styles.filterButton}`}
                                            onClick={() => {
                                                // eslint-disable-next-line no-restricted-globals
                                                if ( confirm("Indicate rejection of the application, allowing resubmission and supervisor final approval") ) { this.declineMinorApplication(application.id)}}}>&#8630;
                                    </button>

                                </td>

                                <td className={styles.center}>
                                        <button title = "Indicate rejection of the application, allowing resubmission with full review"
                                                className={reviewer.outcome && reviewer.outcome==='DRAFT1' ? `${styles.filterButtonActive}` : `${styles.filterButton}`}
                                                onClick={() => {
                                                    // eslint-disable-next-line no-restricted-globals
                                                    if ( confirm("Indicate rejection of the application, allowing resubmission with full review") ) { this.declineMajorApplication(application.id)}}}>&#8634;
                                        </button>

                                </td>
                                <td className={styles.center}>
                                    <button title="Decline application"
                                            className={reviewer.outcome && reviewer.outcome==='REJECT' ? `${styles.filterButtonActive}` : `${styles.filterButton}`}
                                            onClick={() => {
                                        // eslint-disable-next-line no-restricted-globals
                                        if ( confirm("You are indicating that this application should be declined outright and no further considerations made") ) { this.rejectApplication(application.id)}}}>&#10006;
                                    </button>

                                </td>
                            </tr>
                                )}
                                </>
                        ))
                        }
                        </>
                        )
                    )
                    }

                    <tr className={styles.footerRow}>
                        <td colSpan="8">
                            <div className={styles.flexContainer}>

                                <div className={styles.tableStatsContainer}>Page {S_pageNumber + 1}/{S_pageCount} [Records: {S_recordCount}]</div>

                                <div className={styles.spaceContainer}></div>
                                <div className={styles.paginationContainer}>
                                    <div className={styles.previousButtonContainer}>
                                        {S_pageNumber > 0 && (
                                            <button className={styles.paginationButton}
                                                    onClick={() => {
                                                        this.getPersonalApplications(S_pageNumber-1,S_pageSize)
                                                    }}
                                            >Previous</button>
                                        )}
                                    </div>
                                    <div className={styles.nextButtonContainer}>
                                        {(S_pageNumber + 1) < S_pageCount && (

                                            <button className={styles.paginationButton}
                                                    onClick={(e) => {
                                                        this.getPersonalApplications(S_pageNumber+1,S_pageSize)
                                                    }}
                                            >Next</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>


                </table>




            </div>
        );
    }
}

export default withRouter(Review);