import axios from "axios";
import AuthService from "./AuthService";

const API_URL = process.env.REACT_APP_API_TARGET;

class SupervisionService {



    //returns list of applications this user is responsible for
    getList(page, size) {
        const url = API_URL + "/supervise" + "?"
            + "pageNo=" + page
            + "&pageSize=" + size

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }


    approve(applicationId) {
        const url = API_URL + "/supervise/" + applicationId + "/approve"

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );

    }

    decline(applicationId) {
        const url = API_URL + "/supervise/" + applicationId + "/decline"

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );

    }

    getSupervisors(page, size) {
        const url = API_URL + "/admin/supervisors?"
            + "pageNo=" + page
            + "&pageSize=" + size

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );

    }

    getActiveSupervisors() {
        const url = API_URL + "/supervisors/active/all"

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );

    }

    updateSupervisor(supervisorId, newStatus) {
        const url = API_URL + "/admin/supervisor/" + supervisorId
        return axios.put(url, {inRotation:newStatus}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }


    newSupervisor(name, email, school) {
        const url = API_URL + "/admin/supervisor"
        return axios.post(url, {supervisorEmail:email,supervisorName:name, school:school}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }

}

export default new SupervisionService();
