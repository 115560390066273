// noinspection HtmlUnknownTarget

import React, {Component} from "react";
import { NavLink, Route, Switch} from "react-router-dom";
import styles from '../styles/layout.module.css'
import Home from "../content/Home";
import Application from "../content/Application";
import NotFound from "../content/NotFound";
import Login from "../content/Login";
import AuthenticatedRoute from "./AuthenticatedRoute";
import Landing from "../content/Landing";
import AuthService from "../Services/AuthService";
import Gated from "./Gated";
import BaseContext from "../util/BaseContext";
import Logout from "../content/Logout";
import Supervise from "../content/Supervise";
import Review from "../content/Review";
import ApplicationAdmin from "../content/admin/ApplicationAdmin";
import UserAdmin from "../content/admin/UserAdmin";



export default class Layout extends Component {
    static contextType = BaseContext;

    render() {
        const {displayOptional, applicationId, readOnly} = this.context;

        return (

            <div className={styles.container}>

                <header className={styles.header}>

                    <span className={styles.logo}><img src="/i/AECCLogo_inverse.png" alt="logo"/></span>

                    <div className={styles.titleBox}>
                        <h1 className={styles.title}>
                            Application to Conduct Research at AECC University College (Ethics) Portal
                        </h1>
                    </div>
                </header>

                <div className={styles.breadcrumbContainer}>

                    <div className={styles.breadcrumbLeft}>
                        <NavLink to={"/home"}>Home</NavLink>
                    </div>

                    <div className={styles.breadcrumbRight}>
                        {AuthService.getCurrentUser() ? (
                            <NavLink to={"/logout"}>Logout</NavLink>
                        ) : (
                            <NavLink to={"/login"}>Login</NavLink>
                        )}
                    </div>

                </div>

                <div className={styles.mainContainer}>


                    <Gated display={AuthService.getCurrentUser() != null}>
                        <div className={styles.leftPanel}>

                            <Gated display={applicationId == null}>
                                <NavLink activeClassName={styles.linkActive}
                                         to='/home'>My applications
                                </NavLink>

                                <Gated role="ROLE_SUPERVISOR">
                                    <NavLink activeClassName={styles.linkActive}
                                         to='/supervise'>My students
                                    </NavLink>
                                </Gated>

                                <Gated role="ROLE_REVIEWER">
                                    <NavLink activeClassName={styles.linkActive}
                                         to='/review'>My reviews
                                    </NavLink>
                                </Gated>

                                <Gated role="ROLE_ADMIN">
                                    <NavLink activeClassName={styles.linkActive}
                                         to='/admin/applicationAdmin'>Applications
                                    </NavLink>

                                    <NavLink activeClassName={styles.linkActive}
                                             to='/admin/userAdmin'>Users
                                    </NavLink>
                                </Gated>

                            </Gated>




                            <Gated display={applicationId != null}>
                                <div>
<ol className = {styles.navbarList}>
                                    <NavLink activeClassName={styles.linkActive}
                                             to='/application/step01'><li>About you</li>
                                    </NavLink>
                                    <NavLink activeClassName={styles.linkActive}
                                             to='/application/step02'><li>Researcher</li>
                                    </NavLink>
                                    <NavLink activeClassName={styles.linkActive}
                                             to='/application/step03'><li>Your study</li>
                                    </NavLink>
                                    <NavLink activeClassName={styles.linkActive}
                                             to='/application/step04'><li>Overview 1</li>
                                    </NavLink>
                                    <NavLink activeClassName={styles.linkActive}
                                             to='/application/step05'><li>Overview 2</li>
                                    </NavLink>


                                    <Gated display={displayOptional!==undefined && displayOptional===true}>

                                        <NavLink activeClassName={styles.linkActive}
                                                 to='/application/optional01'><li>Procedures</li>
                                        </NavLink>
                                        <NavLink activeClassName={styles.linkActive}
                                                 to='/application/optional02'><li>Instructions</li>
                                        </NavLink>
                                        <NavLink activeClassName={styles.linkActive}
                                                 to='/application/optional03'><li>Measures</li>
                                        </NavLink>
                                        <NavLink activeClassName={styles.linkActive}
                                                 to='/application/optional04'><li>Data collection</li>
                                        </NavLink>
                                        <NavLink activeClassName={styles.linkActive}
                                                 to='/application/optional05'><li>Recruitment</li>
                                        </NavLink>
                                        <NavLink activeClassName={styles.linkActive}
                                                 to='/application/optional06'><li>Criteria</li>
                                        </NavLink>
                                        <NavLink activeClassName={styles.linkActive}
                                                 to='/application/optional07'><li>Incentives</li>
                                        </NavLink>
                                        <NavLink activeClassName={styles.linkActive}
                                                 to='/application/optional08'><li>Consent</li>
                                        </NavLink>
                                        <NavLink activeClassName={styles.linkActive}
                                                 to='/application/optional09'><li>Debriefing</li>
                                        </NavLink>
                                        <NavLink activeClassName={styles.linkActive}
                                                 to='/application/optional10'><li>Risk factors</li>
                                        </NavLink>
                                        <NavLink activeClassName={styles.linkActive}
                                                 to='/application/optional11'><li>Data handling</li>
                                        </NavLink>
                                        <NavLink activeClassName={styles.linkActive}
                                                 to='/application/optional12'><li>Other risks</li>
                                        </NavLink>

                                    </Gated>


                                    <NavLink activeClassName={styles.linkActive}
                                             to='/application/declaration01'><li className={styles.dontShow}>Documents</li>
                                    </NavLink>

    <Gated display={!readOnly}>
                                    <NavLink activeClassName={styles.linkActive}
                                             to='/application/declaration02'><li className={styles.dontShow}>Declaration</li>
                                    </NavLink>
                            </Gated>
</ol>
                                </div>
                            </Gated>

                        </div>
                    </Gated>

                    <div className={styles.rightPanel}>
                        <main className={styles.main}>

                            <Switch>
                                <Route exact path={["/"]}>
                                    <Landing/>
                                </Route>

                                <AuthenticatedRoute exact path={["/home"]}>
                                    <Home/>
                                </AuthenticatedRoute>

                                <AuthenticatedRoute path="/application">
                                    <Application/>
                                </AuthenticatedRoute>

                                <AuthenticatedRoute path="/supervise">
                                    <Supervise/>
                                </AuthenticatedRoute>

                                <AuthenticatedRoute path="/review">
                                    <Review/>
                                </AuthenticatedRoute>

                                <AuthenticatedRoute path="/admin/applicationAdmin">
                                    <ApplicationAdmin/>
                                </AuthenticatedRoute>

                                <AuthenticatedRoute path="/admin/userAdmin">
                                    <UserAdmin/>
                                </AuthenticatedRoute>


                                <Route path="/login">
                                    <Login/>
                                </Route>
                                <Route path="/logout">
                                    <Logout/>
                                </Route>

                                <Route path="*" component={NotFound}/>
                            </Switch>
                        </main>
                    </div>
                </div>
                <footer className={styles.footer}>

                </footer>
            </div>
        )
    }
}



