
import styles from "../../styles/form.module.css";
import {ErrorMessage, Field, useFormikContext} from "formik";
import React, {useContext} from "react";
import Autosave from "../../components/Autosave";
import Gated from "../../components/Gated";
import {Link} from "react-router-dom";
import BaseContext from "../../util/BaseContext";
import MessageDisplay from "../../components/MessageDisplay";
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";
import Question from "../../components/Question";

export default function Optional07() {

    const stage = "Optional07"

    const {readOnly, applicationId, globalMessage} = useContext(BaseContext);
    const {values, touched, errors} = useFormikContext();

    return (

                    <div>

                        <div className={styles.pageTitleContainer}>
                            <h3 className={styles.pageTitle}>Payments and incentives</h3>
                            <span className={styles.autoSaveMessageContainer}>{!readOnly && (<Autosave />)}</span>
                            <FeedbackToggle readOnly = {readOnly} applicationId = {applicationId} stage = {stage} />



                        </div>




                        <NoteDisplay stage={stage} applicationId={applicationId} />




                <Question className={styles.question}  errors={errors.recruitIncentives} touched={touched.recruitIncentives}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01</span>Are you offering incentives to participants?</span>
                    <Field className={styles.radioSelect} id="recruitIncentives" type="radio" name="recruitIncentives" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="recruitIncentives" type="radio" name="recruitIncentives" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"recruitIncentives"}/>
                </Question>
<Gated display = {values.recruitIncentives=="true"}>
                <Question className={styles.question} errors={errors.recruitIncentivesDetails} touched={touched.recruitIncentivesDetails}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01-A</span>Please supply details of the incentives you intend to use.</span>
                    <Field as="textarea" name="recruitIncentivesDetails" className={styles.inputLong} disabled={readOnly}/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"recruitIncentivesDetails"}/>
                </Question>
</Gated>
                <Question className={styles.question} errors={errors.recruitPayments} touched={touched.recruitPayments}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>02</span>Are you offering payments to researchers or participants?</span>
                    <Field className={styles.radioSelect} id="recruitPayments" type="radio" name="recruitPayments" value="false" disabled={readOnly} />No<br/>
                    <Field className={styles.radioSelect} id="recruitPayments" type="radio" name="recruitPayments" value="true" disabled={readOnly} />Yes<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"recruitPayments"}/>
                </Question>
                        <Gated display = {values.recruitPayments=="true"}>
                <Question className={styles.question} errors={errors.recruitPaymentsDetails} touched={touched.recruitPaymentsDetails}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>02-A</span>Please supply details of the payments you intend to use.</span>
                    <Field as="textarea" name="recruitPaymentsDetails" className={styles.inputLong} disabled={readOnly}/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"recruitPaymentsDetails"}/>
                </Question>
                        </Gated>
            <div className={styles.nextContainer}>
                <Link to="/application/optional08" className={styles.next}>Next</Link>
            </div>


        </div>

)
}

