
import styles from "../../styles/form.module.css";
import Gated from "../../components/Gated";
import {ErrorMessage, Field, FieldArray, useFormikContext} from "formik";
import React, {useContext} from 'react';
import Autosave from "../../components/Autosave";
import {Link} from "react-router-dom";
import BaseContext from "../../util/BaseContext";
import MessageDisplay from "../../components/MessageDisplay";
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";
import Question from "../../components/Question";

export default function Optional03() {

    const stage = "Optional03"

    const {readOnly, applicationId, globalMessage} = useContext(BaseContext);
    const {values, touched, errors} = useFormikContext();

    return (
        <div>

                        <div className={styles.pageTitleContainer}>
                            <h3 className={styles.pageTitle}>Measures and materials</h3>
                            <span className={styles.autoSaveMessageContainer}>{!readOnly && (<Autosave />)}</span>
                            <FeedbackToggle readOnly = {readOnly} applicationId = {applicationId} stage = {stage} />



                        </div>




            <NoteDisplay stage={stage} applicationId={applicationId} />


            <Question className={styles.question} errors={errors.questionnaireUse} touched={touched.questionnaireUse}>
                            <span className={styles.question}><span className = {styles.questionIdentifier}>01</span>Will the study use Questionnaire/Inventories?</span>
                            <Field className={styles.radioSelect} id="questionnaireUse" type="radio" name="questionnaireUse" value="false" disabled={readOnly} />No<br/>
                            <Field className={styles.radioSelect} id="questionnaireUse" type="radio" name="questionnaireUse" value="true" disabled={readOnly} />Yes<br/>
                            <ErrorMessage className={styles.validationWarning} component="div"
                                          name={"questionnaireUse"}/>
                        </Question>


                        <Gated display={values.questionnaireUse==="true"}>

                            <FieldArray name={"measures"}>
                                {({push, remove}) => (
                                    <div>


                                        {
                                            values.measures.length > 0 &&
                                            values.measures.map((measure, index) => (
                                                <div className="row" key={index}>
                                                    <Question className={styles.question} errors={errors.measures !== undefined && errors.measures[index] !== undefined && errors.measures[index].measureName}
                                                              touched={ touched.measures !== undefined && touched.measures[index] !== undefined && touched.measures[index].measureName !== undefined}>
                                                        <span className = {styles.questionIdentifier}>01-A</span>
                                                        <span
                                                            className={styles.question}>Name of Measure to be used.</span>

                                                        <Field name={`measures.${index}.measureName`}
                                                               placeholder={""}
                                                               type="text"
                                                               className={styles.inputText}
                                                               disabled={readOnly}
                                                        />

                                                        <button type="button" className={styles.addButton}
                                                                onClick={() => push({measure: ""})}>&#10010;
                                                        </button>
                                                        {index > 0 && (
                                                        <button type="button" className={styles.removeButton}
                                                                onClick={() => remove(index)}>&#10006;
                                                        </button>
                                                        )}
                                                        <ErrorMessage
                                                            name={`measures.${index}.measureName`}
                                                            component="div"
                                                            className={styles.fieldError}
                                                        />
                                                    </Question>

                                                    <Question
                                                        className={styles.question} errors={errors.measures !== undefined && errors.measures[index] !== undefined && errors.measures[index].measureCopyrighted}
                                                        touched={ touched.measures !== undefined && touched.measures[index] !== undefined && touched.measures[index].measureCopyrighted !== undefined}>

                                                        <span
                                                            className={styles.question}><span className = {styles.questionIdentifier}>01-B</span>Is this measure copyrighted?</span>

                                                        <Field className={styles.radioSelect} type="radio" name={`measures.${index}.measureCopyrighted`} disabled={readOnly} value="false"/>No<br/>
                                                        <Field className={styles.radioSelect} type="radio" name={`measures.${index}.measureCopyrighted`} disabled={readOnly}
                                                               value="true"/>Yes<br/>
                                                        <ErrorMessage
                                                            name={`measures.${index}.measureCopyrighted`}
                                                            component="div"
                                                            className={styles.fieldError}
                                                        />

                                                    </Question>

                                                    <Question
                                                        className={styles.question} errors={errors.measures !== undefined && errors.measures[index] !== undefined && errors.measures[index].measurePermission}
                                                        touched={ touched.measures !== undefined && touched.measures[index] !== undefined && touched.measures[index].measurePermission !== undefined}>

                                                    <span className={styles.question}><span className = {styles.questionIdentifier}>01-C</span>Is the owners permission required to use this measure</span>
                                                        <span className={styles.example}>Please ensure you supply your authorisation documents in the documents section.</span>

                                                        <Field className={styles.radioSelect} type="radio" name={`measures.${index}.measurePermission`} disabled={readOnly} value="false"/>No<br/>
                                                        <Field className={styles.radioSelect} type="radio" name={`measures.${index}.measurePermission`} disabled={readOnly}
                                                               value="true"/>Yes<br/>
                                                        <ErrorMessage
                                                            name={`measures.${index}.measurePermission`}
                                                            component="div"
                                                            className={styles.fieldError}
                                                        />
                                                    </Question>

                                                </div>
                                            ))

                                        }

                                    </div>


                                )
                                }

                            </FieldArray>


                        </Gated>




            <div className={styles.nextContainer}>
                <Link to="/application/optional04" className={styles.next}>Next</Link>
            </div>



        </div>
                    )
                    }

