import React, {Component} from "react";

import styles from "../styles/form.module.css";
import {CSSTransition} from "react-transition-group";
import ApplicationService from "../Services/ApplicationService";
import BaseContext from "../util/BaseContext";
import {Panel, Stack} from "@fluentui/react";


export const NoteContext = React.createContext({});

/**
 * Provides a context for our notes system, we need to have some state variables to determine whether we're displaying
 * the relevant panels.
 */
export default class NoteEnabled extends Component {


    state = {
        //Toggleable value to determine if we're showing the notes or display notes option
        //this will be reset by the display notes component on page load
        displayNotes: false,
        displayUpload: false,


        //Version 2 of our notes, once we're happy we can remove the above version 1
        displayNotesPanel: false,

    }

   constructor(props) {
        super(props);
    }

    setDisplayNotes = (displayNotes) => {
        this.setState({displayNotes: displayNotes});
    }

    setDisplayUpload= (displayUpload) => {
        this.setState({displayUpload: displayUpload});
    }


    setDisplayNotesPanel = (value) => {
        this.setState({displayNotesPanel: value});
    }


    render() {

        const {displayNotesPanel, setDisplayNotesPanel} = this.state;


        return(
            <NoteContext.Provider value={{
                displayNotes: this.state.displayNotes,
                displayUpload: this.state.displayUpload,
                displayNotesPanel: this.state.displayNotesPanel,
                setDisplayNotes: this.setDisplayNotes,
                setDisplayUpload: this.setDisplayUpload,
                setDisplayNotesPanel: this.setDisplayNotesPanel,
            }}>
                {this.props.children}




                {/*<Panel*/}
                {/*    isOpen={displayNotesPanel}*/}
                {/*    // onDismiss={setDisplayNotesPanel(false)}*/}

                {/*    isBlocking={true}*/}
                {/*    closeButtonAriaLabel="Close Panel"*/}
                {/*    isFooterAtBottom={true}*/}
                {/*    isLightDismiss={true}*/}
                {/*>*/}
                {/*    <div className="panelContainer">*/}
                {/*        <h3>Panel</h3>*/}

                {/*        /!*<Stack tokens={stackProps}>*!/*/}

                {/*        /!*</Stack>*!/*/}
                {/*    </div>*/}
                {/*</Panel>*/}


            </NoteContext.Provider>
        )
    }
}