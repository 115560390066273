import React, {useContext, useState} from 'react'
import styles from "../../styles/form.module.css";
import Gated from "../../components/Gated";
import {ErrorMessage, Field, FieldArray, useFormikContext} from 'formik'
import Autosave from "../../components/Autosave";
import {Link} from "react-router-dom";
import BaseContext from "../../util/BaseContext";
import MessageDisplay from "../../components/MessageDisplay";
import NoteDisplay, {FeedbackToggle} from "../../components/NoteDisplay";
import Question from "../../components/Question";
import SupervisionService from "../../Services/SupervisionService";






export default function Step02(props) {

    const stage = "Step02"

    const {readOnly, applicationId, globalMessage} = useContext(BaseContext);
    const {values, touched, errors, handleBlur, handleChange, setFieldValue} = useFormikContext();


    const [message, setMessage] = useState();
    const [records, setRecords] = useState([]);



    if ( records.length < 1) {
        SupervisionService.getActiveSupervisors().then(
            response => {
                setRecords(response.data.records);
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setMessage(resMessage);
                document.getElementById("messageBox").scrollIntoView();
            }
        );
    }


    return (




            <div>

                        <div className={styles.pageTitleContainer}>
                            <h3 className={styles.pageTitle}>Nature of researcher</h3>
                            <span className={styles.autoSaveMessageContainer}>{!readOnly && (<Autosave />)}</span>
                            <FeedbackToggle readOnly = {readOnly} applicationId = {applicationId} stage = {stage} />



                        </div>


                <MessageDisplay message={message}/>

                <NoteDisplay stage={stage} applicationId={applicationId} />

                <Question className={styles.question} errors={errors.researcherSchool} touched={touched.researcherSchool}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>01</span>Which school does your research fall under?</span>
                    <Field className={styles.radioSelect} id="researcherSchoolChiro"
                           type="radio" name="researcherSchool" value="Chiropractic" disabled={readOnly}
                            onChange={(e) => {
                                handleChange(e)
                                setFieldValue("supervisorsList",[]);

                            }
                            }
                    />
                    Chiropractic<br/>
                    <Field className={styles.radioSelect} id="researcherSchoolHRS"
                           type="radio" name="researcherSchool" value="HRS" disabled={readOnly}
                           onChange={(e) => {
                               handleChange(e)
                               setFieldValue("supervisorsList",[]);
                           }
                           }
                    />
                    Health and rehabilitation sciences<br/>


                    <Field className={styles.radioSelect} id="researcherSchoolRSP"
                           type="radio" name="researcherSchool" value="RSP"
                           disabled={true}
                           onChange={(e) => {
                               handleChange(e)
                               setFieldValue("supervisorsList",[]);

                           }
                           }
                    />
                    <strike>Rehabilitation, sport and psychology</strike><br/>
                    <Field className={styles.radioSelect} id="researcherSchoolRadiology"
                           type="radio" name="researcherSchool" value="Radiology" disabled={true}/>
                        <strike>Radiology</strike><br/>
                </Question>

                <Question className={styles.question} errors={errors.researcherType} touched={touched.researcherType}>
                    <span className={styles.question}><span className = {styles.questionIdentifier}>02</span>Researcher type?</span>
                <Field className={styles.radioSelect} id="researcherTypeUndergrad"
                       type="radio" name="researcherType" value="undergraduate" disabled={readOnly}/>
                    Undergraduate<br/>
                <Field className={styles.radioSelect} id="researcherTypePostgrad"
                       type="radio" name="researcherType" value="postgraduate" disabled={readOnly}
                />Postgraduate<br/>
                <Field className={styles.radioSelect} id="researcherTypeStaff" type="radio" name="researcherType" value="staff" disabled={readOnly}

                />Staff<br/>
                    <ErrorMessage className={styles.validationWarning} component="div"
                                  name={"researcherType"}/>
            </Question>







<Gated display={values.researcherType==="undergraduate" || values.researcherType==="postgraduate"}>



    <Question className={styles.question} errors={errors.supervisorsList} touched={touched.supervisorsList}>

        <span className={styles.question}><span className = {styles.questionIdentifier}>02-A</span>Select your supervisors</span>
        <span className={styles.example}>You can select multiple supervisors by holding ctrl whilst clicking their name</span>
        <Gated display={values.researcherSchool != undefined}>
        <Field className={styles.inputText} name="supervisorsList" disabled={readOnly} as="select" multiple>
            { records && records.map((supervisor, index) => (
                <>
                    {supervisor.school === values.researcherSchool && (
                        <option value = {supervisor.supervisorEmail}>{supervisor.supervisorName}</option>
                    )}
                </>
            ))}
        </Field>
        </Gated>
        <Gated display={values.researcherSchool === undefined}>
            <p className={styles.instruction}>Please select your school</p>
        </Gated>

        <ErrorMessage className={styles.validationWarning} component="div"
                      name={"supervisorsList"}/>
    </Question>







</Gated>





            <div className={styles.nextContainer}>
                <Link to="step03" className={styles.next}>Next</Link>
            </div>

            </div>
                    )
}






