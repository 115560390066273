import React, {Component} from "react";


import TimeSpinner from "./TimeSpinner";
import {Button} from "@carbon/react";

//import {Button} from "@carbon/react";

export default class ActionButton extends Component {


    constructor(props) {
//TODO override default state with entries from props
//TODO calculate path lengths and adjust stylesheet dynamically

        super(props);

    }

    componentDidMount() {
        if (this.props.backgroundColour != null ) {
            this.setState({
                backgroundColour: this.props.backgroundColour
            })
        }
    }

    render() {

        //If we're called without a display prop assume we're to be displayed
        //display prop exists only display when true

            return (
                <>
                    {this.props.hasActivity ? (
                        <Button as = "div" kind = "primary" tabIndex = "{0}" type = "submit">
                            <span>...Please wait</span> <TimeSpinner />
                        </Button>

                    ) : (
                        <Button  kind="primary" tabIndex="{0}" type="submit" ><>
                           {this.props.children}
                        </></Button>
                    )}
                </>
            )





    }
}
